<div class="container-fluid e-modal">
  <button mat-mini-fab color="primary" class="e-btn-modal-close" (click)="closeDialog()">
    <mat-icon class="e-btn-modal-close__icon">close</mat-icon>
  </button>
  <div class="row mt-3">
    <div class="col-12 e-modal-row-1 mt-4">
      <h1 class="e-modal-title" style="font-weight: bold">Campaign Payment</h1>
    </div>
  </div>
  <br />
  <div class="row mt-3">
    <div class="col-12 e-modal-row-1 mt-4">
      <h2 class="e-page-title e-page-title--emp-less">
        All below amounts are inclusive of Government Taxes
      </h2>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center mt-3 mb-1">
      <h2 class="e-page-title e-page-title--emp-less">
        Campaign ID : <span class="e-txt-weighted">{{ data.campaignId }}</span>
      </h2>
      <h2 class="e-page-title e-page-title--emp-less">
        Connection Number :
        <span class="e-txt-weighted">0{{ data.mobile }}</span>
      </h2>
      <h2 class="e-page-title e-page-title--emp-less">
        Campaign Cost :
        <span class="e-txt-weighted">{{
          data.campaignCost | currency:" ":"symbol"
          }}</span>
      </h2>
      <h2 class="e-page-title e-page-title--emp-less">
        The Account Balance:
        <span class="e-txt-weighted">{{
          data.walletBalance | currency:" ":"symbol"
          }}</span>
      </h2>
      <h2 class="e-page-title e-page-title--emp-less">
        The Account To Be Paid:
        <span class="e-txt-weighted">{{
          data.campaignCost | currency:" ":"symbol"
          }}</span>
      </h2>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center mt-2">
      <p class="e-large-txt">Payment option</p>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center mb-1">
      <h4 class="e-page-title e-page-title--emp-less">
        Select the option you like to pay with
      </h4>
    </div>
  </div>
  <div class="row mt-4 mb-5 e-modal-footer-btns">
    <button mat-stroked-button class="e-btn-payment" (click)="walletOption()"
      [class.selected-payment]="this.selectedPaymentOption === 0"
      *ngIf="this.data.campaignCost <= this.data.walletBalance">
      <span class="material-icons e-btn-payment__icon mb-3">local_atm</span>
      <br />
      <span class="e-btn-payment__txt">My Wallet</span>
    </button>
    <button *ngIf="paymentType == '0'" mat-stroked-button class="e-btn-payment" (click)="addToBillOption()"
      [class.selected-payment]="this.selectedPaymentOption === 2">
      <span class="material-icons e-btn-payment__icon mb-3">receipt_long</span>
      <br />
      <span class="e-btn-payment__txt">Add to bill</span>
    </button>
    <!-- <button *ngIf="paymentType == '0'" mat-stroked-button class="e-btn-payment" (click)="ezCashOption()"
      [class.selected-payment]="this.selectedPaymentOption === 1">
      <img class="e-ez-image mb-3" src="../../../assets/images/1200x630wa.png" />
      <br />
      <span class="e-btn-payment__txt">eZ Cash</span>
    </button> -->
    <button *ngIf="paymentType == '2'" mat-stroked-button class="e-btn-payment" (click)="packageOption()"
      [class.selected-payment]="this.selectedPaymentOption === 4">
      <span class="material-icons e-btn-payment__icon mb-3">inventory_2</span>
      <br />
      <span class="e-btn-payment__txt">Package</span>
    </button>
  </div>
  <!-- <div class="row mt-4 mb-5 e-modal-footer-btns">
    <p *ngIf="this.selectedPaymentOption === 1">
      Please note that You will be charged 1.5 % as the eZcash service fee per
      campaign
    </p>
  </div> -->
</div>
<div class="e-modal-footer mb-1" mat-dialog-actions *ngIf="this.selectedPaymentOption == null">
  <h4 class="e-page-title e-page-title--emp-less" style="color: red">
    Please select a payment option {{ this.selectedPaymentOption }}
    <span class="e-txt-weighted"></span>
  </h4>
</div>
<div class="e-modal-footer mb-1" mat-dialog-actions>
  <button mat-flat-button color="primary" class="e-btn e-btn--small" (click)="initiatePayment()">
    Pay
  </button>
</div>
