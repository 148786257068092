<section class="container-fluid e-body-wrapper pt-5">
  <div class="row">
    <div class="col-md-6 offset-md-3 col-lg-4 offset-lg-4">
      <div class="e-card text-center">
        <form [formGroup]="form" (submit)="SendPin(formDirective)" #formDirective="ngForm" autocomplete="off">
          <h4 class="mb-4 e-page-title">
            Please enter Recover PIN sent to your mobile
          </h4>
          <div class="e-pin-grp row no-gutters">
            <mat-form-field appearance="outline" class="col-2 e-input e-pin-grp__inp">
              <input matInput placeholder="{{ placeholder1 }}" (focus)="checkPlaceHolder($event)"
                (blur)="checkPlaceHolder($event)" maxlength="1" minlength="1" #pos11 formControlName="pos1"
                (keyup.1)="pos1($event)" (keyup.2)="pos1($event)" (keyup.3)="pos1($event)" (keyup.4)="pos1($event)"
                (keyup.5)="pos1($event)" (keyup.6)="pos1($event)" (keyup.7)="pos1($event)" (keyup.8)="pos1($event)"
                (keyup.9)="pos1($event)" (keyup.0)="pos1($event)" (keyup.arrowup)="pos1($event)"
                (keyup.arrowright)="pos1($event)" (keyup.arrowdown)="pos5($event)" (keyup.arrowleft)="pos5($event)"
                (keydown.Backspace)="pos1BackSpace($event)"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" required />
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-2 e-input e-pin-grp__inp">
              <input matInput placeholder="{{ placeholder2 }}" (focus)="checkPlaceHolder($event)"
                (blur)="checkPlaceHolder($event)" maxlength="1" minlength="1" #pos22 formControlName="pos2"
                (keyup.1)="pos2($event)" (keyup.2)="pos2($event)" (keyup.3)="pos2($event)" (keyup.4)="pos2($event)"
                (keyup.5)="pos2($event)" (keyup.6)="pos2($event)" (keyup.7)="pos2($event)" (keyup.8)="pos2($event)"
                (keyup.9)="pos2($event)" (keyup.0)="pos2($event)" (keyup.arrowup)="pos2($event)"
                (keyup.arrowright)="pos2($event)" (keyup.arrowdown)="pos6($event)" (keyup.arrowleft)="pos6($event)"
                (keydown.Backspace)="pos2BackSpace($event)"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" required />
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-2 e-input e-pin-grp__inp">
              <input matInput placeholder="{{ placeholder3 }}" (focus)="checkPlaceHolder($event)"
                (blur)="checkPlaceHolder($event)" maxlength="1" minlength="1" #pos33 formControlName="pos3"
                (keyup.1)="pos3($event)" (keyup.2)="pos3($event)" (keyup.3)="pos3($event)" (keyup.4)="pos3($event)"
                (keyup.5)="pos3($event)" (keyup.6)="pos3($event)" (keyup.7)="pos3($event)" (keyup.8)="pos3($event)"
                (keyup.9)="pos3($event)" (keyup.0)="pos3($event)" (keyup.arrowup)="pos3($event)"
                (keyup.arrowright)="pos3($event)" (keyup.arrowdown)="pos1($event)" (keyup.arrowleft)="pos1($event)"
                (keydown.Backspace)="pos3BackSpace($event)"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" required />
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-2 e-input e-pin-grp__inp">
              <input matInput placeholder="{{ placeholder4 }}" (focus)="checkPlaceHolder($event)"
                (blur)="checkPlaceHolder($event)" maxlength="1" minlength="1" #pos44 formControlName="pos4"
                (keyup.1)="pos4($event)" (keyup.2)="pos4($event)" (keyup.3)="pos4($event)" (keyup.4)="pos4($event)"
                (keyup.5)="pos4($event)" (keyup.6)="pos4($event)" (keyup.7)="pos4($event)" (keyup.8)="pos4($event)"
                (keyup.9)="pos4($event)" (keyup.0)="pos4($event)" (keyup.arrowup)="pos4($event)"
                (keyup.arrowright)="pos4($event)" (keyup.arrowdown)="pos2($event)" (keyup.arrowleft)="pos2($event)"
                (keydown.Backspace)="pos4BackSpace($event)"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" required />
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-2 e-input e-pin-grp__inp">
              <input matInput placeholder="{{ placeholder5 }}" (focus)="checkPlaceHolder($event)"
                (blur)="checkPlaceHolder($event)" maxlength="1" minlength="1" #pos55 formControlName="pos5"
                (keyup.1)="pos5($event)" (keyup.2)="pos5($event)" (keyup.3)="pos5($event)" (keyup.4)="pos5($event)"
                (keyup.5)="pos5($event)" (keyup.6)="pos5($event)" (keyup.7)="pos5($event)" (keyup.8)="pos5($event)"
                (keyup.9)="pos5($event)" (keyup.0)="pos5($event)" (keyup.arrowup)="pos5($event)"
                (keyup.arrowright)="pos5($event)" (keyup.arrowdown)="pos3($event)" (keyup.arrowleft)="pos3($event)"
                (keydown.Backspace)="pos5BackSpace($event)"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" required />
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-2 e-input e-pin-grp__inp">
              <input matInput placeholder="{{ placeholder6 }}" (focus)="checkPlaceHolder($event)"
                (blur)="checkPlaceHolder($event)" maxlength="1" minlength="1" #pos66 formControlName="pos6"
                (keyup.1)="pos6($event)" (keyup.2)="pos6($event)" (keyup.3)="pos6($event)" (keyup.4)="pos6($event)"
                (keyup.5)="pos6($event)" (keyup.6)="pos6($event)" (keyup.7)="pos6($event)" (keyup.8)="pos6($event)"
                (keyup.9)="pos6($event)" (keyup.0)="pos6($event)" (keyup.arrowup)="pos6($event)"
                (keyup.arrowright)="pos6($event)" (keyup.arrowdown)="pos4($event)" (keyup.arrowleft)="pos4($event)"
                (keydown.Backspace)="pos6BackSpace($event)"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" required />
            </mat-form-field>
          </div>
          <div class="e-time-c-wrapper mt-4" style="color: red;" *ngIf="
              this.form.get('pos1').hasError('required') ||
              this.form.get('pos1').hasError('pattern') ||
              this.form.get('pos2').hasError('required') ||
              this.form.get('pos2').hasError('pattern') ||
              this.form.get('pos3').hasError('required') ||
              this.form.get('pos3').hasError('pattern') ||
              this.form.get('pos4').hasError('required') ||
              this.form.get('pos4').hasError('pattern') ||
              this.form.get('pos5').hasError('required') ||
              this.form.get('pos5').hasError('pattern') ||
              this.form.get('pos6').hasError('required') ||
              this.form.get('pos6').hasError('pattern')
            ">
            Pin Number Must Have 6 Digits
          </div>
          <div class="e-time-c-wrapper mt-4">
            <div class="e-time-counter">
              <countdown [config]="{ leftTime: counterTime }" (event)="onNotify($event)"></countdown>
            </div>
            <span class="e-menu-btn-wrapper__vl"></span>
            <button (click)="resendPin()" mat-raised-button [disabled]="resendActivated == true" type="button">
              RESEND
            </button>
          </div>
          <button mat-flat-button color="primary" class="e-btn mt-4" [disabled]="this.form.invalid">
            NEXT
          </button>
        </form>
      </div>
    </div>
  </div>
</section>
