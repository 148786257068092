import {
  Component,
  OnInit,
  Inject,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormGroupDirective,
} from "@angular/forms";
import { CampaignService } from "src/app/campaign/campaign.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { SuccessDialogBoxComponent } from "src/app/pop-up-messages/success-dialog-box/success-dialog-box.component";
export interface DialogData {
  mobile: number;
}
@Component({
  selector: "app-user-wallet",
  templateUrl: "./user-wallet.component.html",
  styleUrls: ["./user-wallet.component.css"],
})
export class UserWalletComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

export interface PaymentHistoryElement {
  charged_total: number;
  currency: string;
  customer_id_or_campaign_id: number;
  id: number;
  owner_id: number;
  owner_mobile: number;
  payment_method: string;
  transaction_status: number;
  transaction_type: string;
  transaction_closed_timestamp: string;
  transaction_init_timestamp: string;
}
@Component({
  selector: "user-reload-popup-dialog",
  templateUrl: "./payment-modal.component.html",
  styleUrls: ["./payment-modal.component.css"],
})
export class UserReloadPopupComponent implements OnInit {
  selectedPaymentOption = null;
  form: FormGroup;
  isLoading = false;
  paymentStatus = false;
  ngOnInit() {
    if (this.data.mobile == null) {
      this.closeDialog();
    }
    this.form = new FormGroup({
      amount: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(11),
          Validators.pattern("^[0-9]*$"),
        ],
      }),
      relaodType: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1),
          Validators.pattern("^[0-9]*$"),
        ],
      }),
    });
  }
  constructor(
    public dialogRef: MatDialogRef<UserReloadPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private campaignService: CampaignService,
    private router: Router,
    private matDialog: MatDialog
  ) {
    dialogRef.disableClose = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close({ event: "close", data: this.paymentStatus });
  }
  ezCashOption() {
    this.selectedPaymentOption = 1;
    this.form.patchValue({ relaodType: 1 });
    this.form.get("relaodType").updateValueAndValidity();
  }
  addToBillOption() {
    this.selectedPaymentOption = 2;
    this.form.patchValue({ relaodType: 2 });
    this.form.get("relaodType").updateValueAndValidity();
  }
  initiateReload(formDirective: FormGroupDirective) {
    if (
      this.form.invalid ||
      (this.selectedPaymentOption != 1 && this.selectedPaymentOption != 2)
    ) {
      return;
    }
    //set payment status to flase
    this.paymentStatus = false;
    this.isLoading = true;
    //user id is handled in backend
    let default_user_id = 0;
    //0 stands for campaign payment 1 for topup
    let type = 1;
    //campign value is handled in backend
    //topup value
    let default_val = this.form.value.amount;
    this.campaignService
      .initiateTopUp(
        default_user_id,
        this.form.value.relaodType,
        type,
        default_val
      )
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          //console.log(response);
          //set payment status to true
          this.paymentStatus = true;
          //how capture success response
          this.closeDialog();

          this.matDialog.open(SuccessDialogBoxComponent, {
            width: "532px",
            data: { message: response.comment },
          });
        },
        (error) => {
          this.isLoading = false;
          this.closeDialog();
          //console.log(error);
        }
      );
  }
}
