import { Component, OnInit } from "@angular/core";
import { MatTabChangeEvent } from "@angular/material/tabs";

export interface DisplayedColumns {
  key: string;
  value: string;
}

@Component({
  selector: "app-user-usage-report",
  templateUrl: "./user-usage-report.component.html",
  styleUrls: ["./user-usage-report.component.css"],
})
export class UserUsageReportComponent implements OnInit {
  reportType = 1;
  tabType = 1;

  displayedColumnsBulkDaily: DisplayedColumns[] = [
    { key: "0", value: "All" },
    { key: "mask", value: "Mask" },
    { key: "mobile_numbers_count", value: "Mobile Numbers Count" },
    { key: "delivered_numbers_count", value: "Delivered numbers Count" },
    {
      key: "non_delivered_numbers_count",
      value: "None Delivered Numbers Count",
    },
    { key: "sms_segments_count", value: "SMS Segments Count" },
    {
      key: "delivered_sms_segments_count",
      value: "Delivered SMS Segments Count",
    },
    {
      key: "non_delivered_sms_segments_count",
      value: "None Delivered SMS Segments Count",
    },
    { key: "d2d_numbers_count", value: "D2D Numbers Count" },
    {
      key: "d2d_delivered_numbers_count",
      value: "D2D Delivered Numbers Count",
    },
    {
      key: "d2d_non_delivered_numbers_count",
      value: "D2D None Delivered Number Count",
    },
    { key: "d2nd_numbers_count", value: "D2ND Numbers Count" },
    {
      key: "d2nd_delivered_numbers_count",
      value: "D2ND Delivered Numbers Count",
    },
    {
      key: "d2nd_non_delivered_numbers_count",
      value: "D2ND None Delivered Number Count",
    },
    { key: "d2d_sms_segments_count", value: "D2D SMS Segments Count" },
    {
      key: "d2d_delivered_sms_segments_count",
      value: "D2D Delivered SMS Segments Count",
    },
    {
      key: "d2d_non_delivered_sms_segments_count",
      value: "D2D None Delivered SMS Segments Count",
    },
    { key: "d2nd_sms_segments_count", value: "D2ND SMS Segments Count" },
    {
      key: "d2nd_delivered_sms_segments_count",
      value: "D2ND Delivered SMS Segments Count",
    },
    {
      key: "d2nd_non_delivered_sms_segments_count",
      value: "D2ND None Delivered SMS Segments Count",
    },
    { key: "d2d_cost_with_tax", value: "D2D Cost With TAX (LKR)" },
    { key: "d2nd_cost_with_tax", value: "D2ND Cost With TAX (LKR)" },
    { key: "total_cost_with_tax", value: "Total Cost With TAX (LKR)" },
  ];

  displayedColumnsPersonalizedDaily: DisplayedColumns[] = [
    { key: "0", value: "All" },
    { key: "mask", value: "Mask" },
    { key: "mobile_numbers_count", value: "Mobile Numbers Count" },
    { key: "delivered_numbers_count", value: "Delivered numbers Count" },
    {
      key: "non_delivered_numbers_count",
      value: "None Delivered Numbers Count",
    },
    { key: "sms_segments_count", value: "SMS Segments Count" },
    {
      key: "delivered_sms_segments_count",
      value: "Delivered SMS Segments Count",
    },
    {
      key: "non_delivered_sms_segments_count",
      value: "None Delivered SMS Segments Count",
    },
    { key: "d2d_numbers_count", value: "D2D Numbers Count" },
    {
      key: "d2d_delivered_numbers_count",
      value: "D2D Delivered Numbers Count",
    },
    {
      key: "d2d_non_delivered_numbers_count",
      value: "D2D None Delivered Number Count",
    },
    { key: "d2nd_numbers_count", value: "D2ND Numbers Count" },
    {
      key: "d2nd_delivered_numbers_count",
      value: "D2ND Delivered Numbers Count",
    },
    {
      key: "d2nd_non_delivered_numbers_count",
      value: "D2ND None Delivered Number Count",
    },
    { key: "d2d_sms_segments_count", value: "D2D SMS Segments Count" },
    {
      key: "d2d_delivered_sms_segments_count",
      value: "D2D Delivered SMS Segments Count",
    },
    {
      key: "d2d_non_delivered_sms_segments_count",
      value: "D2D None Delivered SMS Segments Count",
    },
    { key: "d2nd_sms_segments_count", value: "D2ND SMS Segments Count" },
    {
      key: "d2nd_delivered_sms_segments_count",
      value: "D2ND Delivered SMS Segments Count",
    },
    {
      key: "d2nd_non_delivered_sms_segments_count",
      value: "D2ND None Delivered SMS Segments Count",
    },
    { key: "d2d_cost_with_tax", value: "D2D Cost With TAX (LKR)" },
    { key: "d2nd_cost_with_tax", value: "D2ND Cost With TAX (LKR)" },
    { key: "total_cost_with_tax", value: "Total Cost With TAX (LKR)" },
  ];

  displayedColumnsTargetingDaily: DisplayedColumns[] = [
    { key: "0", value: "All" },
    { key: "mask", value: "mask" },
    { key: "mobile_numbers_count", value: "Mobile Numbers Count" },
    { key: "delivered_numbers_count", value: "Delivered numbers Count" },
    {
      key: "non_delivered_numbers_count",
      value: "None Delivered Numbers Count",
    },
    { key: "sms_segments_count", value: "SMS Segments Count" },
    {
      key: "delivered_sms_segments_count",
      value: "Delivered SMS Segments Count",
    },
    {
      key: "non_delivered_sms_segments_count",
      value: "None Delivered SMS Segments Count",
    },
    { key: "total_cost_with_tax", value: "Total Cost With TAX (LKR)" },
  ];

  displayedColumnsPackageDaily: DisplayedColumns[] = [
    { key: "0", value: "All" },
    { key: "mask", value: "Mask" },
    { key: "mobile_numbers_count", value: "Mobile Numbers Count" },
    { key: "delivered_numbers_count", value: "Delivered numbers Count" },
    {
      key: "non_delivered_numbers_count",
      value: "None Delivered Numbers Count",
    },
    { key: "sms_segments_count", value: "SMS Segments Count" },
    {
      key: "delivered_sms_segments_count",
      value: "Delivered SMS Segments Count",
    },
    {
      key: "non_delivered_sms_segments_count",
      value: "None Delivered SMS Segments Count",
    },
    { key: "d2d_numbers_count", value: "D2D Numbers Count" },
    {
      key: "d2d_delivered_numbers_count",
      value: "D2D Delivered Numbers Count",
    },
    {
      key: "d2d_non_delivered_numbers_count",
      value: "D2D None Delivered Number Count",
    },
    { key: "d2nd_numbers_count", value: "D2ND Numbers Count" },
    {
      key: "d2nd_delivered_numbers_count",
      value: "D2ND Delivered Numbers Count",
    },
    {
      key: "d2nd_non_delivered_numbers_count",
      value: "D2ND None Delivered Number Count",
    },
    { key: "d2d_sms_segments_count", value: "D2D SMS Segments Count" },
    {
      key: "d2d_delivered_sms_segments_count",
      value: "D2D Delivered SMS Segments Count",
    },
    {
      key: "d2d_non_delivered_sms_segments_count",
      value: "D2D None Delivered SMS Segments Count",
    },
    { key: "d2nd_sms_segments_count", value: "D2ND SMS Segments Count" },
    {
      key: "d2nd_delivered_sms_segments_count",
      value: "D2ND Delivered SMS Segments Count",
    },
    {
      key: "d2nd_non_delivered_sms_segments_count",
      value: "D2ND None Delivered SMS Segments Count",
    },
  ];

  displayedColumnsMaskWiseBulkDaily: DisplayedColumns[] = [
    { key: "0", value: "All" },
    { key: "date", value: "Date" },
    { key: "mask", value: "Mask" },
    { key: "mobile_numbers_count", value: "Mobile Numbers Count" },
    { key: "delivered_numbers_count", value: "Delivered numbers Count" },
    {
      key: "non_delivered_numbers_count",
      value: "None Delivered Numbers Count",
    },
    { key: "sms_segments_count", value: "SMS Segments Count" },
    {
      key: "delivered_sms_segments_count",
      value: "Delivered SMS Segments Count",
    },
    {
      key: "non_delivered_sms_segments_count",
      value: "None Delivered SMS Segments Count",
    },
    { key: "d2d_numbers_count", value: "D2D Numbers Count" },
    {
      key: "d2d_delivered_numbers_count",
      value: "D2D Delivered Numbers Count",
    },
    {
      key: "d2d_non_delivered_numbers_count",
      value: "D2D None Delivered Number Count",
    },
    { key: "d2nd_numbers_count", value: "D2ND Numbers Count" },
    {
      key: "d2nd_delivered_numbers_count",
      value: "D2ND Delivered Numbers Count",
    },
    {
      key: "d2nd_non_delivered_numbers_count",
      value: "D2ND None Delivered Number Count",
    },
    { key: "d2d_sms_segments_count", value: "D2D SMS Segments Count" },
    {
      key: "d2d_delivered_sms_segments_count",
      value: "D2D Delivered SMS Segments Count",
    },
    {
      key: "d2d_non_delivered_sms_segments_count",
      value: "D2D None Delivered SMS Segments Count",
    },
    { key: "d2nd_sms_segments_count", value: "D2ND SMS Segments Count" },
    {
      key: "d2nd_delivered_sms_segments_count",
      value: "D2ND Delivered SMS Segments Count",
    },
    {
      key: "d2nd_non_delivered_sms_segments_count",
      value: "D2ND None Delivered SMS Segments Count",
    },
    { key: "d2d_cost_with_tax", value: "D2D Cost With TAX (LKR)" },
    { key: "d2nd_cost_with_tax", value: "D2ND Cost With TAX (LKR)" },
    { key: "total_cost_with_tax", value: "Total Cost With TAX (LKR)" },
  ];

  displayedColumnsMaskWisePersonalizedDaily: DisplayedColumns[] = [
    { key: "0", value: "All" },
    { key: "date", value: "Date" },
    { key: "mask", value: "Mask" },
    { key: "mobile_numbers_count", value: "Mobile Numbers Count" },
    { key: "delivered_numbers_count", value: "Delivered numbers Count" },
    {
      key: "non_delivered_numbers_count",
      value: "None Delivered Numbers Count",
    },
    { key: "sms_segments_count", value: "SMS Segments Count" },
    {
      key: "delivered_sms_segments_count",
      value: "Delivered SMS Segments Count",
    },
    {
      key: "non_delivered_sms_segments_count",
      value: "None Delivered SMS Segments Count",
    },
    { key: "d2d_numbers_count", value: "D2D Numbers Count" },
    {
      key: "d2d_delivered_numbers_count",
      value: "D2D Delivered Numbers Count",
    },
    {
      key: "d2d_non_delivered_numbers_count",
      value: "D2D None Delivered Number Count",
    },
    { key: "d2nd_numbers_count", value: "D2ND Numbers Count" },
    {
      key: "d2nd_delivered_numbers_count",
      value: "D2ND Delivered Numbers Count",
    },
    {
      key: "d2nd_non_delivered_numbers_count",
      value: "D2ND None Delivered Number Count",
    },
    { key: "d2d_sms_segments_count", value: "D2D SMS Segments Count" },
    {
      key: "d2d_delivered_sms_segments_count",
      value: "D2D Delivered SMS Segments Count",
    },
    {
      key: "d2d_non_delivered_sms_segments_count",
      value: "D2D None Delivered SMS Segments Count",
    },
    { key: "d2nd_sms_segments_count", value: "D2ND SMS Segments Count" },
    {
      key: "d2nd_delivered_sms_segments_count",
      value: "D2ND Delivered SMS Segments Count",
    },
    {
      key: "d2nd_non_delivered_sms_segments_count",
      value: "D2ND None Delivered SMS Segments Count",
    },
    { key: "d2d_cost_with_tax", value: "D2D Cost With TAX (LKR)" },
    { key: "d2nd_cost_with_tax", value: "D2ND Cost With TAX (LKR)" },
    { key: "total_cost_with_tax", value: "Total Cost With TAX (LKR)" },
  ];

  displayedColumnsMaskWiseTargetingDaily: DisplayedColumns[] = [
    { key: "0", value: "All" },
    { key: "date", value: "Date" },
    { key: "mask", value: "Mask" },
    { key: "mobile_numbers_count", value: "Mobile Numbers Count" },
    { key: "delivered_numbers_count", value: "Delivered numbers Count" },
    {
      key: "non_delivered_numbers_count",
      value: "None Delivered Numbers Count",
    },
    { key: "sms_segments_count", value: "SMS Segments Count" },
    {
      key: "delivered_sms_segments_count",
      value: "Delivered SMS Segments Count",
    },
    {
      key: "non_delivered_sms_segments_count",
      value: "None Delivered SMS Segments Count",
    },
    { key: "total_cost_with_tax", value: "Total Cost With TAX (LKR)" },
  ];

  displayedColumnsMaskWisePackageDaily: DisplayedColumns[] = [
    { key: "0", value: "All" },
    { key: "date", value: "Date" },
    { key: "mask", value: "Mask" },
    { key: "mobile_numbers_count", value: "Mobile Numbers Count" },
    { key: "delivered_numbers_count", value: "Delivered numbers Count" },
    {
      key: "non_delivered_numbers_count",
      value: "None Delivered Numbers Count",
    },
    { key: "sms_segments_count", value: "SMS Segments Count" },
    {
      key: "delivered_sms_segments_count",
      value: "Delivered SMS Segments Count",
    },
    {
      key: "non_delivered_sms_segments_count",
      value: "None Delivered SMS Segments Count",
    },
    { key: "d2d_numbers_count", value: "D2D Numbers Count" },
    {
      key: "d2d_delivered_numbers_count",
      value: "D2D Delivered Numbers Count",
    },
    {
      key: "d2d_non_delivered_numbers_count",
      value: "D2D None Delivered Number Count",
    },
    { key: "d2nd_numbers_count", value: "D2ND Numbers Count" },
    {
      key: "d2nd_delivered_numbers_count",
      value: "D2ND Delivered Numbers Count",
    },
    {
      key: "d2nd_non_delivered_numbers_count",
      value: "D2ND None Delivered Number Count",
    },
    { key: "d2d_sms_segments_count", value: "D2D SMS Segments Count" },
    {
      key: "d2d_delivered_sms_segments_count",
      value: "D2D Delivered SMS Segments Count",
    },
    {
      key: "d2d_non_delivered_sms_segments_count",
      value: "D2D None Delivered SMS Segments Count",
    },
    { key: "d2nd_sms_segments_count", value: "D2ND SMS Segments Count" },
    {
      key: "d2nd_delivered_sms_segments_count",
      value: "D2ND Delivered SMS Segments Count",
    },
    {
      key: "d2nd_non_delivered_sms_segments_count",
      value: "D2ND None Delivered SMS Segments Count",
    },
  ];

  constructor() {}

  ngOnInit() {}

  tabChangeMain(tabChangeEvent: MatTabChangeEvent) {
    if (tabChangeEvent.tab.textLabel == "Bulk") {
      this.tabType = 1;
    } else if (tabChangeEvent.tab.textLabel == "Personalized") {
      this.tabType = 2;
    } else if (tabChangeEvent.tab.textLabel == "Targeting") {
      this.tabType = 3;
    } else if (tabChangeEvent.tab.textLabel == "Package") {
      this.tabType = 4;
    }
  }
}
