<!-- <nav class="e-mh-header e-mh-header--large navbar navbar-expand-lg navbar-light bg-light"> -->
  <nav class="navbar navbar-expand-md navbar-light">

  <a
    class="e-mh-header__main px-3"
    routerLink="/user/landing"
    style="text-decoration: none"
    >eSMS</a
  >
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto e-mh-nav">
      <!-- <li class="nav-item">
        <button class="e-mh-nav__item e-mh-nav__item--active" mat-button>
          Solutions
        </button>
      </li> -->
      <li class="nav-item">
        <button
          class="e-mh-nav__item"
          mat-button
          routerLink="/user/pricing-landing"
          routerLinkActive="active"
        >
          Pricing
        </button>
      </li>
      <!-- <li class="nav-item">
        <button class="e-mh-nav__item" mat-button>About us</button>
      </li> -->
      <li class="nav-item">
        <button
          class="e-mh-nav__item"
          mat-button
          routerLink="/user/contact-us-landing"
          routerLinkActive="active"
        >
          Contact us
        </button>
      </li>
    </ul>
    <button mat-stroked-button class="e-btn-stroke mr-2" routerLink="/signup">
      SIGN UP
    </button>
    <button
      mat-flat-button
      color="primary"
      class="e-btn ml-2"
      routerLink="/login"
    >
      LOGIN
    </button>
    <!-- <ul class="navbar-nav e-header__icon-wra">
          <li class="nav-item dropdown">
              <span class="e-btn-icon e-btn-icon--circle e-btn-icon--spacing-right" id="navbarDropdown" role="button"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="material-icons">notifications</i>
                  <span class="e-btn-icon__circle e-btn-icon__circle--alert">2</span>
              </span>
              <div class="e-menu e-ntf dropdown-menu" aria-labelledby="navbarDropdown">
                  <div class="dropdown-item e-ntf__inner-wrapper">
                      <div class="e-ntf__txt">
                          <div class="e-menu__sec-txt">12th June 2020 09:45pm</div>
                          <div class="e-menu__primary-txt mt-1">Your requested mask “adeona test” has been approved
                          </div>
                      </div>
                      <div class="e-ntf__status e-ntf__status--active"></div>
                  </div>
                  <div class="dropdown-divider"></div>
                  <div class="dropdown-item e-ntf__inner-wrapper">
                      <div class="e-ntf__txt">
                          <div class="e-menu__sec-txt">12th June 2020 09:45pm</div>
                          <div class="e-menu__primary-txt mt-1">Your requested mask “adeona test” has been approved
                          </div>
                      </div>
                      <div class="e-ntf__status e-ntf__status--failed"></div>
                  </div>
                  <div class="dropdown-divider"></div>
                  <div class="dropdown-item e-ntf__inner-wrapper">
                      <div class="e-ntf__txt">
                          <div class="e-menu__sec-txt">12th June 2020 09:45pm</div>
                          <div class="e-menu__primary-txt mt-1">Your requested mask “adeona test” has been approved
                          </div>
                      </div>
                      <div class="e-ntf__status e-ntf__status--active"></div>
                  </div>
                  <div class="dropdown-divider"></div>
                  <div class="dropdown-item e-ntf__inner-wrapper">
                      <div class="e-ntf__txt">
                          <div class="e-menu__sec-txt">12th June 2020 09:45pm</div>
                          <div class="e-menu__primary-txt mt-1">Your requested mask “adeona test” has been approved
                          </div>
                      </div>
                      <div class="e-ntf__status e-ntf__status--active"></div>
                  </div>
                  <div class="dropdown-divider"></div>
                  <div class="dropdown-item e-ntf__inner-wrapper">
                      <div class="e-ntf__txt">
                          <div class="e-menu__sec-txt">12th June 2020 09:45pm</div>
                          <div class="e-menu__primary-txt mt-1">Your requested mask “adeona test” has been approved
                          </div>
                      </div>
                      <div class="e-ntf__status e-ntf__status--active"></div>
                  </div>
              </div>
          </li>
          <li class="nav-item dropdown">
              <span class="e-btn-icon e-btn-icon--circle e-btn-icon--spacing-right" id="navbarDropdown2" role="button"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="material-icons">person</i>
              </span>
              <div class="e-menu e-menu-profile dropdown-menu" aria-labelledby="navbarDropdown2">
                  <div class="text-center e-menu__sec-txt">Logged in as</div>
                  <div class="text-center e-menu__primary-txt">John Doe</div>
                  <div class="dropdown-divider"></div>
                  <div class="e-menu-btn-wrapper">
                      <a class="e-body-txt e-body-txt--highlighted e-menu-btn-wrapper__btn">Settings</a>
                      <span class="e-menu-btn-wrapper__vl"></span>
                      <a class="e-body-txt e-body-txt--highlighted e-menu-btn-wrapper__btn">Logout</a>
                  </div>
              </div>
          </li>
      </ul> -->
  </div>
</nav>
