import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { Router } from "@angular/router";
import { AuthData } from "./auth-data.model";

import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatDialogService } from "../mat-dialog-service";
import { SuccessComponent } from "../success/success.component";
import { SuccessDialogBoxComponent } from "../pop-up-messages/success-dialog-box/success-dialog-box.component";
import { AppSettings } from "../config";
import { I } from "@angular/cdk/keycodes";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  private authStatusListner = new Subject<boolean>();
  private token: string;
  private refreshToken: string;
  private isAuthenticated = false;
  private userData: any = null;
  private tokenTimer: any;
  private refreshTokenTimer: any;
  dialogRef;
  showBanner: boolean = false;

  hostIpWithPort = AppSettings.hostIpWithPort;
  constructor(
    private http: HttpClient,
    private router: Router,
    private matDialog: MatDialog,
    private matDialogService: MatDialogService
  ) {}
  registerUser(
    fname: string,
    lname: string,
    address: string,
    mobile: number,
    username: string,
    password: string,
    email: string,
    nic_brc: string,
    default_mask: string,
    agree_to_terms: number,
    image: File,
    accountUsedFor: string
  ) {
    const postData = new FormData();
    postData.append("fname", fname);
    postData.append("lname", lname);
    postData.append("address", address);
    postData.append("mobile", mobile.toString());
    postData.append("username", username);
    postData.append("password", password);
    postData.append("email", email);
    postData.append("nicOrBrc", nic_brc);
    postData.append("defaultMask", default_mask);
    postData.append("agreeToTerms", agree_to_terms.toString());
    postData.append("image", image, mobile.toString());
    postData.append("accountUsedFor", accountUsedFor);

    this.http
      .post<{ regToken: string; expiration: number }>(
        this.hostIpWithPort + "/api/v1/user/signup",
        postData
      )
      .subscribe(
        (response: any) => {
          const regToken = response.regToken;
          const regExpiration = response.expiration;
          const now = new Date();
          const regExpirationDate = new Date(
            now.getTime() + regExpiration * 1000
          );
          if (regToken && regExpiration) {
            this.saveRegAuthData(regToken, regExpirationDate.toISOString());
          }
          //console.log(response);
          this.authStatusListner.next(false);
          this.dialogRef = this.matDialog.open(SuccessDialogBoxComponent, {
            width: "542px",
            data: { message: response.comment, status: response.comment },
          });
          this.router.navigate(["/signup/get-pin", response.regMobile]);
        },
        (error) => {
          //console.log(error);
          this.authStatusListner.next(false);
        }
      );
  }
  getAuthStatusListener() {
    return this.authStatusListner.asObservable();
  }
  loginUser(username: string, password: string) {
    const user = { username: username, password: password };
    this.http
      .post<{
        comment: string;
        token: string;
        expiration: number;
        refreshToken: string;
        refreshExpiration: number;
        userData: any;
        isotpOutValidated: string;
      }>(this.hostIpWithPort + "/api/v3/user/login", user)
      .subscribe(
        (response) => {
          if (response.isotpOutValidated == "0") {
            this.initSuccessLogin(response);
          } else if (response.isotpOutValidated == "1") {
            const TwoPhaseToken = response.token;
            if (TwoPhaseToken) {
              const expiresInDuration = response.expiration;
              const now = new Date();
              const expirationDate = new Date(
                now.getTime() + expiresInDuration
              );
              // localStorage.setItem("two", expirationDate.toISOString());
              this.saveTwoStepAuthData(TwoPhaseToken, expirationDate);

              this.router.navigate(["/user/two-step-verification/enter-pin"]);
            }
          }
        },
        (error) => {
          if (error.status == 400) {
            if (error.error.comment == "You haven't activated your account") {
              const regToken = error.error.refreshToken;
              const regExpiration = error.error.refreshExpiration;
              const now = new Date();
              const regExpirationDate = new Date(
                now.getTime() + regExpiration * 1000
              );
              this.saveRegAuthData(regToken, regExpirationDate.toISOString());
              this.router.navigate([
                "/signup/get-pin",
                error.error.userData.mobile,
              ]);
            }
          }
          //console.log(error);
        }
      );
  }

  getToken() {
    return this.token;
  }
  getRefreshToken() {
    return this.refreshToken;
  }
  getRegisterToken() {
    let regToken = "";
    if (localStorage.getItem("regToken")) {
      regToken = localStorage.getItem("regToken");
      return regToken;
    } else {
      return regToken;
    }
  }
  //token sent during pin verify in forgot password
  getVerifyMobileToken() {
    let verifyMobileToken = "";
    if (localStorage.getItem("verifyMobileToken")) {
      verifyMobileToken = localStorage.getItem("verifyMobileToken");
      return verifyMobileToken;
    } else {
      return verifyMobileToken;
    }
  }
  getTwoStepAuthToken() {
    let twoStepAuth = "";
    if (localStorage.getItem("twoStepAuth")) {
      twoStepAuth = localStorage.getItem("twoStepAuth");
      return twoStepAuth;
    } else {
      return twoStepAuth;
    }
  }
  //token sent to change the user password
  getChangePasswordToken() {
    let changePasswordToken = "";
    if (localStorage.getItem("changePasswordToken")) {
      changePasswordToken = localStorage.getItem("changePasswordToken");
      return changePasswordToken;
    } else {
      return changePasswordToken;
    }
  }
  getAuthenticated() {
    return this.isAuthenticated;
  }
  getUserData() {
    return this.userData;
  }
  twoStepVerifyPinAndGetTokens(pin_number: string, form: any) {
    const formData = {
      otp: pin_number,
    };
    this.http
      .post<{
        comment: string;
        token: string;
        expiration: number;
      }>(this.hostIpWithPort + "/api/v3/user/verify-otp", formData)
      .subscribe(
        (response: any) => {
          this.clearTwoStepAuthData();
          if (response.status == "success") {
            this.initSuccessLogin(response);
          } else {
            //stay on the same location
          }
        },
        (error) => {
          // console.log(error);
          form.reset();
          if (error.error.errCode == "-1994") {
            this.router.navigate(["/login"]);
          }
        }
      );
  }
  getTwoStepVerifyResendPin() {
    return this.http.post(
      this.hostIpWithPort + "/api/v3/user/resend-otp",
      null
    );
  }
  initSuccessLogin(response) {
    const isEligibleForTargetingCampaigns =
      response.userData.isEligibleForTargetingCampaigns;
    const paymentType = response.userData.payment_type;
    const token = response.token;
    this.token = token;
    const refreshToken = response.refreshToken;
    this.refreshToken = refreshToken;

    if (token && refreshToken) {
      const expiresInDuration = response.expiration;
      const refreshExpiresInDuration = response.refreshExpiration;

      this.setAuthTimer(expiresInDuration);
      this.isAuthenticated = true;
      this.userData = response.userData;

      this.authStatusListner.next(true);

      const now = new Date();
      const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
      const refreshExpirationDate = new Date(
        now.getTime() + refreshExpiresInDuration * 1000
      );
      this.saveAuthData(
        this.token,
        expirationDate,
        this.refreshToken,
        refreshExpirationDate,
        isEligibleForTargetingCampaigns,
        paymentType
      );
      //console.log(response);
      this.router.navigate(["/user/dashboard"]);
    }
  }
  logout() {
    this.token = null;
    this.isAuthenticated = false;
    this.userData = null;
    this.authStatusListner.next(false);
    clearTimeout(this.tokenTimer);
    this.clearRegAuthData();
    this.clearAuthData();
    //token sent during pin verify in forgot password
    this.clearVerifyMobileAuthData();
    this.clearTwoStepAuthData();
    //token sent to change password
    this.clearChangePasswordAuthData();
    //token sent to change paswrod while loggedin
    this.clearAddNewPasswordWhileLoggedAuthData();
    this.router.navigate(["/login"]);
  }
  public clearRegAuthData() {
    localStorage.removeItem("regToken");
    localStorage.removeItem("regExpirationDate");
  }
  private saveRegAuthData(regToken: string, regExpirationDate: string) {
    localStorage.setItem("regToken", regToken);
    localStorage.setItem("regExpirationDate", regExpirationDate);
  }
  //token sent during pin verify in forgot password
  public clearVerifyMobileAuthData() {
    localStorage.removeItem("verifyMobileToken");
    localStorage.removeItem("verifyMobileExpirationDate");
  }
  public saveVerifyMobileAuthData(
    verifyMobileToken: string,
    verifyMobileExpirationDate: string
  ) {
    localStorage.setItem("verifyMobileToken", verifyMobileToken);
    localStorage.setItem(
      "verifyMobileExpirationDate",
      verifyMobileExpirationDate
    );
  }
  private saveTwoStepAuthData(
    twoStepAuthToken: string,
    twoStepAuthExpirationDate: Date
  ) {
    localStorage.setItem("twoStepAuth", twoStepAuthToken);
    localStorage.setItem(
      "twoStepAuthExpiration",
      twoStepAuthExpirationDate.toISOString()
    );
  }
  private clearTwoStepAuthData() {
    localStorage.removeItem("twoStepAuth");
    localStorage.removeItem("twoStepAuthExpiration");
  }
  //token sent to change the user password
  private clearChangePasswordAuthData() {
    localStorage.removeItem("changePasswordToken");
    localStorage.removeItem("changePasswordExpirationDate");
  }
  public saveChangePasswordAuthData(
    changePasswordToken: string,
    changePasswordExpirationDate: string
  ) {
    localStorage.setItem("changePasswordToken", changePasswordToken);
    localStorage.setItem(
      "changePasswordExpirationDate",
      changePasswordExpirationDate
    );
  }
  private saveAuthData(
    token: string,
    expirationDate: Date,
    refreshToken: string,
    refreshExpirationDate: Date,
    isEligibleForTargetingCampaigns: number,
    paymentType: number
  ) {
    localStorage.setItem("token", token);
    localStorage.setItem("expirationDate", expirationDate.toISOString());
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem(
      "refreshExpirationDate",
      refreshExpirationDate.toISOString()
    );
    localStorage.setItem(
      "isEligibleForTargetingCampaigns",
      isEligibleForTargetingCampaigns?.toString()
    );
    localStorage.setItem("paymentType", paymentType?.toString());
  }
  private clearAuthData() {
    localStorage.removeItem("token");
    localStorage.removeItem("expirationDate");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("refreshExpirationDate");
    localStorage.removeItem("isEligibleForTargetingCampaigns");
    localStorage.removeItem("paymentType");
  }
  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn =
      new Date(authInformation.expirationDate).getTime() - now.getTime();

    if (expiresIn > 0) {
      this.token = authInformation.token;
      this.isAuthenticated = true;
      this.setAuthTimer(expiresIn / 1000);
      this.refreshToken = authInformation.refreshToken;
      this.authStatusListner.next(true);
    }
  }
  getAuthData() {
    const token = localStorage.getItem("token");
    const expirationDate = localStorage.getItem("expirationDate");
    const refreshToken = localStorage.getItem("refreshToken");
    const refreshExpirationDate = localStorage.getItem("refreshExpirationDate");
    if (!token || !expirationDate || !refreshToken || !refreshExpirationDate) {
      return;
    }
    return {
      token: token,
      expirationDate: expirationDate,
      refreshToken: refreshToken,
      refreshExpirationDate: refreshExpirationDate,
    };
  }

  getEligibilityForTargetingCampaign(): boolean {
    const isEligibleForTargetingCampaigns = localStorage.getItem(
      "isEligibleForTargetingCampaigns"
    );

    return isEligibleForTargetingCampaigns === "1";
  }

  getPaymentType(): string {
    const paymentType = localStorage.getItem("paymentType");

    return paymentType;
  }
  private setAuthTimer(duration: number) {
    //console.log("Setting Timer:" + duration);
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration * 1000);
  }
  getPin() {
    return this.http.get(
      this.hostIpWithPort + "/api/v1/account/activate/pinRequester"
    );
  }
  verifyPin(pin: string, form: any) {
    const userPin = { pin: pin };
    this.http
      .post(
        this.hostIpWithPort + "/api/v1/account/activate/pinValidator",
        userPin
      )
      .subscribe(
        (response: any) => {
          //console.log(response);
          this.dialogRef = this.matDialog.open(SuccessDialogBoxComponent, {
            width: "542px",
            data: { message: response.comment },
          });
          this.router.navigate(["/login"]);
        },
        (error) => {
          //console.log(error);
          this.clearRegAuthData();
          form.reset();
          //this.router.navigate(["/login"]);
        }
      );
  }
  //password change
  verifyMobile(mobile: string) {
    const userMobile = { mobile: mobile };
    return this.http.post(
      this.hostIpWithPort + "/api/v1/account/forgot-password/mobileValidator",
      userMobile
    );
  }
  //password change
  verifyRecoveryPin(pin: string) {
    const userPin = { pin: pin };
    return this.http.post(
      this.hostIpWithPort + "/api/v1/account/forgot-password/pinValidator",
      userPin
    );
  }
  updatePassword(password: string) {
    const userPassword = { password: password };
    this.http
      .post(
        this.hostIpWithPort + "/api/v1/account/forgot-password/changePassword",
        userPassword
      )
      .subscribe(
        (response: any) => {
          //console.log(response);
          //how capture success response
          this.dialogRef = this.matDialog.open(SuccessDialogBoxComponent, {
            width: "542px",
            data: { message: response.comment },
          });
          this.router.navigate(["/login"]);
        },
        (error) => {
          //console.log(error);
          this.router.navigate(["/login"]);
        }
      );
  }
  getAccountDetails() {
    return this.http.get(this.hostIpWithPort + "/api/v1/account/user");
  }
  updateAccountDetails(
    fname: string,
    lname: string,
    address: string,
    email: string,
    remark: string
  ) {
    const user = {
      fname: fname,
      lname: lname,
      address: address,
      email: email,
      remark: remark,
    };
    return this.http.patch(
      this.hostIpWithPort + "/api/v1/account/user/edit",
      user
    );
  }
  getDashBoardInfo() {
    return this.http.get(
      this.hostIpWithPort + "/api/v1/account/user/dashboard"
    );
  }
  getNotificationInfo() {
    return this.http.get(this.hostIpWithPort + "/api/v1/notification/user");
  }
  // getDateWiseMessageList(fromDate: string, toDate: string) {
  //   const dateRange = {
  //     fromDate: fromDate,
  //     toDate: toDate,
  //   };
  //   return this.http.post(
  //     this.hostIpWithPort + "/api/v1/filter/date-wise/message",
  //     dateRange
  //   );
  // }

  getTargetingLocationCriteria() {
    return this.http.post(
      this.hostIpWithPort +
        "/api/v1/targeting-campaigns/load-json-configuration",
      null
    );
  }

  getReportCampaignsDateWise(
    currentPage: string,
    postsPerPage: string,
    fromDate: string,
    toDate: string
  ) {
    let params = {
      currentPage: currentPage,
      postsPerPage: postsPerPage,
    };
    const dateRange = {
      fromDate: fromDate,
      toDate: toDate,
    };
    return this.http.post(
      this.hostIpWithPort + "/api/v1/filter/date-wise/message",
      dateRange,
      {
        params: params,
      }
    );
  }

  getCampaignsPerMobileCampaignWise(currentPage: string, postsPerPage: string) {
    let params = {
      currentPage: currentPage,
      postsPerPage: postsPerPage,
    };
    return this.http.get(
      this.hostIpWithPort + "/api/v1/saved-contacts/get-list",
      {
        params: params,
      }
    );
  }

  getReportDailySummary(
    currentPage: string,
    postsPerPage: string,
    fromDate: string,
    toDate: string,
    campaignType: number
  ) {
    let params = {
      currentPage: currentPage,
      postsPerPage: postsPerPage,
    };
    const dateRange = {
      fromDate: fromDate,
      toDate: toDate,
    };
    return this.http.post(
      this.hostIpWithPort +
        `${
          campaignType === 1
            ? "/api/v1/filter/date-wise/bulk/summary-report"
            : campaignType === 2
            ? "/api/v1/filter/date-wise/personalized/summary-report"
            : campaignType === 3
            ? "/api/v1/filter/date-wise/targeting/summary-report"
            : campaignType === 4
            ? "/api/v1/filter/date-wise/packaging/summary-report"
            : campaignType === 5
            ? "/api/v1/filter/date-with-mask-wise/bulk/summary-report"
            : campaignType === 6
            ? "/api/v1/filter/date-with-mask-wise/personalized/summary-report"
            : campaignType === 7
            ? "/api/v1/filter/date-with-mask-wise/targeting/summary-report"
            : campaignType === 8
            ? "/api/v1/filter/date-with-mask-wise/packaging/summary-report"
            : undefined
        }`,
      dateRange,
      {
        params: params,
      }
    );
  }

  getReportMonthlySummary(
    currentPage: string,
    postsPerPage: string,
    fromMonth: string,
    toMonth: string,
    campaignType: number
  ) {
    let params = {
      currentPage: currentPage,
      postsPerPage: postsPerPage,
    };
    const dateRange = {
      fromMonth: fromMonth,
      toMonth: toMonth,
    };
    return this.http.post(
      this.hostIpWithPort +
        `${
          campaignType === 1
            ? "/api/v1/filter/month-wise/bulk/summary-report"
            : campaignType === 2
            ? "/api/v1/filter/month-wise/personalized/summary-report"
            : campaignType === 3
            ? "/api/v1/filter/month-wise/targeting/summary-report"
            : campaignType === 4
            ? "/api/v1/filter/month-wise/packaging/summary-report"
            : undefined
        }`,
      dateRange,
      {
        params: params,
      }
    );
  }

  getReportUsageEnterpriseDashboard(
    currentPage: string,
    postsPerPage: string,
    fromDate: string,
    toDate: string
  ) {
    let params = {
      currentPage: currentPage,
      postsPerPage: postsPerPage,
    };
    const dateRange = {
      fromDate: fromDate,
      toDate: toDate,
    };
    return this.http.post(
      this.hostIpWithPort + "/api/v1/filter/enter-price-report/per-user",
      dateRange,
      {
        params: params,
      }
    );
  }

  getUserReportUsage(
    currentPage: string,
    postsPerPage: string,
    year: string,
    month: string,
    campaignType: number
  ) {
    let params = {
      currentPage: currentPage,
      postsPerPage: postsPerPage,
    };
    const body = {
      year: year,
      month: month,
    };
    return this.http.post(
      this.hostIpWithPort +
        `${
          campaignType === 1
            ? "/api/v1/filter/usage-report/per-user/bulk"
            : campaignType === 2
            ? "/api/v1/filter/usage-report/per-user/personalized"
            : campaignType === 3
            ? "/api/v1/filter/usage-report/per-user/targeting"
            : campaignType === 4
            ? "/api/v1/filter/usage-report/per-user/package"
            : undefined
        }`,
      body,
      {
        params: params,
      }
    );
  }

  getReportCampaignsCampaignWise(
    currentPage: string,
    postsPerPage: string,
    fromDate: string,
    toDate: string
  ) {
    let params = {
      currentPage: currentPage,
      postsPerPage: postsPerPage,
    };
    const dateRange = {
      fromDate: fromDate,
      toDate: toDate,
    };
    return this.http.post(
      this.hostIpWithPort + "/api/v1/filter/campaign-wise/message",
      dateRange,
      {
        params: params,
      }
    );
  }

  getCampaignWiseMessageList(fromDate: string, toDate: string) {
    const dateRange = {
      fromDate: fromDate,
      toDate: toDate,
    };
    return this.http.post(
      this.hostIpWithPort + "/api/v1/filter/campaign-wise/message",
      dateRange
    );
  }
  // searchMobileList(fromDate: string, toDate: string, searchMobile: string) {
  //   const dateRange = {
  //     fromDate: fromDate,
  //     toDate: toDate,
  //     searchMobile: searchMobile,
  //   };
  //   return this.http.post(
  //     this.hostIpWithPort + "/api/v1/filter/mobile",
  //     dateRange
  //   );
  // }

  getReportMobile(
    currentPage: string,
    postsPerPage: string,
    fromDate: string,
    toDate: string,
    searchMobile: string
  ) {
    let params = {
      currentPage: currentPage,
      postsPerPage: postsPerPage,
    };
    const dateRange = {
      fromDate: fromDate,
      toDate: toDate,
      searchMobile: searchMobile,
    };
    return this.http.post(
      this.hostIpWithPort + "/api/v1/filter/mobile",
      dateRange,
      {
        params: params,
      }
    );
  }
  clearSingleNotification(id: string) {
    const campaign = {
      id: id,
    };
    return this.http.post(
      this.hostIpWithPort + "/api/v1/notification/user/clear/single",
      campaign
    );
  }
  clearAllNotification() {
    return this.http.get(
      this.hostIpWithPort + "/api/v1/notification/user/clear/all"
    );
  }
  getChangePasswordWhileLoggedPin() {
    return this.http.get(
      this.hostIpWithPort + "/api/v1/account/user/change-password/get-pin"
    );
  }
  //token sent to change the user password while logged in
  public clearAddNewPasswordWhileLoggedAuthData() {
    localStorage.removeItem("addNewPasswordtoken");
    localStorage.removeItem("addNewPasswordtokenExpirationDate");
  }
  public saveAddNewPasswordWhileLoggedAuthData(
    addNewPasswordtoken: string,
    addNewPasswordtokenExpirationDate: string
  ) {
    localStorage.setItem("addNewPasswordtoken", addNewPasswordtoken);
    localStorage.setItem(
      "addNewPasswordtokenExpirationDate",
      addNewPasswordtokenExpirationDate
    );
  }
  //token sent to change the user password while loggedin
  getAddNewPasswordWhileLoggedToken() {
    let addNewPasswordtoken = "";
    if (localStorage.getItem("addNewPasswordtoken")) {
      addNewPasswordtoken = localStorage.getItem("addNewPasswordtoken");
      return addNewPasswordtoken;
    } else {
      return addNewPasswordtoken;
    }
  }
  //verify pin  sent to change the user password while loggedin

  verifyPinAddNewPasswordWhileLogged(pin: string, form: any) {
    const userPin = {
      pin: pin,
    };
    this.http
      .post(
        this.hostIpWithPort + "/api/v1/account/user/change-password/verify-pin",
        userPin
      )
      .subscribe(
        (response: any) => {
          //console.log(response);
          const addVerifyNewPasswordtoken = response.addVerifyNewPasswordtoken;
          const addNewVeirfyPasswordtokenExpiration =
            response.addNewVeirfyPasswordtokenExpiration;
          const now = new Date();
          const addNewVerifyPasswordtokenExpirationDate = new Date(
            now.getTime() + addNewVeirfyPasswordtokenExpiration * 1000
          );
          this.saveVerifyAddNewPasswordWhileLoggedAuthData(
            addVerifyNewPasswordtoken,
            addNewVerifyPasswordtokenExpirationDate.toISOString()
          );
          this.router.navigate([
            "/account/change-password/update-new-password",
          ]);
        },
        (error) => {
          //console.log(error);
          form.reset();
          //this.router.navigate(["/user/dashboard"]);
        }
      );
  }
  //token sent to change the user password while logged in (after verifying)
  public clearVerifyAddNewPasswordWhileLoggedAuthData() {
    localStorage.removeItem("addVerifyNewPasswordtoken");
    localStorage.removeItem("addNewVerifyPasswordtokenExpirationDate");
  }
  public saveVerifyAddNewPasswordWhileLoggedAuthData(
    addVerifyNewPasswordtoken: string,
    addNewVerifyPasswordtokenExpirationDate: string
  ) {
    localStorage.setItem(
      "addVerifyNewPasswordtoken",
      addVerifyNewPasswordtoken
    );
    localStorage.setItem(
      "addNewVerifyPasswordtokenExpirationDate",
      addNewVerifyPasswordtokenExpirationDate
    );
  }
  //token sent to change the user password while loggedin
  getVeirfyAddNewPasswordWhileLoggedToken() {
    let addVerifyNewPasswordtoken = "";
    if (localStorage.getItem("addVerifyNewPasswordtoken")) {
      addVerifyNewPasswordtoken = localStorage.getItem(
        "addVerifyNewPasswordtoken"
      );
      return addVerifyNewPasswordtoken;
    } else {
      return addVerifyNewPasswordtoken;
    }
  }
  updateNewPasswordWhileLogged(password: string) {
    const userData = {
      password: password,
    };
    return this.http.post(
      this.hostIpWithPort +
        "/api/v1/account/user/change-password/update-password",
      userData
    );
  }

  getMsgKey() {
    return this.http.post(
      this.hostIpWithPort + "/api/v1/message-via-url/key/view",
      null
    );
  }

  generateMsgKey() {
    return this.http.post(
      this.hostIpWithPort + "/api/v1/message-via-url/key/generate",
      null
    );
  }

  deleteMsgKey() {
    return this.http.post(
      this.hostIpWithPort + "/api/v1/message-via-url/key/delete",
      null
    );
  }
}
