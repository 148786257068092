import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
export interface DialogData {
  message: string;
  status: string;
}
@Component({
  selector: "app-error-dialog-box",
  templateUrl: "./error-dialog-box.component.html",
  styleUrls: ["./error-dialog-box.component.css"],
})
export class ErrorDialogBoxComponent implements OnInit {
  paymentStatus = false;
  constructor(
    public dialogRef: MatDialogRef<ErrorDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close({ event: "close", data: this.paymentStatus });
  }
  ngOnInit(): void {}
}
