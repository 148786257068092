import { SelectionModel } from "@angular/cdk/collections";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { CampaignService } from "src/app/campaign/campaign.service";
import { ConfirmDialogBoxComponent } from "src/app/pop-up-messages/confirm-dialog-box/confirm-dialog-box.component";
import { SuccessDialogBoxComponent } from "src/app/pop-up-messages/success-dialog-box/success-dialog-box.component";

@Component({
  selector: "app-view-list",
  templateUrl: "./view-list.component.html",
  styleUrls: ["./view-list.component.css"],
})
export class ViewListComponent implements OnInit {
  form;

  isClickContactList = true;
  isLoading = true;
  isRefresh = false;
  isAllPaginatorChanged = false;
  isSearchPaginatorChanged = false;

  constructor(
    private campaignService: CampaignService,
    private route: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog
  ) {}

  totalPostsOne = 0;
  postsPerPageOne = 5;
  pageSizeOptionOne = [5, 10, 25, 100];
  currentPageOne = 1;

  displayedColumnsOne: string[] = ["select", "mobile_number"];
  dataSourceOne = new MatTableDataSource();
  selectionOne = [];
  @ViewChild("TableOnePaginator", { static: false })
  tableOnePaginator: MatPaginator;
  @ViewChild("TableOneSort") tableOneSort: MatSort;

  totalPostsTwo = 0;
  postsPerPageTwo = 5;
  pageSizeOptionTwo = [5, 10, 25, 100];
  currentPageTwo = 1;

  dataSourceAllTwo;
  dataSourceTwo;
  displayedColumnsTwo: string[] = ["select", "mobile_number"];
  selectionTwo = [];
  @ViewChild("TableTwoPaginator", { static: false })
  tableTwoPaginator: MatPaginator;
  @ViewChild("TableTwoSort") tableTwoSort: MatSort;
  // @ViewChild(MatSort) set TableTwoSort(tableTwoSort: MatSort) {
  //   if (this.dataSourceTwo) {
  //     this.dataSourceTwo.sort = tableTwoSort;
  //   }
  // }

  contactList: {
    id: Number;
    name: String;
    created: String;
    edited: String;
    count: Number;
    status: Number;
  };

  ngOnInit(): void {
    this.form = new FormGroup({
      searchParam: new FormControl(null, {
        validators: [Validators.required, Validators.maxLength(11)],
      }),
    });

    if (this.route.snapshot.params["id"]) {
      this.fetchAllData(
        this.currentPageOne,
        this.postsPerPageOne,
        this.route.snapshot.params["id"]
      );
    } else {
      this.router.navigate(["/contact/list"]);
    }
  }

  fetchAllData(currentPage, postsPerPage, id) {
    this.isLoading = true;
    this.dataSourceOne = new MatTableDataSource(null);
    //get campaign list

    this.campaignService
      .getContactNumberList(currentPage, postsPerPage.toString(), id)
      .subscribe(
        (response: any) => {
          //console.log(response);
          // this.totalPostsOne = response.data.count;
          ////////console.log(this.totalPosts);
          var data = response?.data?.numbersArrays?.map((a: any) => ({
            mobile_number: a.mobile_number,
            id: a.id,
          }))||[];
          this.dataSourceOne = new MatTableDataSource(data);
          this.dataSourceOne.sort = this.tableOneSort;

          if (data.length < this.postsPerPageOne) {
            this.totalPostsOne = this.currentPageOne * this.postsPerPageOne;
          } else {
            this.totalPostsOne = this.currentPageOne * this.postsPerPageOne + 1;
          }

          this.contactList = {
            id: response.data.numberList[0].id,
            name: response.data.numberList[0].name,
            created: response.data.numberList[0].created,
            edited: response.data.numberList[0].edited,
            count: response.data.numberList[0].total_numbers,
            status: response.data.numberList[0].status,
          };

          this.isLoading = false;
          this.isAllPaginatorChanged = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.isAllPaginatorChanged = false;
          // this.router.navigate(["/contact/list"]);
        }
      );
  }

  onChangedAllPage(pageData: PageEvent) {
    this.currentPageOne = pageData.pageIndex + 1;
    this.postsPerPageOne = pageData.pageSize;
    this.isAllPaginatorChanged = true;
    if (this.route.snapshot.params["id"]) {
      this.fetchAllData(
        this.currentPageOne,
        this.postsPerPageOne,
        this.route.snapshot.params["id"]
      );
    } else {
      this.router.navigate(["/contact/list"]);
    }
  }

  fetchSearchData(currentPage, postsPerPage, searchParam, id) {
    this.isLoading = true;
    this.dataSourceTwo = new MatTableDataSource(null);
    //get campaign list

    this.campaignService
      .contactViewListGlobalSearch(
        currentPage,
        postsPerPage.toString(),
        searchParam,
        id
      )
      .subscribe(
        (response: any) => {
          ////////console.log(response);
          // this.totalPostsTwo = response.data.count;
          ////////console.log(this.totalPosts);
          this.dataSourceAllTwo = response?.data?.numbersArrays||[];
          this.dataSourceTwo = new MatTableDataSource(this.dataSourceAllTwo);
          this.dataSourceTwo.sort = this.tableTwoSort;
          if (this.dataSourceAllTwo.length < this.postsPerPageTwo) {
            this.totalPostsTwo = this.currentPageTwo * this.postsPerPageTwo;
          } else {
            this.totalPostsTwo = this.currentPageTwo * this.postsPerPageTwo + 1;
          }
          // this.cdr.detectChanges();
          this.isLoading = false;
          this.isRefresh = false;
          this.isSearchPaginatorChanged = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.isRefresh = false;
          this.isSearchPaginatorChanged = false;
        }
      );
  }

  applySearch(formDirective: FormGroupDirective) {
    if (this.form.invalid) {
      return;
    }

    this.isRefresh = true;
    this.isSearchPaginatorChanged = true;

    this.postsPerPageTwo = 5;
    this.pageSizeOptionTwo = [5, 10, 25, 100];
    this.currentPageTwo = 1;

    let mobileNumber;
    if (this.form.value.searchParam.length == 9) {
      mobileNumber = this.form.value.searchParam;
    } else if (this.form.value.searchParam.length == 10) {
      mobileNumber = this.form.value.searchParam.substr(1);
    } else if (this.form.value.searchParam.length == 11) {
      mobileNumber = this.form.value.searchParam.substr(2);
    } else {
      mobileNumber = this.form.value.searchParam;
    }

    if (this.route.snapshot.params["id"]) {
      this.fetchSearchData(
        this.currentPageTwo,
        this.postsPerPageTwo,
        mobileNumber,
        this.route.snapshot.params["id"]
      );
    } else {
      this.router.navigate(["/contact/list"]);
    }
  }

  onChangedSearchPage(pageData: PageEvent) {
    //////console.log(pageData);
    this.currentPageTwo = pageData.pageIndex + 1;
    this.postsPerPageTwo = pageData.pageSize;
    this.isSearchPaginatorChanged = true;

    let mobileNumber;
    if (this.form.value.searchParam.length == 9) {
      mobileNumber = this.form.value.searchParam;
    } else if (this.form.value.searchParam.length == 10) {
      mobileNumber = this.form.value.searchParam.substr(1);
    } else if (this.form.value.searchParam.length == 11) {
      mobileNumber = this.form.value.searchParam.substr(2);
    } else {
      mobileNumber = this.form.value.searchParam;
    }

    if (this.route.snapshot.params["id"]) {
      this.fetchSearchData(
        this.currentPageTwo,
        this.postsPerPageTwo,
        mobileNumber,
        this.route.snapshot.params["id"]
      );
    } else {
      this.router.navigate(["/contact/list"]);
    }
  }

  removeNewNumberModalInListView(numberList) {
    var title;
    var message;

    title = "Delete Numbers from Contact List";
    message =
      "Are you sure, you want to remove this numbers from contact list?";
    const confirmDialog = this.matDialog.open(ConfirmDialogBoxComponent, {
      data: {
        title: title,
        message: message,
      },
    });
    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        var numbers = numberList.map((a) => {
          return a.id;
        });
        this.campaignService
          .deleteNumbersContactList(this.contactList?.id.toString(), numbers)
          .subscribe(
            (response: any) => {
              // console.log(response);
              this.matDialog.open(SuccessDialogBoxComponent, {
                width: "532px",
                data: {
                  message: response.comment,
                },
              });
              let mobileNumber;
              if (this.form.value?.searchParam) {
                if (this.form.value?.searchParam?.length == 9) {
                  mobileNumber = this.form.value?.searchParam;
                } else if (this.form.value?.searchParam?.length == 10) {
                  mobileNumber = this.form.value?.searchParam?.substr(1);
                } else if (this.form.value.searchParam.length == 11) {
                  mobileNumber = this.form.value?.searchParam?.substr(2);
                } else {
                  mobileNumber = this.form.value?.searchParam;
                }
              }
              this.fetchAllData(
                this.currentPageOne,
                this.postsPerPageOne,
                this.route.snapshot.params["id"]
              );
              this.fetchSearchData(
                this.currentPageTwo,
                this.postsPerPageTwo,
                mobileNumber,
                this.route.snapshot.params["id"]
              );
              this.selectionOne = [];
              this.selectionTwo = [];
            },
            (error) => {
              console.log(error);
            }
          );
      }
    });
  }

  addNewNumberOpenModal() {
    const dialogRef = this.matDialog.open(ContactListAddNumberComponent, {
      width: "542px",
      data: {
        contactListId: this.route.snapshot.params["id"],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data == true) {
        if (this.route.snapshot.params["id"]) {
          if (this.form.value.searchParam) {
            let mobileNumber;
            if (this.form.value.searchParam.length == 9) {
              mobileNumber = this.form.value.searchParam;
            } else if (this.form.value.searchParam.length == 10) {
              mobileNumber = this.form.value.searchParam.substr(1);
            } else if (this.form.value.searchParam.length == 11) {
              mobileNumber = this.form.value.searchParam.substr(2);
            } else {
              mobileNumber = this.form.value.searchParam;
            }

            this.fetchSearchData(
              this.currentPageTwo,
              this.postsPerPageTwo,
              mobileNumber,
              this.route.snapshot.params["id"]
            );
          }

          this.fetchAllData(
            this.currentPageOne,
            this.postsPerPageOne,
            this.route.snapshot.params["id"]
          );
        } else {
          this.router.navigate(["/contact/list"]);
          this.isLoading = false;
        }
      }
      this.isLoading = false;
    });
  }
  removeNewNumberOpenModal() {
    const dialogRef = this.matDialog.open(ContactListRemoveNumberComponent, {
      width: "542px",
      data: {
        contactListId: this.route.snapshot.params["id"],
        mobileSample: null,
        messageDisplayed: null,
        disabledText: 0,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data == true) {
        if (this.route.snapshot.params["id"]) {
          if (this.form.value.searchParam) {
            let mobileNumber;
            if (this.form.value.searchParam.length == 9) {
              mobileNumber = this.form.value.searchParam;
            } else if (this.form.value.searchParam.length == 10) {
              mobileNumber = this.form.value.searchParam.substr(1);
            } else if (this.form.value.searchParam.length == 11) {
              mobileNumber = this.form.value.searchParam.substr(2);
            } else {
              mobileNumber = this.form.value.searchParam;
            }

            this.fetchSearchData(
              this.currentPageTwo,
              this.postsPerPageTwo,
              mobileNumber,
              this.route.snapshot.params["id"]
            );
          }

          this.fetchAllData(
            this.currentPageOne,
            this.postsPerPageOne,
            this.route.snapshot.params["id"]
          );
        } else {
          this.router.navigate(["/contact/list"]);
        }
      }
      this.isLoading = false;
    });
  }

  headerClick(val) {
    this.isClickContactList = val;
    if (!val) {
      this.form.patchValue({ searchParam: null });
      this.form.get("searchParam").updateValueAndValidity();

      this.totalPostsTwo = 0;
      this.postsPerPageTwo = 5;
      this.pageSizeOptionTwo = [5, 10, 25, 100];
      this.currentPageTwo = 1;

      this.dataSourceTwo = new MatTableDataSource(null);
      this.selectionTwo = [];
    } else {
      this.isAllPaginatorChanged = true;
      if (this.route.snapshot.params["id"]) {
        this.selectionOne = [];
        this.fetchAllData(
          this.currentPageOne,
          this.postsPerPageOne,
          this.route.snapshot.params["id"]
        );
      } else {
        this.router.navigate(["/contact/list"]);
      }
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(dataSource, selection) {
    const alreadySelected = dataSource?.data?.filter((o1: any) =>
      selection?.some((o2: any) => o1.id === o2.id)
    );
    const numSelected = alreadySelected?.length;
    const numRows = dataSource?.data?.length;
    return numRows ? numSelected === numRows : false;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows(dataSource, selection) {
    if (this.isAllSelected(dataSource, selection)) {
      this.removeMultiple(dataSource?.data, selection);
      return;
    }

    const alreadySelected = dataSource?.data?.filter((o1: any) =>
      selection?.some((o2: any) => o1.id === o2.id)
    );

    if (alreadySelected?.length >= 0) {
      this.removeMultiple(alreadySelected, selection);
    }

    selection.push(...dataSource?.data);
  }

  selectionChange(row, event, selection) {
    if (event.checked) {
      selection.push(...[row]);
    } else {
      this.removeMultiple([row], selection);
    }
  }

  selectionChecked(row, selection) {
    return selection?.some((element) => {
      if (element.id === row.id) {
        return true;
      }

      return false;
    });
  }

  removeMultiple(array, selection) {
    array.forEach((element) => {
      const index = selection.findIndex((x) => x.id === element.id);
      selection.splice(index, 1);
    });
  }
}

export interface DialogData {
  contactListId: string;
  mobileSample: string;
  messageDisplayed: string;
  disabledText: number;
}
@Component({
  selector: "contact-list-add-number-dialog",
  templateUrl: "./contact-list-add-number-modal.component.html",
  styleUrls: ["./contact-list-add-number-modal.component.css"],
})
export class ContactListAddNumberComponent implements OnInit {
  selectedPaymentOption = null;
  transactionStatus = false;
  disableYes = false;
  form: FormGroup;
  ngOnInit() {
    if (this.data.contactListId == null) {
      this.closeDialog();
    }
    this.form = new FormGroup({
      mobile: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(11),
          Validators.pattern("(^07[0-9]{8}$)|(^947[0-9]{8}$)|(^7[0-9]{8}$)"),
        ],
      }),
    });
  }
  constructor(
    public dialogRef: MatDialogRef<ContactListAddNumberComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private campaignService: CampaignService,
    private router: Router,
    private matDialog: MatDialog
  ) {
    dialogRef.disableClose = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  closeDialog() {
    //this.router.navigate(["/contact/view-list", this.data.contactListId]);
    this.dialogRef.close({ event: "close", data: this.transactionStatus });
  }

  addNumber(formDirective: FormGroupDirective) {
    this.transactionStatus = false;
    this.disableYes = true;
    if (!this.data.contactListId || this.form.invalid) {
      this.disableYes = false;
      return;
    } else {
      let mobileNumber;
      if (this.form.value.mobile.length == 9) {
        mobileNumber = "94" + this.form.value.mobile;
      } else if (this.form.value.mobile.length == 10) {
        mobileNumber = "94" + this.form.value.mobile.substr(1);
      } else if (this.form.value.mobile.length == 11) {
        mobileNumber = this.form.value.mobile;
      }
      this.campaignService
        .addNewMobile(this.data.contactListId, mobileNumber)
        .subscribe(
          (response: any) => {
            // console.log(response);
            this.transactionStatus = true;
            this.disableYes = false;
            this.closeDialog();
            this.matDialog.open(SuccessDialogBoxComponent, {
              width: "532px",
              data: {
                message: mobileNumber + " is added to this contact list",
              },
            });
          },
          (error) => {
            //console.log(error);
            this.disableYes = false;
            this.closeDialog();
          }
        );
    }
  }
}
@Component({
  selector: "contact-list-remove-number-dialog",
  templateUrl: "./contact-list-remove-number-modal.component.html",
  styleUrls: ["./contact-list-remove-number-modal.component.css"],
})
export class ContactListRemoveNumberComponent implements OnInit {
  selectedPaymentOption = null;
  transactionStatus = false;
  disableYes = false;
  form: FormGroup;
  ngOnInit() {
    if (this.data.contactListId == null) {
      this.closeDialog();
    }
    this.form = new FormGroup({
      mobile: new FormControl(this.data.mobileSample, {
        validators: [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(11),
          Validators.pattern("(^07[0-9]{8}$)|(^947[0-9]{8}$)|(^7[0-9]{8}$)"),
        ],
      }),
    });
  }
  constructor(
    public dialogRef: MatDialogRef<ContactListRemoveNumberComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private campaignService: CampaignService,
    private router: Router,
    private matDialog: MatDialog
  ) {
    dialogRef.disableClose = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close({ event: "close", data: this.transactionStatus });
  }

  addNumber(formDirective: FormGroupDirective) {
    this.transactionStatus = false;
    this.disableYes = true;
    if (!this.data.contactListId || this.form.invalid) {
      this.disableYes = false;
      return;
    } else {
      let mobileNumber;
      if (this.form.value.mobile.length == 9) {
        mobileNumber = "94" + this.form.value.mobile;
      } else if (this.form.value.mobile.length == 10) {
        mobileNumber = "94" + this.form.value.mobile.substr(1);
      } else if (this.form.value.mobile.length == 11) {
        mobileNumber = this.form.value.mobile;
      }
      this.campaignService
        .removeMobile(this.data.contactListId, mobileNumber)
        .subscribe(
          (response: any) => {
            // console.log(response);
            this.transactionStatus = true;
            this.disableYes = false;
            this.closeDialog();
            this.matDialog.open(SuccessDialogBoxComponent, {
              width: "532px",
              data: {
                message: mobileNumber + " is removed from this contact list",
              },
            });
          },
          (error) => {
            //console.log(error);
            this.disableYes = false;
            this.closeDialog();
          }
        );
    }
  }
}
