import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inbox-secondary-header',
  templateUrl: './inbox-secondary-header.component.html',
  styleUrls: ['./inbox-secondary-header.component.css']
})
export class InboxSecondaryHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
