import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { ErrorComponent } from "./error/error.component";
import { SuccessDialogBoxComponent } from "./pop-up-messages/success-dialog-box/success-dialog-box.component";
import { ErrorDialogBoxComponent } from "./pop-up-messages/error-dialog-box/error-dialog-box.component";
import { Router } from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private matDialog: MatDialog, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        //console.log(error.error.comment);
        //alert(error.error.error.errors[0].message);
        let errorMessage =
          "Connectivity Failure! Please try again in a moment.";
        if (error.error.comment) {
          errorMessage = error.error.comment;
        }
        this.matDialog.open(ErrorDialogBoxComponent, {
          width: "542px",
          data: { message: errorMessage },
        });
        if (error.error.errCode == 998) {
          this.router.navigate(["/login"]);
        }
        return throwError(error);
      })
    );
  }
}
