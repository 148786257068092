import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "app-chip-autocomplete-tageted",
  templateUrl: "./chip-autocomplete-tageted.component.html",
  styleUrls: ["./chip-autocomplete-tageted.component.css"],
})
export class ChipAutocompleteTagetedComponent implements OnInit {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredChips: Observable<string[]>;

  @Input() form: FormGroup;
  @Input() formName: string;
  @Input() allChips: string[];
  @Input() label;
  @Input() placeholder;

  chipCtrl = new FormControl("");

  chips: string[] = [];

  @ViewChild("chipInput") chipInput: ElementRef<HTMLInputElement>;

  constructor() {
    this.updateAllChips();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.chips = this.chips.filter((item) => this.allChips.includes(item));
    this.allChips = this.allChips?.filter((item) => !this.chips.includes(item));

    if (this.chips?.length > 0) {
      this.chipCtrl.setValue(null);
      this.form?.get(this.formName)?.setValue(this.chips);
    }
    this.updateAllChips();
  }

  ngOnInit(): void {
    if (
      this.form?.get(this.formName)?.value &&
      this.form?.get(this.formName)?.value?.length > 0
    ) {
      this.chips = this.form?.get(this.formName).value;
      this.form?.get(this.formName).value.forEach((e) => {
        this.allChipsRemove(e);
      });
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();

    // Add our fruit
    if (value) {
      this.chips.push(value);
    }

    // Reset the input value
    if (event.input) {
      this.chipInput.nativeElement.value = "";
    }

    this.chipCtrl.setValue(null);
    this.form?.get(this.formName)?.setValue(this.chips);
  }

  remove(chip: string): void {
    const index = this.chips.indexOf(chip);

    if (index >= 0) {
      this.chips.splice(index, 1);
    }
    this.chipCtrl.setValue(null);
    this.form?.get(this.formName)?.setValue(this.chips);
    this.allChipsAdd(chip);
  }

  removeAllChip(allShips): void {
    this.chips = [];
    this.chipCtrl.setValue(null);
    this.form?.get(this.formName)?.setValue([]);

    this.allChips = allShips;
    this.updateAllChips();
  }

  allChipsRemove(chip: string): void {
    const index = this.allChips.indexOf(chip);

    if (index >= 0) {
      this.allChips.splice(index, 1);
    }
  }

  allChipsAdd(chip: string): void {
    this.allChips.push(chip);
    this.updateAllChips();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.allChipsRemove(event.option.viewValue);
    this.chips.push(event.option.viewValue);
    this.chipInput.nativeElement.value = "";
    this.chipCtrl.setValue(null);
    this.form?.get(this.formName)?.setValue(this.chips);
  }

  updateAllChips() {
    this.filteredChips = this.chipCtrl.valueChanges.pipe(
      startWith(null),
      map((chip: string | null) =>
        chip ? this._filter(chip) : this.allChips?.sort().slice()
      )
    );
  }

  private _filter(value: any): string[] {
    const filterValue = value.toLowerCase();

    return this.allChips
      .filter((fruit) => fruit.toLowerCase().includes(filterValue))
      .sort();
  }
}
