import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormsModule,
  FormGroupDirective,
} from "@angular/forms";
import { mimeType } from "./mime-type.validator";
import { from, Subscription } from "rxjs";
import { passValidator } from "./confirm-password.validator";
import { AuthService } from "../auth.service";
import { AuthData } from "../auth-data.model";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.css"],
})
export class SignupComponent implements OnInit, OnDestroy {
  form: FormGroup;
  image_name: string = null;
  isLoading = false;
  private authStatusSub: Subscription;
  constructor(public authService: AuthService, private matDialog: MatDialog) {}
  file_select = false;
  show;
  confirmShow;
  ngOnInit(): void {
    this.show = false;
    this.confirmShow = false;
    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe((status) => {
        this.isLoading = status;
      });
    this.form = new FormGroup({
      fname: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern("^[a-zA-Z0-9-.,_'\"`|:() /]*$"),
        ],
      }),
      lname: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern("^[a-zA-Z0-9-.,_'\"`|:() /]*$"),
        ],
      }),
      address: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(10),
          Validators.pattern("^[a-zA-Z0-9-.,_'\"`|:()\n\r /]*$"),
        ],
      }),
      email: new FormControl(null, {
        validators: [Validators.required, Validators.email],
      }),
      nic: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9]*$"),
          Validators.minLength(4),
        ],
      }),
      mask: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(11),
          Validators.pattern("^[a-zA-Z0-9 _~'&-.@]*$"),
        ],
      }),
      mobile: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(11),
          Validators.pattern("(^07[0-9]{8}$)|(^947[0-9]{8}$)|(^7[0-9]{8}$)"),
        ],
      }),
      username: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(20),
          Validators.pattern("^[a-zA-Z0-9]*$"),
        ],
      }),
      password: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.pattern(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,50}$/
          ),
        ],
      }),
      //^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$

      cpassword: new FormControl(null, {
        validators: [Validators.required, passValidator],
      }),
      imageName: new FormControl(null, {
        validators: [Validators.required],
      }),
      condition: new FormControl(null, {
        validators: [Validators.required, Validators.minLength(1)],
      }),
      image: new FormControl(null, {
        validators: [Validators.required],
        asyncValidators: [mimeType],
      }),
      accountUsedFor: new FormControl("1", {
        validators: [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1),
        ],
      }),
    });
  }
  onRegister(formDirective: FormGroupDirective) {
    this.file_select = true;
    console.log(this.form);
    if (
      this.form.invalid ||
      this.form.get("password").value != this.form.get("cpassword").value ||
      (this.form.value.mobile.length != 9 &&
        this.form.value.mobile.length != 10 &&
        this.form.value.mobile.length != 11)
    ) {
      return;
    } else {
      let mobileNumber;
      if (this.form.value.mobile.length == 9) {
        mobileNumber = this.form.value.mobile;
      } else if (this.form.value.mobile.length == 10) {
        mobileNumber = this.form.value.mobile.substr(1);
      } else if (this.form.value.mobile.length == 11) {
        mobileNumber = this.form.value.mobile.substr(2);
      }
      //console.log();
      // this.form.reset();
      // formDirective.resetForm();
      const user: AuthData = {
        fname: this.form.get("fname").value,
        lname: this.form.get("lname").value,
        address: this.form.get("address").value,
        mobile: mobileNumber,
        username: this.form.get("username").value,
        password: this.form.get("password").value,
        email: this.form.get("email").value,
        nic_brc: this.form.get("nic").value,
        default_mask: this.form.get("mask").value,
        agree_to_terms: 1,
        accountUsedFor: this.form.value.accountUsedFor,
      };
      this.isLoading = true;
      this.authService.registerUser(
        user.fname,
        user.lname,
        user.address,
        user.mobile,
        user.username,
        user.password,
        user.email,
        user.nic_brc,
        user.default_mask,
        user.agree_to_terms,
        this.form.value.image,
        user.accountUsedFor
      );
    }
  }
  onImagePicked(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({ image: file });
    this.form.get("image").updateValueAndValidity();
    this.form.patchValue({ imageName: file.name });
    this.form.get("imageName").updateValueAndValidity();
  }
  resetPassword() {
    this.form.get("cpassword").reset();
  }
  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }
  passwordShow() {
    this.show = !this.show;
  }
  confirmPasswordShow() {
    this.confirmShow = !this.confirmShow;
  }
  viewTermsAndCondition() {
    const dialogRef = this.matDialog.open(TermsConditionsDialog, {
      width: "100%",
      height: "542px",
      data: {},
    });
  }
  viewAcccountUssage() {
    const dialogRef = this.matDialog.open(AccountUssageDialog, {
      height: "542px",
      data: {},
    });
  }
}
export interface DialogImageData {
  resourcePath: string;
}
@Component({
  selector: "terms-conditions-dialog",
  templateUrl: "./terms-conditions-modal.component.html",
  styleUrls: ["./terms-conditions-modal.component.css"],
})
export class TermsConditionsDialog implements OnInit {
  selectedPaymentOption = null;
  requestStatus = false;
  ngOnInit() {}
  constructor(
    public dialogRef: MatDialogRef<TermsConditionsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogImageData,
    private authService: AuthService,
    private router: Router,
    private matDialog: MatDialog
  ) {
    dialogRef.disableClose = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close({ event: "close", data: this.requestStatus });
  }

  onStatusChnage() {
    this.dialogRef.close();
  }
}
@Component({
  selector: "account-ussage-dialog",
  templateUrl: "./account-type-model.html",
  styleUrls: ["./account-type-model.css"],
})
export class AccountUssageDialog implements OnInit {
  selectedPaymentOption = null;
  requestStatus = false;
  ngOnInit() {}
  constructor(
    public dialogRef: MatDialogRef<AccountUssageDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogImageData,
    private authService: AuthService,
    private router: Router,
    private matDialog: MatDialog
  ) {
    dialogRef.disableClose = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close({ event: "close", data: this.requestStatus });
  }

  onStatusChnage() {
    this.dialogRef.close();
  }
}
