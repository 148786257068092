<section class="wrapper">
  <div class="container-fluid">

    <section class="e-card e-card--no-paddings e-card--mobile-margin-invert m-5 pb-4">
      <div class="row">
        <div class="col-lg-12" style="min-height: 75vh;">
          <mat-tab-group (selectedTabChange)="tabChangeMain($event)">
            <mat-tab label="Bulk SMS">
              <app-campaign-create *ngIf="tabType === 1"></app-campaign-create>
            </mat-tab>
            <mat-tab label="Personalized SMS">
              <app-personalize-sms *ngIf="tabType === 2"></app-personalize-sms>
            </mat-tab>
            <mat-tab label="Targeted SMS" *ngIf="isEligibleForTargetingCampaigns">
              <app-targeted-campaign-create *ngIf="tabType === 3"></app-targeted-campaign-create>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </section>
  </div>
</section>
