import { Component, OnInit, Inject } from "@angular/core";
import { AuthService } from "src/app/auth/auth.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { CampaignService } from "src/app/campaign/campaign.service";
import { Router } from "@angular/router";

import { FormGroup } from "@angular/forms";
import { findIndex } from "rxjs/operators";
export interface DialogData {
  id: number;
  created_time: string;
  creator_id: string;
  message: string;
  msg_type: string;
  status: string;
  subject: string;
  url: string;
  user_id: string;
}
@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.css"],
})
export class NotificationComponent implements OnInit {
  account = null;
  isLoading = true;
  constructor(private authService: AuthService, private matDialog: MatDialog) {}

  ngOnInit(): void {
    this.authService.getNotificationInfo().subscribe(
      (response: any) => {
        if (response.status == "success" && response.comment == "success") {
          //console.log(response);
          this.account = response.userData;
          this.isLoading = false;
        }
      },
      (error) => {
        this.isLoading = false;
        //console.log(error);
      }
    );
  }
  marAllAsRead() {
    this.authService.clearAllNotification().subscribe(
      (response) => {
        let i;
        if (this.account?.notification != null) {
          for (i = 0; i < this.account.notification.length; i++) {
            this.account.notification[i].status = 1;
          }
        }
      },
      (error) => {
        //console.log(error);
      }
    );
  }
  openDialog(
    index: number,
    id: number,
    created_time: string,
    creator_id: string,
    message: string,
    msg_type: string,
    status: string,
    subject: string,
    url: string,
    user_id: string
  ): void {
    if (this.account != null) {
      if (+status == 0) {
        this.account.notification[index].status = 1;
        this.authService.clearSingleNotification(id.toString()).subscribe(
          (response) => {},
          (error) => {
            //console.log(error);
          }
        );
      }
      this.matDialog.open(UserNotifcationPopupDialogComponent, {
        width: "542px",

        data: {
          id: id,
          created_time: created_time,
          creator_id: creator_id,
          message: message,
          msg_type: msg_type,
          status: status,
          subject: subject,
          url: url,
          user_id: user_id,
        },
      });
    }
  }
  findNotification(id: number) {}
}
@Component({
  selector: "user-notification-popup-dialog",
  templateUrl: "./notification-dialog.component.html",
  styleUrls: ["./notification-dialog.component.css"],
})
export class UserNotifcationPopupDialogComponent implements OnInit {
  selectedPaymentOption = null;
  form: FormGroup;
  isLoading = false;
  ngOnInit() {
    if (this.data.id == null) {
      this.closeDialog();
    }
  }
  constructor(
    public dialogRef: MatDialogRef<UserNotifcationPopupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private campaignService: CampaignService,
    private router: Router,
    private matDialog: MatDialog
  ) {
    dialogRef.disableClose = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
