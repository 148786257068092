<!-- <section class="container-fluid e-body-wrapper">
  <div class="row">
    <h3 class="col-12 text-center e-page-title mt-4 mb-4">Welcome to eSMS</h3>
    <div class="col-md-4 offset-md-4">
      <div class="e-card text-center">
        <form (submit)="onLogin(loginForm)" #loginForm="ngForm">
          <h4 class="mb-4 e-page-title">Login</h4>
          <mat-form-field appearance="outline" class="e-input">
            <mat-label>Username</mat-label>
            <input
              matInput
              type="text"
              name="username"
              ngModel
              required
              #userName="ngModel"
              class="textbox"
            />
            <mat-error *ngIf="userName.invalid"
              >Username cannot be empty</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="outline" class="e-input">
            <mat-label>Password</mat-label>
            <input
              [type]="show ? 'text' : 'password'"
              name="password"
              ngModel
              matInput
              required
              #passwordInput="ngModel"
              class="textbox"
            />
            <mat-icon matSuffix (click)="password()"
              ><a>visibility</a></mat-icon
            >
            <mat-error *ngIf="passwordInput.invalid"
              >Password cannot be empty</mat-error
            >
          </mat-form-field>
          <button
            mat-flat-button
            color="primary"
            class="e-btn mt-2 mb-3"
            type="submit"
          >
            LOGIN
          </button>
          <p class="e-body-txt">
            Don't have an account?
            <a routerLink="/signup" class="e-body-txt e-body-txt--highlighted"
              >SIGN UP</a
            >
          </p>
          <a
            routerLink="/account/forgot-password/enter-mobile"
            class="e-small-txt e-small-txt--highlighted"
            >FORGOT PASSWORD</a
          >
        </form>
      </div>
    </div>
  </div>
</section> -->

<section class="e-body-wrapper pb-5">
  <div class="container-fluid">
    <div class="row">
      <h3 class="col-12 text-center e-page-title mt-4 mb-4">Welcome to eSMS</h3>
      <div class="col-md-6 offset-md-3 col-lg-4 offset-lg-4">
        <div class="e-card text-center">
          <form (submit)="onLogin(loginForm)" #loginForm="ngForm">
            <h4 class="mb-4 e-page-title">Login</h4>
            <mat-form-field appearance="outline" class="e-input mb-3">
              <mat-label>Username</mat-label>
              <input matInput matInput type="text" name="username" ngModel required #userName="ngModel" />
              <mat-error *ngIf="userName.invalid">Username cannot be empty</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="e-input">
              <mat-label>Password</mat-label>
              <input matInput type="password" [type]="show ? 'text' : 'password'" name="password" ngModel matInput
                required #passwordInput="ngModel" />

              <mat-icon matSuffix (click)="password()">visibility</mat-icon>
              <mat-error *ngIf="passwordInput.invalid">Password cannot be empty</mat-error>
            </mat-form-field>
            <button mat-flat-button color="primary" class="e-btn mt-4 mb-3" type="submit"
              [disabled]="passwordInput.value == '' || userName.value == ''">
              LOGIN
            </button>
            <p class="e-body-txt">
              Don't have an account?
              <a routerLink="/signup" class="e-body-txt e-body-txt--highlighted">SIGN UP</a>
            </p>
            <a routerLink="/account/forgot-password/enter-mobile" class="e-small-txt e-small-txt--highlighted">FORGOT
              PASSWORD</a>
          </form>
        </div>
      </div>
    </div>
  </div>
  <app-help-banner *ngIf="!authService.showBanner"></app-help-banner>
</section>
