import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { CampaignService } from "src/app/campaign/campaign.service";
import { SuccessComponent } from "src/app/success/success.component";

@Component({
  selector: "app-view-template-list",
  templateUrl: "./view-template-list.component.html",
  styleUrls: ["./view-template-list.component.css"],
})
export class ViewTemplateListComponent implements OnInit {
  form: FormGroup;
  isLoading = false;
  data;
  messageRemLength = 160;
  numberOfMessages = 1;
  msg_default_size = 1;
  constructor(
    private campaignService: CampaignService,
    private router: Router,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    //form validations
    this.form = new FormGroup({
      templateListName: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern("^[a-zA-Z0-9-.,_'\"`|:() /]*$"),
        ],
      }),
      message: new FormControl("", {
        validators: [Validators.required, Validators.minLength(3)],
      }),
    });

    if (this.route.snapshot.params["id"]) {
      this.fetchViewData(this.route.snapshot.params["id"]);
    } else {
      this.router.navigate(["/template/list"]);
    }
  }

  fetchViewData(id) {
    this.isLoading = true;
    //get campaign list

    this.campaignService.getViewSingleTemplate(id).subscribe(
      (response: any) => {
        //console.log(response);
        this.data = response.data[0];

        this.form.patchValue({ templateListName: this.data.template_name });
        this.form.get("templateListName").updateValueAndValidity();

        this.form.patchValue({ message: this.data.message });
        this.form.get("message").updateValueAndValidity();

        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
        // this.router.navigate(["/contact/list"]);
      }
    );
  }

  createCampaign() {
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;
    this.campaignService
      .editTemplateList(
        this.form.value.templateListName,
        this.form.value.message,
        this.route.snapshot.params["id"]
      )
      .subscribe(
        (response: any) => {
          this.isLoading = false;

          if (response.status == "success") {
            //console.log(response);
            //navigate
            //how capture success response
            this.matDialog.open(SuccessComponent, {
              width: "542px",
              data: {
                message: "Template was edited",
              },
            });
            this.fetchViewData(this.route.snapshot.params["id"]);
          } else {
            console.log("invalid");
          }
        },
        (error) => {
          this.isLoading = false;
          console.log(error);
        }
      );
    //console.log(this.form);
  }

  messageCounterUpdate() {
    if (this.form.value.message != null) {
      if (hasUnicode(this.form.value.message)) {
        this.msg_default_size = 70;
        this.setMessageLength();
      } else {
        this.msg_default_size = 160;
        this.setMessageLength();
      }
    } else {
      this.msg_default_size = 160;
      this.setMessageLength();
    }
  }
  //function to send message counter in the UI
  setMessageLength() {
    var msg_temp_length = this.form.value.message.length;

    var quotient = Math.floor(msg_temp_length / this.msg_default_size);
    var remainder =
      (msg_temp_length - this.msg_default_size * quotient) %
      this.msg_default_size;
    if (msg_temp_length % this.msg_default_size == 0) {
      if (
        msg_temp_length == this.msg_default_size * quotient &&
        msg_temp_length != 0
      ) {
        this.messageRemLength = 0;
        this.numberOfMessages = quotient;
      } else {
        this.messageRemLength = this.msg_default_size - remainder;
        this.numberOfMessages = quotient;
      }
    } else {
      this.messageRemLength = this.msg_default_size - remainder;
      this.numberOfMessages = quotient + 1;
    }
    //setting number of messages to 1 when ever message box is empty
    if (this.form.value.message.length == 0) {
      this.numberOfMessages = 1;
    }
  }
}

function hasUnicode(str) {
  for (var i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 127) return true;
  }
  return false;
}
