import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { AuthService } from "src/app/auth/auth.service";
import * as moment from "moment";
import * as momentT from "moment-timezone";
import * as XLSX from "xlsx";
import { MatTableDataSource } from "@angular/material/table";
import { MatOption } from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";

export interface DisplayedColumns {
  key: string;
  value: string;
}

@Component({
  selector: "app-mask-usage-report",
  templateUrl: "./mask-usage-report.component.html",
  styleUrls: ["./mask-usage-report.component.css"],
})
export class MaskUsageReportComponent implements OnInit {
  years = [];
  months = [];

  data = [];
  isLoading = false;
  isRefresh = false;
  isExportingExcel = false;

  totalPostsTwo = 0;
  postsPerPageTwo = 5;
  pageSizeOptionTwo = [5, 10, 25, 100];
  currentPageTwo = 1;

  @Input() campaignType;
  dataSourceTwo;
  @Input() displayedColumnsTwo: DisplayedColumns[] = [];
  columnsToDisplayTwo: string[] = [];
  @ViewChild("TableTwoPaginator", { static: false })
  tableTwoPaginator: MatPaginator;
  @ViewChild("TableTwoSort") tableTwoSort: MatSort;

  form;
  @ViewChild("DateWiseTable") tableDate: ElementRef;
  @ViewChild("CampaignWiseTable") tableCampaign: ElementRef;

  filterColumns = new FormControl(this.columnsToDisplayTwo);
  constructor(private authService: AuthService) {
    this.dataSourceTwo = new MatTableDataSource(null);
  }

  ngOnChanges() {
    this.columnsToDisplayTwo = this.displayedColumnsTwo
      .map(function (item) {
        return item.key;
      })
      .filter((el) => el != "0")
      .slice();
  }

  ngOnInit() {
    this.filterColumns.patchValue([...this.columnsToDisplayTwo, "0"]);
    this.years = generateArrayOfYears();
    this.months = generateArrayOfMonths(moment(new Date()).format("YYYY"));
    this.form = new FormGroup({
      year: new FormControl(null, {
        validators: [Validators.required],
      }),
      month: new FormControl(null, {
        validators: [Validators.required],
      }),
    });

    this.form.patchValue({ year: moment(new Date()).format("YYYY") });
    this.form.get("year").updateValueAndValidity();

    this.form.patchValue({ month: moment(new Date()).format("MM") });
    this.form.get("month").updateValueAndValidity();
  }

  toggleColumnSelection(event: MatOption) {
    var allColumns = this.displayedColumnsTwo
      .map(function (item) {
        return item.key;
      })
      .filter((el) => el != "0")
      .slice();
    if (event.value == "0" && event.selected) {
      this.filterColumns.patchValue([
        ...this.displayedColumnsTwo.map((item) => item.key),
        "0",
      ]);
      this.columnsToDisplayTwo = this.displayedColumnsTwo
        .map(function (item) {
          return item.key;
        })
        .filter((el) => el != "0")
        .slice();
    } else if (event.value == "0" && !event.selected) {
      this.filterColumns.patchValue([]);
      this.columnsToDisplayTwo = [].slice();
    } else if (
      event.value != "0" &&
      event.selected &&
      allColumns.length == this.filterColumns.value.length
    ) {
      this.columnsToDisplayTwo = this.filterColumns.value
        .filter((el) => el != "0")
        .slice();
      this.filterColumns.patchValue([...this.columnsToDisplayTwo, "0"]);
    } else {
      this.columnsToDisplayTwo = this.filterColumns.value
        .filter((el) => el != "0")
        .slice();
      this.filterColumns.patchValue([...this.columnsToDisplayTwo]);
    }
  }

  applySearch(formDirective: FormGroupDirective) {
    // console.log(this.form);
    if (this.form.invalid) {
      return;
    }

    this.isRefresh = true;

    this.postsPerPageTwo = 5;
    this.pageSizeOptionTwo = [5, 10, 25, 100];
    this.currentPageTwo = 1;

    this.fetchDataTwo(
      this.currentPageTwo,
      this.postsPerPageTwo,
      this.form.get("month").value,
      this.form.get("year").value
    );
  }

  fetchDataTwo(currentPage, postsPerPage, month, year) {
    this.isLoading = true;
    this.dataSourceTwo = new MatTableDataSource(null);
    //get campaign list

    this.authService
      .getUserReportUsage(
        currentPage,
        postsPerPage.toString(),
        year,
        month,
        this.campaignType
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          // this.totalPostsTwo = response.data.count;
          this.data =
            response?.data?.data?.map((a) => ({
              mask: a?.mask,
              mobile_numbers_count: a?.mobile_numbers_count,
              delivered_numbers_count: a?.delivered_numbers_count,
              non_delivered_numbers_count: a?.non_delivered_numbers_count,
              sms_segments_count: a?.sms_segments_count,
              delivered_sms_segments_count: a?.delivered_sms_segments_count,
              non_delivered_sms_segments_count:
                a?.non_delivered_sms_segments_count,
              d2d_numbers_count: a?.d2d_numbers,
              d2d_delivered_numbers_count: a?.d2d_delivered_numbers,
              d2d_non_delivered_numbers_count:
                a?.d2d_none_delivered_number_count,
              d2nd_numbers_count: a?.d2nd_numbers,
              d2nd_delivered_numbers_count: a?.d2nd_delivered_numbers,
              d2nd_non_delivered_numbers_count:
                a?.d2nd_none_delivered_number_count,
              d2d_sms_segments_count: a?.d2d_sms_segments_count,
              d2d_delivered_sms_segments_count:
                a?.d2d_delivered_sms_segment_count,
              d2d_non_delivered_sms_segments_count:
                a?.d2d_none_delivered_sms_segment_count,
              d2nd_sms_segments_count: a?.d2nd_messages,
              d2nd_delivered_sms_segments_count:
                a?.d2nd_delivered_sms_segment_count,
              d2nd_non_delivered_sms_segments_count:
                a?.d2nd_none_delivered_sms_segment_count,
              d2d_cost_with_tax: Number(a?.d2d_revenue_with_tax)?.toFixed(2),
              d2nd_cost_with_tax: Number(a?.d2nd_revenue_with_tax)?.toFixed(2),
              total_cost_with_tax: Number(a?.total_cost_with_tax)?.toFixed(2),
            })) || [];
          ////////console.log(this.totalPosts);
          this.dataSourceTwo = new MatTableDataSource(this.data);
          this.dataSourceTwo.sort = this.tableTwoSort;
          if (this.data.length < this.postsPerPageTwo) {
            this.totalPostsTwo = this.currentPageTwo * this.postsPerPageTwo;
          } else {
            this.totalPostsTwo = this.currentPageTwo * this.postsPerPageTwo + 1;
          }
          this.isLoading = false;
          this.isRefresh = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.isRefresh = false;
        }
      );
  }

  onChangedPageTwo(pageData: PageEvent) {
    // console.log(pageData.pageIndex, pageData.pageSize)
    this.currentPageTwo = pageData.pageIndex + 1;
    this.postsPerPageTwo = pageData.pageSize;
    this.fetchDataTwo(
      this.currentPageTwo,
      this.postsPerPageTwo,
      this.form.get("month").value,
      this.form.get("year").value
    );
  }

  yearChanged(event: any) {
    this.months = generateArrayOfMonths(event.value);
  }

  ExportTOExcel() {
    this.isExportingExcel = true;
    this.authService
      .getUserReportUsage(
        "1",
        "all",
        this.form.get("year").value,
        this.form.get("month").value,
        this.campaignType
      )
      .subscribe(
        (response: any) => {
          //console.log(response);
          var data =
            response?.data?.data?.map((a) => {
              var row = {
                mask: a?.mask,
                mobile_numbers_count: Number(a?.mobile_numbers_count),
                delivered_numbers_count: Number(a?.delivered_numbers_count),
                non_delivered_numbers_count: Number(
                  a?.non_delivered_numbers_count
                ),
                sms_segments_count: Number(a?.sms_segments_count),
                delivered_sms_segments_count: Number(
                  a?.delivered_sms_segments_count
                ),
                non_delivered_sms_segments_count: Number(
                  a?.non_delivered_sms_segments_count
                ),
                d2d_numbers_count: Number(a?.d2d_numbers),
                d2d_delivered_numbers_count: Number(a?.d2d_delivered_numbers),
                d2d_non_delivered_numbers_count: Number(
                  a?.d2d_none_delivered_number_count
                ),
                d2nd_numbers_count: Number(a?.d2nd_numbers),
                d2nd_delivered_numbers_count: Number(a?.d2nd_delivered_numbers),
                d2nd_non_delivered_numbers_count: Number(
                  a?.d2nd_none_delivered_number_count
                ),
                d2d_sms_segments_count: Number(a?.d2d_sms_segments_count),
                d2d_delivered_sms_segments_count: Number(
                  a?.d2d_delivered_sms_segment_count
                ),
                d2d_non_delivered_sms_segments_count: Number(
                  a?.d2d_none_delivered_sms_segment_count
                ),
                d2nd_sms_segments_count: Number(a?.d2nd_messages),
                d2nd_delivered_sms_segments_count: Number(
                  a?.d2nd_delivered_sms_segment_count
                ),
                d2nd_non_delivered_sms_segments_count: Number(
                  a?.d2nd_none_delivered_sms_segment_count
                ),
                d2d_cost_with_tax: Number(
                  Number(a?.d2d_revenue_with_tax)?.toFixed(2)
                ),
                d2nd_cost_with_tax: Number(
                  Number(a?.d2nd_revenue_with_tax)?.toFixed(2)
                ),
                total_cost_with_tax: Number(
                  Number(a?.total_cost_with_tax)?.toFixed(2)
                ),
              };

              return this.columnsToDisplayTwo.reduce(
                (a, key) => Object.assign(a, { [key]: row[key] }),
                {}
              );
            }) || [];
          var dataSourceOne = new MatTableDataSource(data);

          const Heading = [this.columnsToDisplayTwo];

          /* add the header */
          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
          XLSX.utils.sheet_add_aoa(ws, Heading);

          //Starting in the second row to avoid overriding and skipping headers
          XLSX.utils.sheet_add_json(ws, dataSourceOne.filteredData, {
            origin: "A2",
            skipHeader: true,
          });

          /* generate workbook and add the worksheet */
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

          /* save to file */
          XLSX.writeFile(
            wb,
            `report-mask-usage-for${
              this.campaignType === 1
                ? "bulk"
                : this.campaignType === 2
                ? "personalized"
                : this.campaignType === 3
                ? "targeting"
                : this.campaignType === 4
                ? "package"
                : undefined
            }` +
              new Date().getTime() +
              ".xlsx"
          );
          this.isExportingExcel = false;
        },
        (error) => {
          console.log(error);
          this.isExportingExcel = false;
        }
      );
  }
}

function generateArrayOfYears() {
  var max = new Date().getFullYear();
  var min = 2021;
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push({ name: i.toString(), value: i.toString() });
  }
  return years;
}

function generateArrayOfMonths(currentYear) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var max = 11;
  if (currentYear == new Date().getFullYear()) {
    max = new Date().getMonth();
  } else {
    max = 11;
  }

  var min = 0;
  var months = [];

  for (var i = max; i >= min; i--) {
    months.push({
      name: monthNames[i],
      value: (i + 1).toString().padStart(2, "0"),
    });
  }

  return months;
}
