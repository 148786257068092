import { Component, OnInit, Inject } from "@angular/core";
import { AuthService } from "src/app/auth/auth.service";
import { SuccessComponent } from "src/app/success/success.component";
import {
  FormGroupDirective,
  Validators,
  FormControl,
  FormGroup,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { DialogData } from "src/app/campaign/campaign-create/campaign-create.component";
import { CampaignService } from "src/app/campaign/campaign.service";
import { SuccessDialogBoxComponent } from "src/app/pop-up-messages/success-dialog-box/success-dialog-box.component";

@Component({
  selector: "app-account-info",
  templateUrl: "./account-info.component.html",
  styleUrls: ["./account-info.component.css"],
})
export class AccountInfoComponent implements OnInit {
  account;
  paymentType;
  isLoading = true;
  isLoadingBal = true;
  constructor(private authService: AuthService, private matDialog: MatDialog) {}

  ngOnInit(): void {
    this.paymentType = this.authService.getPaymentType();
    this.authService.getAccountDetails().subscribe(
      (response: any) => {
        this.account = response.userData;
        //console.log(this.account);
        this.isLoading = false;
        this.isLoadingBal = false;
      },
      (error) => {
        //console.log(error);
      }
    );
  }
  openReload() {
    if (this.account != null) {
      const dialogRefOpen = this.matDialog.open(
        UserAccountInfoReloadPopupDialogComponent,
        {
          width: "630px",
          height: "700px",
          data: {
            mobile: this.account.mobile,
          },
        }
      );
      dialogRefOpen.afterClosed().subscribe((result) => {
        this.isLoadingBal = true;
        this.isLoading = true;
        if (result.data == true) {
          this.authService.getAccountDetails().subscribe(
            (response: any) => {
              this.account = response.userData;
              //console.log(this.account);
              this.isLoading = false;
              this.isLoadingBal = false;
            },
            (error) => {
              //console.log(error);
            }
          );
        } else {
          this.isLoading = false;
          this.isLoadingBal = false;
        }
      });
    }
  }
  fetchedUserStatus(status) {
    let status_string = null;
    switch (status) {
      case 0:
        status_string = "Pending(1st Level)";
        break;
      case 1:
        status_string = "Active";
        break;
      case 2:
        status_string = "Suspended";
        break;
      case 3:
        status_string = "Rejected";
        break;
      case 4:
        status_string = "Pending(Final Level";
        break;

      default:
        status_string = "Inactive";
        break;
    }
    return status_string;
  }
}
@Component({
  selector: "user-account-info-reload-popup-dialog",
  templateUrl: "./payment-modal.component.html",
  styleUrls: ["./payment-modal.component.css"],
})
export class UserAccountInfoReloadPopupDialogComponent implements OnInit {
  selectedPaymentOption = null;
  form: FormGroup;
  isLoading = false;
  paymentStatus = false;
  ngOnInit() {
    if (this.data.mobile == null) {
      this.closeDialog();
    }
    this.form = new FormGroup({
      amount: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(11),
          Validators.pattern("^[0-9]*$"),
        ],
      }),
      relaodType: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1),
          Validators.pattern("^[0-9]*$"),
        ],
      }),
    });
  }
  constructor(
    public dialogRef: MatDialogRef<UserAccountInfoReloadPopupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private campaignService: CampaignService,
    private router: Router,
    private matDialog: MatDialog
  ) {
    dialogRef.disableClose = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close({ event: "close", data: this.paymentStatus });
  }
  ezCashOption() {
    this.selectedPaymentOption = 1;
    this.form.patchValue({ relaodType: 1 });
    this.form.get("relaodType").updateValueAndValidity();
  }
  addToBillOption() {
    this.selectedPaymentOption = 2;
    this.form.patchValue({ relaodType: 2 });
    this.form.get("relaodType").updateValueAndValidity();
  }
  initiateReload(formDirective: FormGroupDirective) {
    this.paymentStatus = false;
    if (
      this.form.invalid ||
      (this.selectedPaymentOption != 1 && this.selectedPaymentOption != 2)
    ) {
      return;
    }
    this.isLoading = true;
    //user id is handled in backend
    let default_user_id = 0;
    //0 stands for campaign payment 1 for topup
    let type = 1;
    //campign value is handled in backend
    //topup value
    let default_val = this.form.value.amount;
    this.campaignService
      .initiateTopUp(
        default_user_id,
        this.form.value.relaodType,
        type,
        default_val
      )
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          //console.log(response);
          //reset payment status to true
          this.paymentStatus = true;
          //how capture success response
          this.closeDialog();
          this.matDialog.open(SuccessDialogBoxComponent, {
            width: "532px",
            data: { message: response.comment },
          });
        },
        (error) => {
          this.isLoading = false;
          this.closeDialog();
          //console.log(error);
        }
      );
  }
}
