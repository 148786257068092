<div class="navbar-light bg-light">
  <nav class="container">
    <div class="e-sec-header navbar navbar-expand-lg navbar-light bg-light">
      <ul class="navbar-nav mr-auto e-sec-header-nav">
        <li class="nav-item active">
          <button class="e-sec-header-nav__item"
            [ngClass]="{'e-sec-header-nav__item--active': campaignsActive.isActive }"
            [ngClass]="{'': campaignsSearchActive.isActive }" mat-button
            routerLinkActive="e-sec-header-nav__item--active" routerLink="/account/my-wallet/all">
            All
          </button>
          <a routerLink="/account/my-wallet" routerLinkActive #campaignsActive="routerLinkActive" style="display: none"></a>
        </li>
        <li class="nav-item">
          <button class="e-sec-header-nav__item" mat-button routerLinkActive="e-sec-header-nav__item--active"
            routerLink="/account/my-wallet/search" #campaignsSearchActive="routerLinkActive">
            Search
          </button>
        </li>
      </ul>
    </div>
  </nav>
</div>
