<section class="e-body-wrapper py-5">
  <div class="container e-body-wrapper">
    <div class="row pt-2 pb-2 e-align-right">
      <button mat-flat-button class="e-btn mt-2 mb-3" [routerLink]="[
          '/account/change-password/get-pin',
          this.account?.mobile
        ]" [color]="
          this.account?.accountStatus == 'active' ? 'primary' : 'default'
        " [disabled]="!(this.account?.accountStatus == 'active')">
        Change Password
      </button>
      <div style="padding-left: 10px;"></div>
      <button mat-flat-button class="e-btn mt-2 mb-3" routerLink="/account/edit-account" [color]="
          this.account?.accountStatus == 'active' ? 'primary' : 'default'
        " [disabled]="!(this.account?.accountStatus == 'active')">
        EDIT ACCOUNT
      </button>
    </div>
    <div class="row">
      <div class="col-12 col-md-8">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <div class="e-card" *ngIf="!isLoading">
          <h4 class="mb-4 text-center e-page-title">User details</h4>
          <div class="row mb-2">
            <div class="col-6">
              <div class="e-inp-view">
                <div class="e-inp-view__val">{{ this.account?.fname }}</div>
                <div class="e-inp-view__lbl">First name</div>
              </div>
            </div>
            <div class="col-6">
              <div class="e-inp-view">
                <div class="e-inp-view__val">{{ this.account?.lname }}</div>
                <div class="e-inp-view__lbl">Last name</div>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-6">
              <div class="e-inp-view">
                <div class="e-inp-view__val">{{ this.account?.address }}</div>
                <div class="e-inp-view__lbl">Address</div>
              </div>
            </div>
            <div class="col-6">
              <div class="e-inp-view">
                <div class="e-inp-view__val">{{ this.account?.email }}</div>
                <div class="e-inp-view__lbl">Email</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="e-inp-view">
                <div class="e-inp-view__val">{{ this.account?.mobile }}</div>
                <div class="e-inp-view__lbl">Mobile</div>
              </div>
            </div>
            <div class="col-6">
              <div class="e-inp-view">
                <div class="e-inp-view__val">{{ this.account?.nicOrBrc }}</div>
                <div class="e-inp-view__lbl">NIC / BRC</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="e-inp-view">
                <div class="e-inp-view__val">
                  {{ this.account?.defaultMask }}
                </div>
                <div class="e-inp-view__lbl">Default mask</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mt-4 mt-sm-0">
        <div class="e-card e-card-campaign-right mb-4">
          <div class="e-card-campaign-right__sec-txt">Your account Status</div>
          <!-- <mat-spinner *ngIf="isLoading"></mat-spinner> -->
          <div class="spinner-grow text-primary" role="status" *ngIf="isLoading">
            <span class="sr-only">Loading...</span>
          </div>
          <br />
          <div class="e-card-campaign-right__primary-txt e-card-campaign-right__primary-txt--colored mt-3"
            *ngIf="!isLoading">
            {{ this.account?.accountStatus }}
          </div>
        </div>
        <div class="e-card e-card-campaign-right mb-4">
          <div class="e-card-campaign-right__sec-txt">Payment Type</div>
          <!-- <mat-spinner *ngIf="isLoading"></mat-spinner> -->
          <div class="spinner-grow text-primary" role="status" *ngIf="isLoading">
            <span class="sr-only">Loading...</span>
          </div>
          <br />
          <div class="e-card-campaign-right__primary-txt mt-3" *ngIf="!isLoading">
            <h2 style="color: #5C72FE;"><b>{{
                account?.payment_type==0?'💳Prepaid':account?.payment_type==1?'🧾Invoice':account?.payment_type==2?'📦Packaging':'Invalid'
                }}</b></h2>
          </div>
        </div>
        <div class="e-card e-card-campaign-right mb-4">
          <div class="e-card-campaign-right__sec-txt">
            Your account balance is
          </div>

          <div class="e-card-campaign-right__primary-txt mt-3 mb-3">
            <!-- <mat-spinner *ngIf="isLoading"></mat-spinner> -->
            <div class="spinner-grow text-primary" role="status" *ngIf="isLoadingBal">
              <span class="sr-only">Loading...</span>
            </div>
            <br />
            <ng-container *ngIf="!isLoadingBal">
              <span class="e-card-campaign-right__currency">Rs. &nbsp;</span>
              {{ this.account?.walletBalance | currency:" ":"symbol" }}
            </ng-container>
          </div>
          <button *ngIf="paymentType == '0'" mat-flat-button color="primary" class="e-btn mt-2 mb-1" (click)="openReload()">
            RELOAD
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
