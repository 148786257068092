<section class="e-main-banner">
  <div class="container">
    <div class="row">
      <div class="col-12 e-main-banner__txt">
        <h1 class="e-main-txt mb-3 text-center">Terms & Conditions</h1>
        <P class="e-p-txt">The scope of this Agreement shall be for DIALOG to provide a SMS based solution for the
          Customer
          in order to enable the End Users to receive information sent by the Customer via a Short Code using
          SMS as a carrier for the period stated in this Agreement.</P>
      </div>
    </div>
  </div>
</section>

<section class="e-rl-section">
  <div class="container">
    <div class="row">
      <ol class="col-12 e-sec-list px-5 px-md-0 my-list">
        <li>
          <p class="e-secondary-header-txt mb-4">OBLIGATIONS OF THE CUSTOMER</p>The Customer shall;
          <ol>
            <li>undertake to send messages via the web page assigned to the Customer by DIALOG for the
              purposes of this Agreement;</li>
            <li>be solely responsible for the content and accuracy of the messages sent with the aid of the
              said Service;</li>
            <li>understand that the SMSC cannot forward more than five (5) messages per second to be
              relayed to mobile telephone devices;</li>
            <li>ensure that the End Users provide their consent to receive messages from the Customer
              using the said Services;</li>
            <li>indemnify DIALOG and keep DIALOG indemnified against all direct charges, losses incurred
              or suffered by DIALOG or by any third party acting in reliance of the accuracy, quality,
              completeness value and integrity of the content of any or all SMs sent with the aid of the
              Said Service and and/or all claims, demands, suits, litigation, proceedings, judgments and
              decrees that may arise in consequence of the provision of the Customer Services and/or the
              Said Service; The aggregate liability of the Customer in any given event shall only be limited
              to the aggregate monetary value of each Individual Supplier / Service Contract and/or the
              total Agreement value due to DIALOG from the Customer.</li>
            <li>Indemnify DIALOG and keep DIALOG indemnified against all direct costs, damages, losses
              and expenses incurred or suffered by Dialog due to any breach of this agreement by the
              Customer. The aggregate liability of the Customer in any given event shall only be limited to
              the aggregate monetary value of each Individual Supplier / Service Contract and/or the total
              Agreement value due to DIALOG from the Customer.</li>
            <li>agree not to reproduce, duplicate, copy, sell, resell or exploit for any commercial purposes
              any portion of the Said Service, use of the Said Service or access to the said Service without
              prior written consent of DIALOG;</li>
            <li>the Customer shall ensure that the content part of the message is no longer than 160
              characters;</li>
            <li>bear the additional cost in excess, if the content part of the message along with the contents
              of the optout SMS exceeds 160 characters.</li>
            <li>warrant, represent and undertake throughout the Agreement period that it shall comply with
              all laws and regulations applicable to the provisions of the Said Service;</li>
            <li>not use the SMS based information Solution for another purposes other than the purposes of
              this Agreement;</li>
            <li>ensure that the Said Service is utilized solely for the purposes intended by this Agreement;</li>
            <li>inform DIALOG of any interruption to the normal course of business of the Customer within
              reasonable time of commencement of such interruption;</li>
            <li>not to misuse the mobile numbers of the End Users that the Customer may gain access to
              while providing the Said Services;</li>
            <li>comply with information security clauses, network access clauses, anti-bribery and anti-
              corruption clauses, data protection and privacy clauses at Schedule III hereto and is also
              located at https://www.dialog.lk/legal or at such other location as DIALOG may determine
              from time-to-time.</li>
            <li>properly brand the Customer’s one time password (OTP) to avoid firewall disconnection.</li>
          </ol>
        </li>
        <li>
          <p class="e-secondary-header-txt my-4">OBLIGATIONS OF DIALOG</p>DIALOG shall:
          <ol>
            <li>provide a SMS based information solution for the Customer to send SMS to the End Users;</li>
            <li>provide a web page to the Customer where they would be able to type a 160-character
              message to send it out to the End Users;</li>
            <li>ensure that DIALOG web server will verify the authenticity of the End Users and submit the
              message to the Value Added Services (VAS) network of DIALOG to convert and deliver the
              message to the End Users as an SM;</li>
            <li>inform the Customer of any interruption to DIALOG Services and/or the Said Service within
              the first One hour of commencement of such interruption provided such interruption occurs
              anytime between 8.30 AM and 5.30 PM on weekdays; Dialog agrees to take steps to
              promptly response to the complaints made by the Customer regarding the service and rectify
              any problems/interruptions to the Said Service forthwith.</li>
          </ol>
        </li>
        <li>
          <p class="e-secondary-header-txt my-4">CHARGES /PAYMENTS</p>
          <ol>
            <li>All charges, payments, where applicable shall be per the financial consideration mentioned
              in Schedule II hereto.</li>
            <li>If the content part of the message along with the contents of the optout SMS exceeds 160
              characters, the additional cost in excess shall be borne by the Customer.</li>
            <li>the Customer or DIALOG may impose any charges on the other Party, with prior mutual
              Agreement.</li>
            <li>Where appropriate and legally required, all taxes applicable under this Agreement on
              the consideration hereunder shall be borne or paid by the respective Party charged with the
              tax.</li>
            <li>The charges and payments under this Agreement do not include any Value Added Taxes
              imposed under the Value Added Tax Act, No 14 of 2002, as amended and other applicable
              taxes charged on sales or turnover and usage of mobile telecommunication services (sales
              taxes). Therefore, any VAT or sales taxes may be charged and recovered or collected, in
              respect of the said Services and also by either Party raising the invoice on the Other Party
              under this Agreement in addition to the charges for the services / products supplied. Any
              VAT so charged shall be paid by the other Party provided however that Party charging VAT is
              registered for VAT and a valid tax invoice as per section 20 of the Value Added Tax Act, No
              14 of 2002 is submitted.</li>
            <li>Where any payment made under this Agreement is subject to withholding tax (“WHT”), such
              Party shall make the necessary payments under this Agreement after deducting WHT
              thereon, and furnish necessary certificates to the respective Party as proof of deduction,
              subject to submission of a direction by the party receiving payment to the other.</li>
            <li>In event of changes to prevailing taxes and or any new / additional taxes become applicable
              during the term of this Agreement, the Parties shall follow the amending or new legislation or
              by-law unless otherwise agreed by a written amendment to this Agreement within one (1)
              month after the date of the legislation.</li>
          </ol>
        </li>
        <li>
          <p class="e-secondary-header-txt my-4">TERMINATION</p>
          <ol>
            <li>Either Party shall, by giving fourteen (14) Days’ notice in writing to the other, without
              prejudice to any other rights herein referred, terminate this Agreement at the occurrence of
              any of the following circumstances.</li>
            <ol>
              <li>If the other commits a material breach of any of the Terms and Conditions of this
                Agreement and such breach is not remedied within thirty (30) Days from notification
                thereof to the Party in breach;</li>
              <li>If the other Party becomes insolvent goes into voluntary or compulsory liquidation or
                pass an effective resolution for winding up or make an arrangement or composition with
                its creditors, or if any receiver be appointed on behalf of debenture holders or
                otherwise;</li>
            </ol>
            <li>Without prejudice to any other rights herein stipulated either Party may terminate this
              Agreement at any time by giving thirty (630Days written notice without giving any reason
              whatsoever.</li>
            <li>DIALOG reserves the right to terminate this Agreement at any time if the provision of the said
              Service/s seeks to create mischief, has the tendency to mislead the public and /or
              contravenes any provisions of prevalent rules and regulations of Sri Lanka.</li>
          </ol>
        </li>
      </ol>
      <ol class="col-12 e-sec-list px-5 px-md-0">
        <h3 class="e-secondary-header-txt my-4" style="text-decoration: underline;">General Terms &amp; Conditions</h3>
        <li>SMS will be charged upon submission.</li>
        <li>All applicable taxes will be included at the time of invoicing.</li>
        <li>All payments to be settled within Thirty (30) Days of invoicing.</li>
        <li>Customer shall be entitled to vary the total monthly usage of SMS for each invoice raised up to
          Two Percent (2%) subject to the prior approval of DIALOG</li>
        <li>DIALOG shall send electronic invoices (e – invoices) to the Customer in order to make payments
          under this Agreement</li>
        <li>Customer shall inform DIALOG in writing if an invoice raised by DIALOG has not been received
          by the Customer within Thirty (30) Days, upon issuance of an invoice</li>
        <li>In the event at any time any particular invoice is not settled within the said period stipulated in this
          Agreement, DIALOG shall be entitled to charge interest at the rate of Two per cent (2%) of the
          outstanding amount due and suspend provision of the Services until such invoice is settled. In the
          event of suspension of provision of Services and continued non-settlement of the invoice for a
          further Twenty (20) Days, DIALOG shall be entitled to terminate this Agreement with immediate
          effect and block dispatching of SMSs through other operator solutions</li>
        <li>If there is a dispute pertaining to an invoice raised by DIALOG, Customer shall inform DIALOG in
          writing within Fifteen (15) Days of receipt of an invoice</li>
        <li>DIALOG reserves the right to take credit action against other services provided to the CUSTOMER
          by DIALOG or any of its affiliates or subsidiaries.</li>
        <li>If the Customer sends International Application to Person (A2P) SMSs through the Customer’s
          local paths, those SMSs shall be charged USD 0.15, per SMS.</li>
      </ol>
    </div>
  </div>
</section>
