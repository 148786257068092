<mat-spinner *ngIf="isLoading"></mat-spinner>
<form
  [formGroup]="form"
  (submit)="initiateReload(formDirective)"
  #formDirective="ngForm"
  *ngIf="!isLoading"
>
  <div class="container-fluid e-modal">
    <button
      mat-mini-fab
      color="primary"
      class="e-btn-modal-close"
      (click)="closeDialog()"
      type="button"
    >
      <mat-icon class="e-btn-modal-close__icon">close</mat-icon>
    </button>
    <div class="row mt-3">
      <div class="col-12 e-modal-row-1 mt-4">
        <h2 class="e-modal-title">Reload</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center mt-2 mb-4">
        <h4 class="e-page-title e-page-title--emp-less">
          Connection number :
          <span class="e-txt-weighted">0{{ this.data.mobile }}</span>
        </h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center mt-3 mb-1">
        <mat-form-field appearance="outline" class="e-input e-input-payment-m">
          <mat-label>Amount (Rs.)</mat-label>
          <input
            matInput
            formControlName="amount"
            maxlength="11"
            minlength="1"
          />
          <mat-error *ngIf="form.get('amount').hasError('required')"
            >Topup value can't be null</mat-error
          >
          <mat-error
            *ngIf="
              form.get('amount').hasError('minlength') ||
              form.get('amount').hasError('maxlength')
            "
            >Invalid Reload Value</mat-error
          >
          <mat-error *ngIf="form.get('amount').hasError('pattern')"
            >Topup value can only have digits</mat-error
          >
          <mat-error>Username cannot be empty</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center mt-2">
        <p class="e-large-txt">Payment option</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center mb-1">
        <h4 class="e-page-title e-page-title--emp-less">
          Select the option you like to pay with
        </h4>
      </div>
    </div>
    <div class="row mt-4 mb-5 e-modal-footer-btns">
      <button
        mat-stroked-button
        class="e-btn-payment"
        (click)="addToBillOption()"
        [class.selected-payment]="this.selectedPaymentOption === 2"
        type="button"
      >
        <span class="material-icons e-btn-payment__icon mb-3"
          >receipt_long</span
        >
        <span class="e-btn-payment__txt">Add to bill</span>
      </button>
      <!-- <button
        mat-stroked-button
        class="e-btn-payment"
        (click)="ezCashOption()"
        [class.selected-payment]="this.selectedPaymentOption === 1"
        type="button"
      >
        <img
          class="e-ez-image mb-3"
          src="../../../assets/images/1200x630wa.png"
        />
        <span class="e-btn-payment__txt">eZ Cash</span>
      </button> -->
    </div>
    <!-- <div class="row mt-4 mb-5 e-modal-footer-btns">
      <p *ngIf="this.selectedPaymentOption === 1">
        Please note that You will be charged 1.5 % as the eZcash service fee per
        reload
      </p>
    </div> -->
  </div>
  <div
    class="e-modal-footer mb-1"
    mat-dialog-actions
    style="color: red"
    *ngIf="this.selectedPaymentOption == null"
  >
    Please select a payment option
  </div>
  <div class="e-modal-footer mb-1" mat-dialog-actions>
    <button
      mat-flat-button
      color="primary"
      class="e-btn e-btn--small"
      type="submit"
      [disabled]="
        this.form.value.amount == null || this.form.value.relaodType == null
      "
    >
      RELOAD
    </button>
  </div>
</form>
