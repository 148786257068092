<div class="navbar-light bg-light">
  <nav class="container">
    <div class="e-sec-header navbar navbar-expand-lg navbar-light bg-light">
      <ul class="navbar-nav mr-auto e-sec-header-nav">
        <li class="nav-item active">
          <button class="e-sec-header-nav__item"
            [ngClass]="{'e-sec-header-nav__item--active': campaignsActive.isActive }"
            [ngClass]="{'': campaignsSearchActive.isActive }" mat-button
            routerLinkActive="e-sec-header-nav__item--active" routerLink="/contact/list/all">
            Contact List
          </button>
          <a routerLink="/contact/list" routerLinkActive #campaignsActive="routerLinkActive" style="display: none"></a>
        </li>
        <li class="nav-item">
          <button class="e-sec-header-nav__item" mat-button routerLinkActive="e-sec-header-nav__item--active"
            routerLink="/contact/list/search" #campaignsSearchActive="routerLinkActive">
            Search
          </button>
        </li>
      </ul>
      <div class="row e-align-right">
        <button routerLink="/contact/create-list" mat-flat-button color="primary" class="e-btn float-right">
          Add New Contact List
        </button>
      </div>
    </div>
  </nav>
</div>
