<div class="container mt-3">
  <div class="row alert-banner-row">
    <div class="col-sm-2 icon-column">
      <span class="material-icons e-status-syb__stop-icon">info</span>
    </div>
    <div class="col-sm-8 body-column">
      <h4><b>Please Clear Your Browser Cache</b></h4>
      <p>To ensure you are seeing the latest updates and experiencing optimal performance, we recommend clearing your
        browser cache.
      </p>
      <h6>
        <b>Thank you for your cooperation!</b>
      </h6>
    </div>
    <div class="col-sm-2 button-column">
      <button color="primary" mat-mini-fab aria-label="Example icon button with a menu icon" (click)="closeBtn()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>
