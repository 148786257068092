import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { SuccessComponent } from "src/app/success/success.component";
import {
  FormGroupDirective,
  FormControl,
  Validators,
  FormGroup,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../auth.service";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-two-step-verify-enter-pin",
  templateUrl: "./two-step-verify-enter-pin.component.html",
  styleUrls: ["./two-step-verify-enter-pin.component.css"],
})
export class TwoStepVerifyEnterPinComponent implements OnInit {
  form: FormGroup;
  isSubmitted = false;
  mobile = null;
  dialogRef;
  resendActivated = true;
  //pos11 is used insted of pos1 as controler names is alos pos1
  //to remove the consfusing
  @ViewChild("pos11") pos1Field: ElementRef;
  @ViewChild("pos22") pos2Field: ElementRef;
  @ViewChild("pos33") pos3Field: ElementRef;
  @ViewChild("pos44") pos4Field: ElementRef;
  @ViewChild("pos55") pos5Field: ElementRef;
  @ViewChild("pos66") pos6Field: ElementRef;
  placeholder1 = "_";
  placeholder2 = "_";
  placeholder3 = "_";
  placeholder4 = "_";
  placeholder5 = "_";
  placeholder6 = "_";
  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) {}
  counterTime = 0;
  ngOnInit(): void {
    this.mobile = this.route.snapshot.params["mobile"];
    this.form = new FormGroup({
      pos1: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1),
          Validators.pattern("^[0-9]*$"),
        ],
      }),
      pos2: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1),
          Validators.pattern("^[0-9]*$"),
        ],
      }),
      pos3: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1),
          Validators.pattern("^[0-9]*$"),
        ],
      }),
      pos4: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1),
          Validators.pattern("^[0-9]*$"),
        ],
      }),
      pos5: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1),
          Validators.pattern("^[0-9]*$"),
        ],
      }),
      pos6: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1),
          Validators.pattern("^[0-9]*$"),
        ],
      }),
    });
    const now = new Date();
    if (
      !localStorage.getItem("twoStepAuth") ||
      !localStorage.getItem("twoStepAuthExpiration")
    ) {
      this.router.navigate(["/login"]);
    }
    const expireTime = localStorage.getItem("twoStepAuthExpiration");
    const expiresIn = new Date(expireTime).getTime() - now.getTime();

    this.counterTime = expiresIn;
    if (expiresIn < 0) {
      this.router.navigate(["/login"]);
    }
  }
  //move focus
  pos1(event) {
    if (event.key.length == 1) this.form.get("pos1").setValue(event.key);
    this.pos2Field.nativeElement.focus();
  }
  pos1BackSpace(event) {
    this.form.get("pos1").setValue(null);
    event.preventDefault();
    this.pos6Field.nativeElement.focus();
  }
  pos2(event) {
    if (event.key.length == 1) this.form.get("pos2").setValue(event.key);
    this.pos3Field.nativeElement.focus();
  }
  pos2BackSpace(event) {
    this.form.get("pos2").setValue(null);
    event.preventDefault();
    this.pos1Field.nativeElement.focus();
  }
  pos3(event) {
    if (event.key.length == 1) this.form.get("pos3").setValue(event.key);
    this.pos4Field.nativeElement.focus();
  }
  pos3BackSpace(event) {
    this.form.get("pos3").setValue(null);
    event.preventDefault();
    this.pos2Field.nativeElement.focus();
  }
  pos4(event) {
    if (event.key.length == 1) this.form.get("pos4").setValue(event.key);
    this.pos5Field.nativeElement.focus();
  }
  pos4BackSpace(event) {
    this.form.get("pos4").setValue(null);
    event.preventDefault();
    this.pos3Field.nativeElement.focus();
  }
  pos5(event) {
    if (event.key.length == 1) this.form.get("pos5").setValue(event.key);
    this.pos6Field.nativeElement.focus();
  }
  pos5BackSpace(event) {
    this.form.get("pos5").setValue(null);
    event.preventDefault();
    this.pos4Field.nativeElement.focus();
  }
  pos6(event) {
    if (event.key.length == 1) this.form.get("pos6").setValue(event.key);
    this.pos1Field.nativeElement.focus();
  }
  pos6BackSpace(event) {
    this.form.get("pos6").setValue(null);
    event.preventDefault();
    this.pos5Field.nativeElement.focus();
  }

  onVerifyPin(formDirective: FormGroupDirective) {
    this.isSubmitted = false;
    if (this.form.invalid) {
      return;
    }
    const entered_pin =
      this.form.get("pos1").value +
      this.form.get("pos2").value +
      this.form.get("pos3").value +
      this.form.get("pos4").value +
      this.form.get("pos5").value +
      this.form.get("pos6").value;
    this.authService.twoStepVerifyPinAndGetTokens(entered_pin, this.form);
  }
  resendPin() {
    this.authService.getTwoStepVerifyResendPin().subscribe(
      (response) => {
        //show message
        this.resendActivated = false;
        setInterval(() => {
          this.resendActivated = true;
        }, 30000);
        this.dialogRef = this.matDialog.open(SuccessComponent, {
          width: "542px",
          data: { message: "Pin is resent to your mobile" },
        });
      },
      (error) => {
        console.log(error);
        // this.router.navigate(["/login"]);
      }
    );
  }
  checkPlaceHolder(event: any) {
    //console.log(event.target.id);
    if (event.target.id == "mat-input-0") {
      if (this.placeholder1) {
        this.placeholder1 = null;
      } else {
        this.placeholder1 = "_";
      }
    } else if (event.target.id == "mat-input-1") {
      if (this.placeholder2) {
        this.placeholder2 = null;
      } else {
        this.placeholder2 = "_";
      }
    } else if (event.target.id == "mat-input-2") {
      if (this.placeholder3) {
        this.placeholder3 = null;
      } else {
        this.placeholder3 = "_";
      }
    } else if (event.target.id == "mat-input-3") {
      if (this.placeholder4) {
        this.placeholder4 = null;
      } else {
        this.placeholder4 = "_";
      }
    } else if (event.target.id == "mat-input-4") {
      if (this.placeholder5) {
        this.placeholder5 = null;
      } else {
        this.placeholder5 = "_";
      }
    } else if (event.target.id == "mat-input-5") {
      if (this.placeholder6) {
        this.placeholder6 = null;
      } else {
        this.placeholder6 = "_";
      }
    }
  }
  onNotify(event: any) {
    if (event.action == "done") {
      this.router.navigate(["/login"]);
    }
  }
}
