<div class="container-fluid e-filter-section e-filter-section--paddings-x2">
  <div class="row">
    <div class="col-12 col-md-10">
      <h4 class="pt-4 pb-2 e-page-title">Filter</h4>
    </div>
  </div>
  <form [formGroup]="form" (submit)="applySearch(formDirective)" #formDirective="ngForm">
    <div style="padding: 10px"></div>
    <div class="row">
      <div class="col-12 col-md-3">
        <mat-form-field appearance="outline">
          <mat-label>Select a short code</mat-label>
          <mat-select formControlName="shortCode">
            <mat-option *ngFor="let shortCode of shortCodeList" [value]="shortCode.short_code_or_long_number">
              {{shortCode.short_code_or_long_number}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('shortCode').hasError('required')">Short Code can't be empty
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-2">
        <mat-form-field appearance="outline">
          <mat-label>From Date</mat-label>
          <input matInput [matDatepicker]="fromPicker" name="fromDate" formControlName="fromDate"
            [matDatepickerFilter]="dateFilterFrom" readonly>
          <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
          <mat-datepicker #fromPicker></mat-datepicker>
          <mat-error *ngIf="form.get('fromDate').hasError('required')">From Date can't be empty
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-2">
        <mat-form-field appearance="outline">
          <mat-label>To Date</mat-label>
          <input matInput [matDatepicker]="toPicker" name="toDate" formControlName="toDate"
            [matDatepickerFilter]="dateFilterTo" readonly>
          <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
          <mat-datepicker #toPicker></mat-datepicker>
          <mat-error *ngIf="form.get('toDate').hasError('required')">From Date can't be empty
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-3">
        <div class="row">
          <div class="col-4 pt-2">
            <button mat-flat-button color="primary" class="e-btn ml-2">
              VIEW
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <hr />
</div>

<section class="pt-5 e-body-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-12 e-card e-card--no-paddings">
        <div *ngIf="isLoading" class="col-12 d-flex justify-content-center align-items-center"
          style="min-height: 50vh;">
          <mat-spinner></mat-spinner>
        </div>
        <section>
        <div class="e-card--table">
          <table mat-table matSort [dataSource]="dataSourceOne" #TableOneSort="matSort">
            <!-- ID Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>ID.</th>
              <td mat-cell *matCellDef="let element">
                {{ element.id }}
              </td>
            </ng-container>
            <!-- TIME Column -->
            <ng-container matColumnDef="timestamp">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Time.</th>
              <td mat-cell *matCellDef="let element">
                {{ element.timestamp | date: "yyyy-MM-dd HH:mm":"+0000" }}
              </td>
            </ng-container>
            <!-- SENDER Column -->
            <ng-container matColumnDef="sender">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Sender.
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.sender }}
              </td>
            </ng-container>
            <!-- RECEIVER Column -->
            <ng-container matColumnDef="receiver">
              <th mat-header-cell *matHeaderCellDef>Receiver</th>
              <td mat-cell *matCellDef="let element">
                {{ element.receiver}}
              </td>
            </ng-container>
            <!-- MESSAGE Column -->
            <ng-container matColumnDef="message">
              <th mat-header-cell *matHeaderCellDef>Message</th>
              <td mat-cell *matCellDef="let element"
                (click)="readMoreDialog(element.receiver, element.id, element.message, element.status)" style="cursor: pointer;">
                <div [innerHTML]="formatContent(element.message)"></div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsOne"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsOne"
              [ngStyle]="{'background-color':row.status === 0 ? 'rgba(100, 148, 237, 0.2);' : 'none' }"></tr>
          </table>
        </div>
        <mat-paginator *ngIf="!isRefresh" #TableOnePaginator="matPaginator" [length]="this.totalPosts" [showFirstLastButtons]="false"
            [pageSizeOptions]="pageSizeOption" [pageSize]="postsPerPage" (page)="onChangedPage($event)"></mat-paginator>
      </section>
      </div>
      <div *ngIf="dataSourceAll?.length>0 && !isLoading" class="mt-5 mb-5 col-lg-12 pull-left">
        <button mat-flat-button color="primary" class="e-btn" (click)="downloadExcel()" [disabled]="this.totalPosts<0 || !this.loadedData"><span><i
              class="fa fa-download pr-3"></i><b>Export as Excel</b></span>&nbsp;<i class="fa fa-spinner fa-spin" *ngIf="!this.loadedData"></i></button>
      </div>
    </div>
  </div>
</section>
