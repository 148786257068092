<section class="e-body-wrapper pb-5">
  <div class="container e-body-wrapper">
    <div class="row pt-4 pb-2">
      <button mat-button class="e-btn e-back-btn" routerLink="/template/list">
        <span class="material-icons e-back-btn__icon"> arrow_back </span>BACK
      </button>
    </div>
    <div class="row">
      <div class="offset-md-1 col-md-10 e-card">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <form
          *ngIf="!isLoading"
          (submit)="createCampaign()"
          [formGroup]="form"
          #campaignFormDirective="ngForm"
        >
          <h4 class="mb-4 e-page-title text-center">Add New Template List</h4>
          <div class="row">
            <div class="col-6">
              <mat-form-field appearance="outline" class="e-input">
                <mat-label>List name</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="templateListName"
                  maxlength="44"
                />
                <mat-error
                  *ngIf="form.get('templateListName').hasError('required')"
                  >List name can't be null</mat-error
                >
                <mat-error
                  *ngIf="form.get('templateListName').hasError('minlength')"
                  >List name contain atleast 3 characters</mat-error
                >
                <mat-error
                  *ngIf="form.get('templateListName').hasError('pattern')"
                  >Illegal characters in the List name</mat-error
                >
              </mat-form-field>
            </div>

            <div class="col-6"></div>
          </div>
          <div class="row">
            <div class="col-12">
              <mat-form-field appearance="outline" class="e-input">
                <mat-label>Message</mat-label>
                <textarea
                  matInput
                  formControlName="message"
                  (input)="messageCounterUpdate()"
                >
                </textarea>
                <mat-hint
                  >{{ messageRemLength }} / {{ numberOfMessages }}</mat-hint
                >
                <mat-error *ngIf="form.get('message').hasError('required')"
                  >Message body can't be null</mat-error
                >
                <mat-error *ngIf="form.get('message').hasError('minlength')"
                  >Message body must contain atleast 3 characters</mat-error
                >
                <mat-error *ngIf="form.get('message').hasError('pattern')"
                  >Illegal characters in the message body</mat-error
                >
              </mat-form-field>
            </div>
          </div>

          <div class="row e-btn-group e-btn-group--center mt-2">
            <button
              mat-stroked-button
              class="e-btn-stroke mr-2"
              type="button"
              [routerLink]="['/template/list']"
            >
              CANCEL
            </button>
            <button
              mat-flat-button
              color="primary"
              class="e-btn ml-2"
              type="submit"
            >
              CREATE
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
