<div class="row mt-4">
  <div class="col-12 col-md-8">
    <div class="e-card e-card--no-paddings mb-4 pb-2">
      <h4 class="pt-4 mb-3 text-center e-page-title">
        Your transaction history
      </h4>
      <div *ngIf="isLoading" class="col-12 d-flex justify-content-center align-items-center">
        <mat-spinner></mat-spinner>
      </div>
      <div class="e-card--table">
        <table mat-table mat-table matSort [dataSource]="dataSource" #TableOneSort="matSort">
          <!-- Position Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;">
              No.
            </th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="transaction_init_timestamp">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%;">
              Date
            </th>
            <td mat-cell *matCellDef="let element">
              {{
              element.transaction_init_timestamp
              | date: "yyyy-MM-dd HH:mm":"+0530"
              }}
            </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="payment_method">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%;">
              Payment Method
            </th>
            <td mat-cell *matCellDef="let element">
              {{ paymentMethod(+element.payment_method) }}
            </td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="transaction_type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%;">
              Transaction Type
            </th>
            <td mat-cell *matCellDef="let element">
              {{ TransactionType(+element.transaction_type) }}
            </td>
          </ng-container>
          <!-- Symbol Column -->
          <ng-container matColumnDef="transaction_status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%;" class="e-txt-success">
              Status
            </th>
            <td mat-cell *matCellDef="let element">
              {{ TransactionStatus(+element.transaction_status) }}
            </td>
          </ng-container>
          <!-- Symbol Column -->
          <ng-container matColumnDef="charged_total">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Total (LKR)
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.charged_total }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <mat-paginator #TableOnePaginator="matPaginator" [length]="this.totalPosts" [showFirstLastButtons]="false"
        [pageSizeOptions]="pageSizeOption" [pageSize]="postsPerPage" (page)="onChangedPage($event)">
      </mat-paginator>
    </div>
  </div>
  <div class="col-12 col-md-4 mt-4 mt-sm-0">
    <div class="e-card e-card-campaign-right mb-4">
      <div class="e-card-campaign-right__sec-txt">
        Your account balance is
      </div>
      <div class="spinner-grow text-primary" role="status" *ngIf="isLoading_bal">
        <span class="sr-only">Loading...</span>
      </div>
      <br />
      <!-- <mat-spinner *ngIf="isLoading_bal"></mat-spinner> -->
      <div class="e-card-campaign-right__primary-txt mt-3 mb-3" *ngIf="!isLoading_bal">
        <span class="e-card-campaign-right__currency">Rs. &nbsp;</span>
        {{ this.account?.walletBalance | currency:" ":"symbol" }}
      </div>
      <button *ngIf="paymentType == '0'" mat-flat-button color="primary" class="e-btn mt-2 mb-1" (click)="openReload()">
        RELOAD
      </button>
    </div>
  </div>
</div>
