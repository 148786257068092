import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CampaignService } from 'src/app/campaign/campaign.service';
import { ConfirmDialogBoxComponent } from 'src/app/pop-up-messages/confirm-dialog-box/confirm-dialog-box.component';
import { SuccessDialogBoxComponent } from 'src/app/pop-up-messages/success-dialog-box/success-dialog-box.component';

@Component({
  selector: 'app-contact-list-search',
  templateUrl: './contact-list-search.component.html',
  styleUrls: ['./contact-list-search.component.css']
})
export class ContactListSearchComponent implements OnInit {
  form;
  isLoading = false;
  isRefresh = false;

  totalPosts = 0;
  postsPerPage = 5;
  pageSizeOption = [5, 10, 25, 100];
  currentPage = 1;

  dataSourceAll;

  dataSourceOne;
  displayedColumnsOne: string[] = ["name", "created", "creator_id"];
  @ViewChild("TableOnePaginator", { static: false })
  tableOnePaginator: MatPaginator;
  @ViewChild("TableOneSort") tableOneSort: MatSort;
  @ViewChild(MatSort) set TableOneSort(tableOneSort: MatSort) {
    if (this.dataSourceOne) {
      this.dataSourceOne.sort = tableOneSort;
    }
  }

  constructor(
    private textCapaignService: CampaignService,
    private matDialog: MatDialog,
  ) {}

  ngOnInit() {
    this.form = new FormGroup({
      searchParam: new FormControl(null, {
        validators: [Validators.required],
      }),
    });
  }

  fetchData(currentPage, postsPerPage, searchParam) {
    this.isLoading = true;
    this.dataSourceOne = new MatTableDataSource(null);
    //get campaign list

    this.textCapaignService
      .contactListGlobalSearch(
        currentPage,
        postsPerPage.toString(),
        searchParam
      )
      .subscribe(
        (response: any) => {
          ////////console.log(response);
          // this.totalPosts = response.count;
          ////////console.log(this.totalPosts);
          this.dataSourceAll = response?.data||[];
          this.dataSourceOne = new MatTableDataSource(this.dataSourceAll);
          this.dataSourceOne.sort = this.tableOneSort;
          if (this.dataSourceAll.length < this.postsPerPage) {
            this.totalPosts = this.currentPage * this.postsPerPage;
          } else {
            this.totalPosts = this.currentPage * this.postsPerPage + 1;
          }
          // this.cdr.detectChanges();
          this.isLoading = false;
          this.isRefresh = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.isRefresh = false;
        }
      );
  }

  applySearch(formDirective: FormGroupDirective) {
    if (this.form.invalid) {
      return;
    }

    this.isRefresh = true;

    this.postsPerPage = 5;
    this.pageSizeOption = [5, 10, 25, 100];
    this.currentPage = 1;

    this.fetchData(
      this.currentPage,
      this.postsPerPage,
      this.form.get("searchParam").value
    );
  }

  onChangedPage(pageData: PageEvent) {
    //////console.log(pageData);
    this.currentPage = pageData.pageIndex + 1;
    this.postsPerPage = pageData.pageSize;
    this.fetchData(
      this.currentPage,
      this.postsPerPage,
      this.form.get("searchParam").value
    );
  }

  removeContactList(id) {
    var title;
    var message;

    title = "Delete Contact List";
    message =
      "Are you sure, you want to remove the contact list id: " + id + "?";
    const confirmDialog = this.matDialog.open(ConfirmDialogBoxComponent, {
      data: {
        title: title,
        message: message,
      },
    });
    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.textCapaignService.deleteContactList(id).subscribe(
          (response: any) => {
            // console.log(response);
            this.matDialog.open(SuccessDialogBoxComponent, {
              width: "532px",
              data: {
                message: response.comment,
              },
            });
            this.fetchData(this.currentPage, this.postsPerPage, this.form.get("searchParam").value);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }

}
