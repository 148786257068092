<div class="container-fluid e-modal e-ntf-modal">
  <button
    mat-mini-fab
    color="primary"
    class="e-btn-modal-close e-btn-modal-close--adj"
  >
    <mat-icon class="e-btn-modal-close__icon" (click)="closeDialog()"
      >close</mat-icon
    >
  </button>
  <div class="row">
    <div class="col-12 mt-5 mb-1">
      <h4 class="e-page-title">
        {{ data.subject }}
      </h4>
    </div>
  </div>
  <div class="row e-ntf-modal__sub-header-wrapper pb-2">
    <div class="col-12">
      <h4 class="e-ntf-modal__sub-header">
        <ng-container *ngIf="data.msg_type == '1'">
          eSMS - Admin -
        </ng-container>
        <ng-container *ngIf="data.msg_type == '0'">
          User -
        </ng-container>

        <span class="e-ntf-modal__time">{{
          data.created_time | date: "yyyy-MM-dd HH:mm":"+0530"
        }}</span>
      </h4>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <p class="e-ntf-modal__body">
        {{ this.data.message }}
      </p>
    </div>
  </div>
</div>
<div class="e-modal-footer mb-1" mat-dialog-actions>
  <button
    mat-flat-button
    color="primary"
    class="e-btn e-btn--small"
    (click)="closeDialog()"
  >
    OK
  </button>
</div>
