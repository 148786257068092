<mat-card>
  <mat-card-content>
    <h1>Please Enter the Pin</h1>
    <form>
      <mat-form-field appearance="fill">
        <mat-label>6 Digit Pin</mat-label>
        <input
          type="password"
          #input
          maxlength="6"
          name="pin"
          ngModel
          matInput
          required
        />
        <mat-icon matSuffix>developer_mode</mat-icon>
        <mat-hint align="end">{{ input.value?.length || 0 }}/6</mat-hint>
        <mat-error>Please Enter Valid Password</mat-error>
      </mat-form-field>
      <button mat-raised-button color="primary" type="submit">Next</button>
    </form>
  </mat-card-content>
</mat-card>
