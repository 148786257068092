import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormGroupDirective,
} from "@angular/forms";
import { passValidator } from "src/app/auth/signup/confirm-password.validator";
import { MatDialog } from "@angular/material/dialog";
import { SuccessComponent } from "src/app/success/success.component";
import { SuccessDialogBoxComponent } from "src/app/pop-up-messages/success-dialog-box/success-dialog-box.component";

@Component({
  selector: "app-change-password-add-new-password",
  templateUrl: "./change-password-add-new-password.component.html",
  styleUrls: ["./change-password-add-new-password.component.css"],
})
export class ChangePasswordAddNewPasswordComponent implements OnInit {
  form: FormGroup;
  counterTime = 0;
  show;
  confirmShow;
  constructor(
    private router: Router,
    public authService: AuthService,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.show = false;
    this.confirmShow = false;
    const now = new Date();
    if (
      !localStorage.getItem("addVerifyNewPasswordtoken") ||
      !localStorage.getItem("addNewVerifyPasswordtokenExpirationDate")
    ) {
      this.router.navigate(["/user/dashboard"]);
    }
    const expireTime = localStorage.getItem(
      "addNewVerifyPasswordtokenExpirationDate"
    );
    const expiresIn = new Date(expireTime).getTime() - now.getTime();

    this.counterTime = expiresIn / 1000;
    if (expiresIn < 0) {
      this.router.navigate(["/user/dashboard"]);
    }
    this.form = new FormGroup({
      password: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.pattern(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,50}$/
          ),
        ],
      }),
      //^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$

      cpassword: new FormControl(null, {
        validators: [Validators.required, passValidator],
      }),
    });
  }
  resetPassword() {
    this.form.get("cpassword").reset();
  }
  onAddNewPassword(formDirective: FormGroupDirective) {
    if (
      this.form.invalid ||
      this.form.get("password").value != this.form.get("cpassword").value
    ) {
      return;
    }
    this.authService
      .updateNewPasswordWhileLogged(this.form.get("password").value)
      .subscribe(
        (response: any) => {
          //how capture success response
          const dialogRef = this.matDialog.open(SuccessDialogBoxComponent, {
            width: "542px",
            data: { message: response.comment },
          });
          this.router.navigate(["/user/dashboard"]);
        },
        (error) => {
          //console.log(error);
          this.router.navigate(["/user/dashboard"]);
        }
      );
  }
  passwordShow() {
    this.show = !this.show;
  }
  confirmPasswordShow() {
    this.confirmShow = !this.confirmShow;
  }
}
