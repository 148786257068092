import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-change-password-get-pin",
  templateUrl: "./change-password-get-pin.component.html",
  styleUrls: ["./change-password-get-pin.component.css"],
})
export class ChangePasswordGetPinComponent implements OnInit {
  mobile;
  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.mobile = this.route.snapshot.params["mobile"];
    this.route.params.subscribe((params: Params) => {
      this.mobile = this.route.snapshot.params["mobile"];
    });
  }
  onGetPin() {
    this.authService.getChangePasswordWhileLoggedPin().subscribe(
      (response: any) => {
        //console.log(response);
        const addNewPasswordtoken = response.addNewPasswordtoken;
        const addNewPasswordtokenExpiration =
          response.addNewPasswordtokenExpiration;
        const now = new Date();
        const addNewPasswordtokenExpirationDate = new Date(
          now.getTime() + addNewPasswordtokenExpiration * 1000
        );
        this.authService.saveAddNewPasswordWhileLoggedAuthData(
          addNewPasswordtoken,
          addNewPasswordtokenExpirationDate.toISOString()
        );
        this.router.navigate([
          "/account/change-password/verify-pin",
          this.mobile,
        ]);
      },
      (error) => {
        //console.log(error);
        this.router.navigate(["/user/dashboard", this.mobile]);
      }
    );
  }
}
