<div class="container-fluid e-modal">
  <button mat-mini-fab color="primary" class="e-btn-modal-close">
    <mat-icon class="e-btn-modal-close__icon" (click)="closeDialog()"
      >close</mat-icon
    >
  </button>
  <div class="row mt-3">
    <div class="col-12 e-modal-row-1">
      <span class="material-icons e-status-syb__stop-icon">info</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center mt-5 mb-3">
      <h4 class="e-page-title">
        Are sure you want to stop the campaign
        {{ this.data?.campaignName }} (ID: {{ this.data?.campaignId }})
      </h4>
    </div>
  </div>
</div>
<div class="e-modal-footer mb-1" mat-dialog-actions>
  <button
    mat-stroked-button
    class="e-btn-stroke e-btn-stroke--colored-txt mr-2"
    (click)="closeDialog()"
  >
    NO
  </button>
  <button
    mat-flat-button
    color="primary"
    class="e-btn e-btn--small"
    (click)="stopCampaign()"
    [disabled]="disableYes"
  >
    YES
  </button>
</div>
