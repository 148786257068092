<div class="container-fluid e-modal">
  <button mat-mini-fab color="primary" class="e-btn-modal-close">
    <mat-icon class="e-btn-modal-close__icon" (click)="closeDialog()"
      >close</mat-icon
    >
  </button>
  <div class="row mt-3">
    <div class="col-12">
      <div class="terms-heading">Transactional Account</div>
      <div class="terms-body">
        Messages sent to the customers to pass on information necessary for
        using a product or service. These messages are delivered to DND (Do Not
        Disturb) numbers as well.
      </div>
      <div class="terms-body">
        Ex: • A message sent to the client by a company regarding his Invoice
        amount
      </div>
      <div class="terms-heading">Promotional Account</div>
      <div class="terms-body">
        Messages sent with the objective of promoting product or service.
        Includes any sales & marketing messages which may or may not be
        solicited by the recipient. SMSes that have offers, coupons etc.
      </div>
      <div class="terms-body">
        Ex: • Download & register the ABC Mobile Banking app. Stand a chance to
        WIN A SAMSUNG GALAXY NOTE 8 phone. Download now on www.ABC T&C Apply •
        It’s a HOT DEAL! Buy 1 Get 1 FREE at XYZ with your ABC Bank Debit Card
        for an 8pc Chicken Bucket or a Zinger Burger Meal. Offer valid Today —
        07th Dec 2020.For queries call
      </div>
      <div class="e-modal-footer mb-1" mat-dialog-actions>
        <button
          mat-flat-button
          color="primary"
          class="e-btn e-btn--small"
          type="submit"
          (click)="onStatusChnage()"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
