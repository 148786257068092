import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { AuthService } from "src/app/auth/auth.service";
import * as moment from "moment";
import * as momentT from "moment-timezone";
import * as XLSX from "xlsx";
import { MatTableDataSource } from "@angular/material/table";
import { MatOption } from "@angular/material/core";

export interface DisplayedColumns {
  key: string;
  value: string;
}

@Component({
  selector: "app-daily-bulk-summary-report",
  templateUrl: "./daily-bulk-summary-report.component.html",
  styleUrls: ["./daily-bulk-summary-report.component.css"],
})
export class DailyBulkSummaryReportComponent implements OnInit {
  data = [];
  isLoading = false;
  isRefresh = false;
  isExportingExcel = false;

  maxDate = new Date(Date.now() - 24 * 3600 * 1000 * 2);

  totalPostsTwo = 0;
  postsPerPageTwo = 5;
  pageSizeOptionTwo = [5, 10, 25, 100];
  currentPageTwo = 1;

  @Input() campaignType;
  dataSourceTwo;
  @Input() displayedColumnsTwo: DisplayedColumns[] = [];
  columnsToDisplayTwo: string[] = [];
  @ViewChild("TableTwoPaginator", { static: false })
  tableTwoPaginator: MatPaginator;
  @ViewChild("TableTwoSort") tableTwoSort: MatSort;

  form;
  fromDate = "_ _ _ _";
  toDate = "_ _ _ _";
  @ViewChild("DateWiseTable") tableDate: ElementRef;
  @ViewChild("CampaignWiseTable") tableCampaign: ElementRef;

  filterColumns = new FormControl(this.columnsToDisplayTwo);
  constructor(private authService: AuthService) {
    this.dataSourceTwo = new MatTableDataSource(null);
  }

  ngOnChanges() {
    this.columnsToDisplayTwo = this.displayedColumnsTwo
      .map(function (item) {
        return item.key;
      })
      .filter((el) => el != "0")
      .slice();
  }

  ngOnInit() {
    this.filterColumns.patchValue([...this.columnsToDisplayTwo, "0"]);
    this.form = new FormGroup({
      fromDate: new FormControl(null, {
        validators: [Validators.required],
      }),
      toDate: new FormControl(null, {
        validators: [Validators.required],
      }),
    });
  }

  toggleColumnSelection(event: MatOption) {
    var allColumns = this.displayedColumnsTwo
      .map(function (item) {
        return item.key;
      })
      .filter((el) => el != "0")
      .slice();
    if (event.value == "0" && event.selected) {
      this.filterColumns.patchValue([
        ...this.displayedColumnsTwo.map((item) => item.key),
        "0",
      ]);
      this.columnsToDisplayTwo = this.displayedColumnsTwo
        .map(function (item) {
          return item.key;
        })
        .filter((el) => el != "0")
        .slice();
    } else if (event.value == "0" && !event.selected) {
      this.filterColumns.patchValue([]);
      this.columnsToDisplayTwo = [].slice();
    } else if (
      event.value != "0" &&
      event.selected &&
      allColumns.length == this.filterColumns.value.length
    ) {
      this.columnsToDisplayTwo = this.filterColumns.value
        .filter((el) => el != "0")
        .slice();
      this.filterColumns.patchValue([...this.columnsToDisplayTwo, "0"]);
    } else {
      this.columnsToDisplayTwo = this.filterColumns.value
        .filter((el) => el != "0")
        .slice();
      this.filterColumns.patchValue([...this.columnsToDisplayTwo]);
    }
  }

  public dateFilterFrom = (d: Date): boolean => {
    const value = this.form.value;
    if (value.toDate) {
      return d <= this.convertToDate(value.toDate) && true;
    } else {
      return true;
    }
  };

  public dateFilterTo = (d: Date): boolean => {
    const value = this.form.value;
    if (value.fromDate) {
      return (
        d >= this.convertToDate(value.fromDate) &&
        d <
          new Date(
            this.convertToDate(value.fromDate).getTime() + 24 * 3600 * 1000 * 30
          ) &&
        true
      );
    } else {
      return true;
    }
  };

  protected convertToDate(d: Date | string): Date {
    return typeof d === "string" ? new Date(d) : d;
  }

  applySearch(formDirective: FormGroupDirective) {
    // console.log(this.form);
    if (this.form.invalid) {
      return;
    }

    this.isRefresh = true;

    this.fromDate = momentT(this.form.value.fromDate)
      .tz("Asia/kolkata")
      .format("YYYY-MM-DD");
    this.toDate = momentT(this.form.value.toDate)
      .tz("Asia/kolkata")
      .format("YYYY-MM-DD");

    this.postsPerPageTwo = 5;
    this.pageSizeOptionTwo = [5, 10, 25, 100];
    this.currentPageTwo = 1;

    this.fetchDataTwo(
      this.currentPageTwo,
      this.postsPerPageTwo,
      this.fromDate,
      this.toDate
    );
  }

  fetchDataTwo(currentPage, postsPerPage, fromDate, toDate) {
    this.isLoading = true;
    this.dataSourceTwo = new MatTableDataSource(null);
    //get campaign list

    this.authService
      .getReportDailySummary(
        currentPage,
        postsPerPage.toString(),
        fromDate,
        toDate,
        this.campaignType
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          // this.totalPostsTwo = response.data.count;
          this.data =
            response?.userData?.map((a) => ({
              date: a?.date,
              mobile_numbers_count: a?.mobile_numbers_count,
              mask: a?.mask,
              delivered_numbers_count: a?.delivered_numbers_count,
              non_delivered_numbers_count: a?.non_delivered_numbers_count,
              sms_segments_count: a?.sms_segments_count,
              delivered_sms_segments_count: a?.delivered_sms_segments_count,
              non_delivered_sms_segments_count:
                a?.non_delivered_sms_segments_count,
              d2d_numbers_count: a?.d2d_numbers,
              d2d_delivered_numbers_count: a?.d2d_delivered_numbers,
              d2d_non_delivered_numbers_count:
                a?.d2d_none_delivered_number_count,
              d2nd_numbers_count: a?.d2nd_numbers,
              d2nd_delivered_numbers_count: a?.d2nd_delivered_numbers,
              d2nd_non_delivered_numbers_count:
                a?.d2nd_none_delivered_number_count,
              d2d_sms_segments_count: a?.d2d_sms_segments_count,
              d2d_delivered_sms_segments_count:
                a?.d2d_delivered_sms_segment_count,
              d2d_non_delivered_sms_segments_count:
                a?.d2d_none_delivered_sms_segment_count,
              d2nd_sms_segments_count: a?.d2nd_messages,
              d2nd_delivered_sms_segments_count:
                a?.d2nd_delivered_sms_segment_count,
              d2nd_non_delivered_sms_segments_count:
                a?.d2nd_none_delivered_sms_segment_count,
              d2d_cost_with_tax: Number(a?.d2d_revenue_with_tax)?.toFixed(2),
              d2nd_cost_with_tax: Number(a?.d2nd_revenue_with_tax)?.toFixed(2),
              total_cost_with_tax: Number(a?.total_cost_with_tax)?.toFixed(2),
            })) || [];
          ////////console.log(this.totalPosts);
          this.dataSourceTwo = new MatTableDataSource(this.data);
          this.dataSourceTwo.sort = this.tableTwoSort;
          if (this.data.length < this.postsPerPageTwo) {
            this.totalPostsTwo = this.currentPageTwo * this.postsPerPageTwo;
          } else {
            this.totalPostsTwo = this.currentPageTwo * this.postsPerPageTwo + 1;
          }
          this.isLoading = false;
          this.isRefresh = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.isRefresh = false;
        }
      );
  }

  onChangedPageTwo(pageData: PageEvent) {
    // console.log(pageData.pageIndex, pageData.pageSize)
    this.currentPageTwo = pageData.pageIndex + 1;
    this.postsPerPageTwo = pageData.pageSize;
    this.fetchDataTwo(
      this.currentPageTwo,
      this.postsPerPageTwo,
      this.fromDate,
      this.toDate
    );
  }

  ExportTOExcel() {
    this.isExportingExcel = true;
    this.authService
      .getReportDailySummary(
        "1",
        "all",
        this.fromDate,
        this.toDate,
        this.campaignType
      )
      .subscribe(
        (response: any) => {
          //console.log(response);
          var data =
            response?.userData?.map((a) => {
              var row = {
                date: a?.date,
                mobile_numbers_count: Number(a?.mobile_numbers_count),
                mask: a?.mask,
                delivered_numbers_count: Number(a?.delivered_numbers_count),
                non_delivered_numbers_count: Number(
                  a?.non_delivered_numbers_count
                ),
                sms_segments_count: Number(a?.sms_segments_count),
                delivered_sms_segments_count: Number(
                  a?.delivered_sms_segments_count
                ),
                non_delivered_sms_segments_count: Number(
                  a?.non_delivered_sms_segments_count
                ),
                d2d_numbers_count: Number(a?.d2d_numbers),
                d2d_delivered_numbers_count: Number(a?.d2d_delivered_numbers),
                d2d_non_delivered_numbers_count: Number(
                  a?.d2d_none_delivered_number_count
                ),
                d2nd_numbers_count: Number(a?.d2nd_numbers),
                d2nd_delivered_numbers_count: Number(a?.d2nd_delivered_numbers),
                d2nd_non_delivered_numbers_count: Number(
                  a?.d2nd_none_delivered_number_count
                ),
                d2d_sms_segments_count: Number(a?.d2d_sms_segments_count),
                d2d_delivered_sms_segments_count: Number(
                  a?.d2d_delivered_sms_segment_count
                ),
                d2d_non_delivered_sms_segments_count: Number(
                  a?.d2d_none_delivered_sms_segment_count
                ),
                d2nd_sms_segments_count: Number(a?.d2nd_messages),
                d2nd_delivered_sms_segments_count: Number(
                  a?.d2nd_delivered_sms_segment_count
                ),
                d2nd_non_delivered_sms_segments_count: Number(
                  a?.d2nd_none_delivered_sms_segment_count
                ),
                d2d_cost_with_tax: Number(
                  Number(a?.d2d_revenue_with_tax)?.toFixed(2)
                ),
                d2nd_cost_with_tax: Number(
                  Number(a?.d2nd_revenue_with_tax)?.toFixed(2)
                ),
                total_cost_with_tax: Number(
                  Number(a?.total_cost_with_tax)?.toFixed(2)
                ),
              };

              return this.columnsToDisplayTwo.reduce(
                (a, key) => Object.assign(a, { [key]: row[key] }),
                {}
              );
            }) || [];
          var dataSourceOne = new MatTableDataSource(data);

          const Heading = [this.columnsToDisplayTwo];

          /* add the header */
          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
          XLSX.utils.sheet_add_aoa(ws, Heading);

          //Starting in the second row to avoid overriding and skipping headers
          XLSX.utils.sheet_add_json(ws, dataSourceOne.filteredData, {
            origin: "A2",
            skipHeader: true,
          });

          /* generate workbook and add the worksheet */
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

          /* save to file */
          XLSX.writeFile(
            wb,
            `report-daily-${
              this.campaignType === 1
                ? "bulk"
                : this.campaignType === 2
                ? "personalized"
                : this.campaignType === 3
                ? "targeting"
                : this.campaignType === 4
                ? "package"
                : this.campaignType === 5
                ? "mask-wise-bulk"
                : undefined
            }-summary-enterprise` +
              new Date().getTime() +
              ".xlsx"
          );
          this.isExportingExcel = false;
        },
        (error) => {
          console.log(error);
          this.isExportingExcel = false;
        }
      );
  }
}
