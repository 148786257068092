<app-contact-list-secondary-header></app-contact-list-secondary-header>
<div class="container-fluid e-filter-section e-filter-section--paddings-x2">
  <div class="row">
    <div class="col-12 col-md-10">
      <h4 class="pt-4 pb-2 e-page-title">Filter</h4>
    </div>
  </div>
  <form [formGroup]="form" (submit)="applySearch(formDirective)" #formDirective="ngForm">
    <div style="padding: 10px"></div>
    <div class="row">
      <div class="col-12 col-md-4">
        <mat-form-field appearance="outline">
          <mat-label>Search</mat-label>
          <input matInput name="searchParamObject" formControlName="searchParam" />
          <mat-error *ngIf="form.get('searchParam').hasError('required')">Search can't be empty
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4">
        <div class="row">
          <div class="col-4 pt-2">
            <button mat-flat-button color="primary" class="e-btn ml-2">
              VIEW
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <hr />
</div>
<section class="pt-5 e-body-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-12 e-card e-card--no-paddings">
        <div *ngIf="isLoading" class="col-12 d-flex justify-content-center align-items-center"
          style="min-height: 50vh;">
          <mat-spinner></mat-spinner>
        </div>
        <section>
          <div class="e-card--table">
            <table mat-table matSort [dataSource]="dataSourceOne" #TableOneSort="matSort">
              <!-- Campaign Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name.</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.name }}
                </td>
              </ng-container>
              <!-- ID Column -->
              <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Created.
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.created | date: "yyyy-MM-dd HH:mm":"+0000" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="creator_id">
                <th mat-header-cell *matHeaderCellDef>View</th>
                <td mat-cell *matCellDef="let element">
                  <a [routerLink]="['/contact/view-list', element.id]" matTooltip="View" [matTooltipPosition]="'below'"
                    matTooltipHideDelay="100">
                    <mat-icon>visibility</mat-icon>
                  </a>
                  <a matTooltip="Delete" [matTooltipPosition]="'below'" matTooltipHideDelay="100"
                    (click)="removeContactList(element.id)">
                    <mat-icon>delete</mat-icon>
                  </a>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsOne"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsOne"></tr>
            </table>
          </div>
          <mat-paginator *ngIf="!isRefresh" #TableOnePaginator="matPaginator" [length]="this.totalPosts"
            [showFirstLastButtons]="false" [pageSizeOptions]="pageSizeOption" [pageSize]="postsPerPage"
            (page)="onChangedPage($event)"></mat-paginator>
        </section>
      </div>
    </div>
  </div>
</section>
