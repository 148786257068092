<div class="navbar-light bg-light">
  <nav class="container">
    <div class="e-sec-header navbar navbar-expand-lg navbar-light bg-light">
      <ul class="navbar-nav mr-auto e-sec-header-nav">
        <li class="nav-item active">
          <button
            class="e-sec-header-nav__item"
            mat-button
            routerLinkActive="e-sec-header-nav__item--active"
            routerLink="/account/report/per-mobile"
          >
            Campaigns
          </button>
        </li>
        <li class="nav-item">
          <button
            class="e-sec-header-nav__item"
            mat-button
            routerLinkActive="e-sec-header-nav__item--active"
            routerLink="/account/report/all-campaigns"
          >
            Mobile
          </button>
        </li>
        <li class="nav-item">
          <button
            class="e-sec-header-nav__item"
            mat-button
            routerLinkActive="e-sec-header-nav__item--active"
            routerLink="/account/report/enterprise-dashboard"
          >
            Monthly Campaign Usage
          </button>
        </li>
        <li class="nav-item">
          <button
            class="e-sec-header-nav__item"
            mat-button
            routerLinkActive="e-sec-header-nav__item--active"
            routerLink="/account/report/usage"
          >
            Mask Usage
          </button>
        </li>
      </ul>
    </div>
  </nav>
</div>

<!-- <nav
  class="container e-sec-header navbar navbar-expand-lg navbar-light bg-light"
>
  <ul class="navbar-nav mr-auto e-sec-header-nav row no-gutters">
    <li class="nav-item active col-3">
      <button
        class="e-sec-header-nav__item"
        mat-button
        routerLinkActive="e-sec-header-nav__item--active"
        routerLink="/account/report/per-mobile"
      >
        Campaigns
      </button>
    </li>
    <li class="nav-item col-3">
      <button
        class="e-sec-header-nav__item"
        mat-button
        routerLinkActive="e-sec-header-nav__item--active"
        routerLink="/account/report/all-campaigns"
      >
        Mobile
      </button>
    </li>
  </ul>
</nav> -->
