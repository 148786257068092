import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { CampaignService } from "src/app/campaign/campaign.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormGroupDirective,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { SuccessComponent } from "src/app/success/success.component";
import { SuccessDialogBoxComponent } from "src/app/pop-up-messages/success-dialog-box/success-dialog-box.component";

@Component({
  selector: "app-new-mask",
  templateUrl: "./new-mask.component.html",
  styleUrls: ["./new-mask.component.css"],
})
export class NewMaskComponent implements OnInit {
  displayedColumns: string[] = ["position", "name", "status"];
  dataSource;
  masks = [];
  isLoading = true;
  isLoadingSecond = false;
  form: FormGroup;
  @ViewChild("TABLE") table: ElementRef;

  @ViewChild(MatSort) set contentSort(sort: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = sort;
    }
  }
  @ViewChild(MatPaginator) set contentPaginator(paginator: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = paginator;
    }
  }
  constructor(
    private campaignService: CampaignService,
    private matDialog: MatDialog
  ) {}
  ngOnInit() {
    let status;
    this.campaignService.getAllMasks().subscribe(
      (response: any) => {
        //console.log(response);
        //1st position is always default mask
        this.masks.push({
          position: 1,
          name: response.defaultMask,
          status: "Active",
        });
        let position = 1;

        for (var i = 0; i < response.additionalMask.length; i++) {
          position++;
          if (response.additionalMask[i].mask_status == 0) {
            status = "Pending(1st Level)";
          } else if (response.additionalMask[i].mask_status == 1) {
            status = "Active";
          } else if (response.additionalMask[i].mask_status == 2) {
            status = "Suspended";
          } else if (response.additionalMask[i].mask_status == 3) {
            status = "Rejected";
          } else if (response.additionalMask[i].mask_status == 4) {
            status = "Pending(Final Level)";
          } else {
            status = "Error Occurred";
          }

          this.masks.push({
            position: position,
            name: response.additionalMask[i].mask,
            status: status,
          });
        }

        //console.log(this.masks);
        this.dataSource = new MatTableDataSource(this.masks);
        this.isLoading = false;
      },
      (error) => {
        //console.log(error);
      }
    );
    //form controller to request new mask
    this.form = new FormGroup({
      mask: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(11),
          Validators.pattern("^[a-zA-Z0-9 _~'&-.@]*$"),
        ],
      }),
      message: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(150),
          Validators.pattern("^[a-zA-Z0-9 _`'&.,:;/\\?-@]*$"),
        ],
      }),
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  onRequestNewMask(formDirective: FormGroupDirective) {
    if (this.form.invalid) {
      return;
    }
    this.isLoadingSecond = true;
    this.campaignService
      .requestMask(this.form.get("mask").value, this.form.get("message").value)
      .subscribe(
        (response: any) => {
          // console.log(response);
          // console.log(this.masks.length);
          this.dataSource.data.push({
            position: this.masks.length + 1,
            name: this.form.get("mask").value,
            status: "Pending",
          });
          this.dataSource.filter = "";
          this.isLoadingSecond = false;
          this.form.markAsUntouched();
          //how capture success response
          this.matDialog.open(SuccessDialogBoxComponent, {
            width: "532px",
            data: { message: response.comment },
          });
        },
        (error) => {
          //console.log(error);
          this.isLoadingSecond = false;
        }
      );
  }
}
