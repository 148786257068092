<app-landing-header></app-landing-header>
<section class="e-main-banner">
  <div class="container">
    <div class="row">
      <div class="col-md-7 e-main-banner__txt pt-5 pl-4">
        <h1 class="e-main-txt mb-3">eSMS</h1>
        <h2 class="e-sub-header mb-3">Corporate SMS solution</h2>
        <P class="e-p-txt"
          >e-SMS is a revolutionary solution by Dialog Axiata PLC which will
          enable you to send an SMS to multiple parties in an easy-to-use
          interface at a very nominal cost without any initial setup costs in
          real-time.</P
        >
        <button
          mat-flat-button
          color="primary"
          class="e-btn mt-2 mb-3"
          routerLink="/signup"
        >
          REGISTER TODAY
        </button>
      </div>
      <div class="col-md-5 e-main-banner__img-wrapper">
        <img
          src="../../../assets/images/banner.png"
          class="e-home-img e-main-banner__img"
        />
      </div>
    </div>
  </div>
</section>

<section class="e-mp-bm">
  <div class="container p-5">
    <div class="row e-mp-bm__inner-w">
      <div class="col-md-6 pt-5 pb-5">
        <img
          src="../../../assets/images/sms-icon.png"
          class="e-home-img e-mp-bm__img"
        />
      </div>
      <div class="col-md-6">
        <div class="e-mp-bm__txt">
          <div>
            <h1 class="e-main-txt mb-3">What is bulk messaging?</h1>
            <P class="e-p-txt"
              >Bulk SMS messaging is a legacy description for
              application-to-person SMS messaging services. It refers
              specifically to the sending of a large number of SMS messages to
              the mobile phones of a predetermined group of recipients.</P
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--What is eSMS Section-->
<section
  class="e-fs-section facts-sheet-section pt-5 pb-2 py-5 e-section-2-view"
>
  <div class="container-fluid">
    <div class="row e-fs-section__inner-w">
      <!--What is eSMS Section Content-->
      <div
        class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 e-section-2-view__txt e-fs-section-content m-auto text-m-center text-st-center"
      >
        <!--What is eSMS Section Content Title-->
        <h1 class="e-main-txt e-main-txt--b mb-3">What is the Dialog e-SMS?</h1>
        <!--What is eSMS Section Description-->
        <p class="e-p-txt">
          The Dialog e-SMS solution platform facilitates enterprises in
          dispatching SMSs to their customers via a mobile application or a WEB
          portal.
        </p>
      </div>

      <!--What is eSMS Section Image-->
      <div
        class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 e-section-2-view__image e-fs-section-image pr-0 p-mt-0"
      >
        <img
          class="w-100 border-m-0 border-st-0"
          src="../../../assets/images/asset2.png"
          alt="facts"
        />
      </div>
    </div>
  </div>
</section>

<!--How it Works Section-->
<section class="e-hw-section e-section-2-view py-5">
  <div class="container-fluid">
    <div class="row e-hw-section__inner-w">
      <!--How it Works Section Image-->
      <div
        class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 e-section-2-view__image-left e-hw-section-image pl-0 p-mt-0 mt-order-2"
      >
        <img
          class="w-100 border-m-0 border-st-0"
          src="../../../assets/images/asset1.png"
          alt="structure"
        />
      </div>

      <!--How it Works Section Content-->
      <div
        class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 e-section-2-view__txt e-hw-section-content m-auto pl-md-5 text-m-center text-st-center mt-order-1"
      >
        <!--How it Works Section Content Title-->
        <h1 class="e-main-txt e-main-txt--b mb-3">How does it work?</h1>
        <!--How it Works Section Description-->
        <p class="e-p-txt">
          The company can register their own accounts by logging into the portal
          and creating the preferred User ID/ Port to send SMSs to their bases.
        </p>
        <!--How it Works Section Button-->
        <!-- <button mat-flat-button color="primary" class="e-btn mt-2 mb-3">
          LEARN MORE
        </button> -->
      </div>
    </div>
  </div>
</section>

<section class="e-fb-section py-5">
  <div class="container">
    <h2 class="e-secondary-header-txt text-center mb-5">
      Features and Benefits
    </h2>
    <div class="row">
      <ul class="offset-md-1 col-12 col-md-11 e-sec-list px-5 px-md-0">
        <li>
          SMSs will be delivered to the customers under a User ID (Masking )
          instead of the mobile number
        </li>
        <li>
          Can send a BULK SMS (thousands of number of SMSs) in a single click
        </li>
        <li>Number file can be uploaded on an excel file</li>
        <li>Obtain delivery reports</li>
        <li>Create/Schedule SMS campaigns</li>
        <li>Supports long messages</li>
        <li>Supports messaging in multiple languages</li>
        <li>Portal is available on both web and mobile application</li>
        <li>Support as a communication channel</li>
        <li>Connectivity: 24×7 service from any location</li>
        <li>Confidentiality and security</li>
        <li>Simple & user-friendly</li>
      </ul>
    </div>
  </div>
</section>

<section class="e-rl-section pt-5">
  <div class="container">
    <h2 class="e-secondary-header-txt text-center mb-5">
      Restrictions and Limitations
    </h2>
    <div class="row">
      <ul class="offset-md-1 col-12 col-md-11 e-sec-list px-5 px-md-0">
        <li>
          If the alphanumeric origination number is used, then the max length of
          the address (Masking )should not exceed 11 characters (including
          spaces).
        </li>
        <li>
          For Marketing / Promotional messages will be blocked from 8pm to 8am
          as regulated by TRCSL
        </li>
        <li>The mask should be relevant & owned by the company</li>
        <li>Maximum of 5 masks can be allowed to a company</li>
      </ul>
    </div>
  </div>
</section>

<section class="e-footer-b-section py-4">
  <div class="container">
    <div class="row">
      <div class="offset-md-1 col-12 col-md-5">
        <h1 class="e-main-txt mb-3">eSMS</h1>
        <h2 class="e-sub-header mb-3">Keep on Connecting</h2>
        <button
          mat-flat-button
          color="primary"
          class="e-btn mt-2 mb-3"
          routerLink="/signup"
        >
          REGISTER YOUR ACCOUNT TODAY
        </button>
      </div>
      <div class="col-md-6 e-footer-b-section__img-wrapper">
        <img
          src="../../../assets/images/banner.png"
          class="e-home-img e-footer-b-section__img"
        />
      </div>
    </div>
  </div>
</section>
