<mat-card>
  <mat-card-content>
    <h1>Campaign Id:&nbsp;{{ this.campaign.campaignId }}</h1>
    <br />
    <h2>
      Campaign Cost:&nbsp;{{ this.campaign.campaignCost | currency:" ":"symbol" }}
      (LKR)
    </h2>
    <br />
    <h2>
      Wallet Balance:&nbsp;{{
        this.campaign.walletBalance | currency:" ":"symbol"
      }}
      (LKR)
    </h2>
    <label></label>
    <form (submit)="proceed(proceedForm)" #proceedForm="ngForm">
      <mat-label><h1>Please Select A Payment Option</h1> </mat-label><br />

      <mat-radio-group
        aria-label="Select an option"
        name="paymentType"
        ngModel
        matInput
        required
        #paymentOption="ngModel"
      >
        <mat-radio-button value="0">Add to Bill</mat-radio-button>
        <mat-radio-button value="1">EzCash</mat-radio-button>
        <mat-radio-button
          *ngIf="this.campaign.campaignCost <= this.campaign.walletBalance"
          value="2"
          >My Wallet</mat-radio-button
        >
      </mat-radio-group>
      <br />
      <mat-error *ngIf="paymentOption.invalid"
        >Please Select a Payment Method</mat-error
      >
      <br />
      <button mat-raised-button color="primary" type="submit">Proceed</button>
    </form>
  </mat-card-content>
</mat-card>
