<app-secondary-header></app-secondary-header>
<div class="container">
  <div class="row">
    <div class="col-12" style="display: flex; justify-content: center;">
      <mat-radio-group [(ngModel)]="reportType" class="e-radio">
        <mat-radio-button [value]="1">Daily Report</mat-radio-button>
        <mat-radio-button [value]="2">Monthly Report</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>
<mat-tab-group class="e-body-wrapper" mat-align-tabs="center" (selectedTabChange)="tabChangeMain($event)">
  <mat-tab label="Bulk Summary">
    <app-daily-bulk-summary-report *ngIf="tabType === 1 && reportType === 1"
      [displayedColumnsTwo]="displayedColumnsBulkDaily" [campaignType]="1"></app-daily-bulk-summary-report>
    <app-monthly-bulk-summary-report *ngIf="tabType === 1 && reportType === 2"
      [displayedColumnsTwo]="displayedColumnsBulkMonthly" [campaignType]="1"></app-monthly-bulk-summary-report>
  </mat-tab>
  <mat-tab label="Personalized Summary">
    <app-daily-bulk-summary-report *ngIf="tabType === 2 && reportType === 1"
      [displayedColumnsTwo]="displayedColumnsPersonalizedDaily" [campaignType]="2"></app-daily-bulk-summary-report>
    <app-monthly-bulk-summary-report *ngIf="tabType === 2 && reportType === 2"
      [displayedColumnsTwo]="displayedColumnsPersonalizedMonthly" [campaignType]="2"></app-monthly-bulk-summary-report>
  </mat-tab>
  <mat-tab label="Targeting Summary">
    <app-daily-bulk-summary-report *ngIf="tabType === 3 && reportType === 1"
      [displayedColumnsTwo]="displayedColumnsTargetingDaily" [campaignType]="3"></app-daily-bulk-summary-report>
    <app-monthly-bulk-summary-report *ngIf="tabType === 3 && reportType === 2"
      [displayedColumnsTwo]="displayedColumnsTargetingMonthly" [campaignType]="3"></app-monthly-bulk-summary-report>
  </mat-tab>
  <mat-tab label="Package Summary">
    <app-daily-bulk-summary-report *ngIf="tabType === 4 && reportType === 1"
      [displayedColumnsTwo]="displayedColumnsPackageDaily" [campaignType]="4"></app-daily-bulk-summary-report>
    <app-monthly-bulk-summary-report *ngIf="tabType === 4 && reportType === 2"
      [displayedColumnsTwo]="displayedColumnsPackageMonthly" [campaignType]="4"></app-monthly-bulk-summary-report>
  </mat-tab>
</mat-tab-group>
