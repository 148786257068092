<div class="container-fluid e-modal e-ntf-modal">
  <button
    mat-mini-fab
    color="primary"
    class="e-btn-modal-close e-btn-modal-close--adj"
    (click)="closeDialog()"
  >
    <mat-icon class="e-btn-modal-close__icon" (click)="closeDialog()"
      >close</mat-icon
    >
  </button>
  <h2 mat-dialog-title class="e-page-title">Message {{this.data.message_id}}</h2>
  <div mat-dialog-content class="row mt-2">
    <div class="col-12">
      <p class="e-ntf-modal__body">
        {{ this.data.message }}
      </p>
    </div>
  </div>
</div>
<div class="e-modal-footer mb-1" mat-dialog-actions>
  <button
    mat-flat-button
    color="primary"
    class="e-btn e-btn--small"
    (click)="readUnreadMessage('1');"
    [disabled]="!isReadMessage || isRequesting"
  >
    Read
  </button>
  <button
    mat-flat-button
    color="primary"
    class="e-btn e-btn--small"
    (click)="readUnreadMessage('0');"
    [disabled]="isReadMessage || isRequesting"
  >
    Unread
  </button>
</div>
