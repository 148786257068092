<section class="e-body-wrapper pb-5">
  <div class="container">
    <div class="row pt-4 pb-2 e-body-header">
      <button mat-button class="e-back-btn" routerLink="/template/list">
        <span class="material-icons e-back-btn__icon">arrow_back</span>
        BACK
      </button>
    </div>
    <div class="row">
      <div class="offset-md-1 col-md-10 e-card">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <form *ngIf="!isLoading" (submit)="createCampaign()" [formGroup]="form" #campaignFormDirective="ngForm">
          <h4 class="mb-4 e-page-title text-center">Edit Template ID: {{ this.data?.id }}</h4>
          <div class="row">
            <div class="col-6">
              <mat-form-field appearance="outline" class="e-input">
                <mat-label>List name</mat-label>
                <input matInput type="text" formControlName="templateListName" maxlength="44" />
                <mat-error *ngIf="form.get('templateListName').hasError('required')">List name can't be null</mat-error>
                <mat-error *ngIf="form.get('templateListName').hasError('minlength')">List name contain atleast 3
                  characters</mat-error>
                <mat-error *ngIf="form.get('templateListName').hasError('pattern')">Illegal characters in the List name
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-6"></div>
          </div>
          <div class="row">
            <div class="col-12">
              <mat-form-field appearance="outline" class="e-input">
                <mat-label>Message</mat-label>
                <textarea matInput formControlName="message" (input)="messageCounterUpdate()">
                </textarea>
                <mat-hint>{{ messageRemLength }} / {{ numberOfMessages }}</mat-hint>
                <mat-error *ngIf="form.get('message').hasError('required')">Message body can't be null</mat-error>
                <mat-error *ngIf="form.get('message').hasError('minlength')">Message body must contain atleast 3
                  characters</mat-error>
                <mat-error *ngIf="form.get('message').hasError('pattern')">Illegal characters in the message body
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-6 col-md-6">
              <div class="e-inp-view__lbl">Created time</div>
              <div class="e-inp-view__val">
                {{
                this.data?.createdAt | date: "yyyy-MM-dd HH:mm":"+0000"
                }}
              </div>
            </div>
            <div class="col-6 col-md-6">
              <div class="e-inp-view__lbl">Updated time</div>
              <div class="e-inp-view__val">
                {{
                this.data?.updatedAt | date: "yyyy-MM-dd HH:mm":"+0000"
                }}
              </div>
            </div>
          </div>

          <div class="row e-btn-group e-btn-group--center mt-5">
            <button mat-stroked-button class="e-btn-stroke mr-2" type="button" [routerLink]="['/template/list']">
              CANCEL
            </button>
            <button mat-flat-button color="primary" class="e-btn ml-2" type="submit"
              [disabled]="form.get('message').value == this.data.message && form.get('templateListName').value == this.data.template_name">
              SAVE
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
