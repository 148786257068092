import { Component, OnInit, OnDestroy } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable, Subscription, Subject, timer } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { AuthService } from "../auth/auth.service";
import { Router } from "@angular/router";
import { error } from "protractor";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  isAutheticated = false;
  authListerSubscription: Subscription;
  authNotifcation: Subscription;
  account = null;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    public router: Router
  ) {}
  ngOnInit(): void {
    this.isAutheticated = this.authService.getAuthenticated();
    this.authListerSubscription = this.authService
      .getAuthStatusListener()
      .subscribe((status) => {
        this.isAutheticated = status;
        this.account = this.authService.getUserData();
      });
    this.authNotifcation ==
      timer(0, 300000)
        .pipe(switchMap(() => this.authService.getNotificationInfo()))
        .subscribe(
          (response: any) => {
            if (response.status == "success" && response.comment == "success") {
              this.account = response.userData;
            }
          },
          (error) => {}
        );
  }
  countUnReadNotifications(notificationList) {
    let i;
    let count = 0;
    for (i = 0; i < notificationList.length; i++) {
      if (notificationList[i].status == 0) {
        count++;
      }
    }
    return count;
  }
  isUnReadNotifications(notificationList) {
    let i;
    let truth = false;
    for (i = 0; i < notificationList.length; i++) {
      if (notificationList[i].status == 0) {
        truth = true;
        break;
      }
    }
    return truth;
  }
  ngOnDestroy() {
    this.authListerSubscription.unsubscribe();
    this.authNotifcation.unsubscribe();
  }
  logout() {
    this.authService.logout();
  }
  getUserData() {
    if (!this.account) {
      return null;
    } else {
      return this.account;
    }
  }
}
