import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { CampaignService } from "src/app/campaign/campaign.service";
import { ConfirmDialogBoxComponent } from "src/app/pop-up-messages/confirm-dialog-box/confirm-dialog-box.component";
import { SuccessDialogBoxComponent } from "src/app/pop-up-messages/success-dialog-box/success-dialog-box.component";

@Component({
  selector: "app-contact-list-all",
  templateUrl: "./contact-list-all.component.html",
  styleUrls: ["./contact-list-all.component.css"],
})
export class ContactListAllComponent implements OnInit {
  isLoading = true;

  dataSourceAll;

  totalPosts = 0;
  postsPerPage = 5;
  pageSizeOption = [5, 10, 25, 100];
  currentPage = 1;

  dataSourceOne;
  displayedColumnsOne: string[] = ["name", "created", "creator_id"];
  @ViewChild("TableOnePaginator", { static: false })
  tableOnePaginator: MatPaginator;
  @ViewChild("TableOneSort") tableOneSort: MatSort;

  constructor(
    private textCapaignService: CampaignService,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.fetchData(this.currentPage, this.postsPerPage);
  }

  fetchData(currentPage, postsPerPage) {
    this.isLoading = true;
    this.dataSourceOne = new MatTableDataSource(null);
    //get campaign list

    this.textCapaignService
      .getContactList(currentPage, postsPerPage.toString())
      .subscribe(
        (response: any) => {
          ////////console.log(response);
          // this.totalPosts = response.count;
          ////////console.log(this.totalPosts);
          this.dataSourceAll = response?.data||[];
          this.dataSourceOne = new MatTableDataSource(this.dataSourceAll);
          this.dataSourceOne.sort = this.tableOneSort;
          if (this.dataSourceAll.length < this.postsPerPage) {
            this.totalPosts = this.currentPage * this.postsPerPage;
          } else {
            this.totalPosts = this.currentPage * this.postsPerPage + 1;
          }
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
        }
      );
  }

  onChangedPage(pageData: PageEvent) {
    this.currentPage = pageData.pageIndex + 1;
    this.postsPerPage = pageData.pageSize;
    this.fetchData(this.currentPage, this.postsPerPage);
  }

  removeContactList(id) {
    var title;
    var message;

    title = "Delete Contact List";
    message =
      "Are you sure, you want to remove the contact list id: " + id + "?";
    const confirmDialog = this.matDialog.open(ConfirmDialogBoxComponent, {
      data: {
        title: title,
        message: message,
      },
    });
    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.textCapaignService.deleteContactList(id).subscribe(
          (response: any) => {
            // console.log(response);
            this.matDialog.open(SuccessDialogBoxComponent, {
              width: "532px",
              data: {
                message: response.comment,
              },
            });
            this.fetchData(this.currentPage, this.postsPerPage);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }

}
