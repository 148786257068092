import { LabelType, Options } from "@angular-slider/ngx-slider";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ChipAutocompleteTagetedComponent } from "../chip-autocomplete-tageted/chip-autocomplete-tageted.component";

@Component({
  selector: "app-targeting",
  templateUrl: "./targeting.component.html",
  styleUrls: ["./targeting.component.css"],
})
export class TargetingComponent implements OnInit {
  @Input() form: FormGroup;
  @ViewChild("workshopDistricts")
  workshopDistrictsChild: ChipAutocompleteTagetedComponent;
  @ViewChild("workshopCities")
  workshopCitiesChild: ChipAutocompleteTagetedComponent;
  @ViewChild("homeDistricts")
  homeDistrictsChild: ChipAutocompleteTagetedComponent;
  @ViewChild("homeCities") homeCitiesChild: ChipAutocompleteTagetedComponent;
  @ViewChild("billingDistricts")
  billingDistrictsChild: ChipAutocompleteTagetedComponent;

  ageOptions: Options = {
    floor: 16,
    ceil: 100,
    step: 1,
    disabled: true,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Floor:
          return "Min";
        case LabelType.Ceil:
          return "Max";
        default:
          return value.toString();
      }
    },
  };
  arpuOptions: Options = {
    floor: 0,
    ceil: 20000,
    step: 500,
    disabled: true,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Floor:
          return "Min";
        case LabelType.Ceil:
          return "Max";
        default:
          return value.toString();
      }
    },
  };
  @Input() rateDescription;
  @Input() rateGroup;
  @Input() workLocations;
  @Input() homeLocations;
  @Input() billingLocations;

  panelOpenState = false;
  displayedColumns: string[] = ["_100000", "_500000", "_1000000", "_1000001"];
  dataSource = [];

  allWorkDistricts: string[];
  allWorkCities: string[] = [];
  allHomeDistricts: string[];
  allHomeCities: string[];
  allBillingDistricts: string[];

  genderList = [
    { value: "male", name: "Male" },
    { value: "female", name: "Female" },
  ];
  languagesList = [
    { value: "Sinhala", name: "Sinhala" },
    { value: "Tamil", name: "Tamil" },
    { value: "English", name: "English" },
  ];
  deviceTypeList = [
    { label: "Basic", value: "Basic" },
    { label: "Smart Phones", value: "Smartphone" },
    { label: "Tablets", value: "Tablet" },
    { label: "Feature Phones", value: "Feature phone" },
  ];

  constructor() {}

  ngOnInit(): void {
    this.dataSource = [this.rateGroup];
    this.allWorkDistricts = this.workLocations.map(function (item) {
      return item["district"];
    });
    this.allHomeDistricts = this.homeLocations.map(function (item) {
      return item["district"];
    });
    this.allBillingDistricts = this.billingLocations.map(function (item) {
      return item["district"];
    });

    if (this.form?.get("age")) {
      this.onAgeChecked(this.form.get("age")?.value?.checked);
    }
    if (this.form?.get("arpu")) {
      this.onArpuChecked(this.form.get("arpu")?.value?.checked);
    }

    if (
      this.form?.get("location")?.get("workLocation")?.get("district")?.value &&
      this.form?.get("location")?.get("workLocation")?.get("district")?.value
        ?.length > 0
    ) {
      this.allWorkCities = this.workLocations
        .filter((item) =>
          this.form
            ?.get("location")
            ?.get("workLocation")
            ?.get("district")
            ?.value?.includes(item.district)
        )
        .map(function (item) {
          return item["cities"];
        })
        .toString()
        .split(",");
    }

    if (
      this.form?.get("location")?.get("homeLocation")?.get("district")?.value &&
      this.form?.get("location")?.get("homeLocation")?.get("district")?.value
        ?.length > 0
    ) {
      this.allHomeCities = this.workLocations
        .filter((item) =>
          this.form
            ?.get("location")
            ?.get("homeLocation")
            ?.get("district")
            ?.value?.includes(item.district)
        )
        .map(function (item) {
          return item["cities"];
        })
        .toString()
        .split(",");
    }

    this.form
      ?.get("location")
      ?.get("workLocation")
      ?.get("district")
      ?.valueChanges.subscribe((value) => {
        if (!value?.length) {
          this.form
            ?.get("location")
            ?.get("workLocation")
            ?.get("city")
            .setValue([]);
        }
        this.allWorkCities = this.workLocations
          .filter((item) => value?.includes(item.district))
          .map(function (item) {
            return item["cities"];
          })
          .toString()
          .split(",");
      });

    this.form
      ?.get("location")
      ?.get("homeLocation")
      ?.get("district")
      ?.valueChanges.subscribe((value) => {
        if (!value?.length) {
          this.form
            ?.get("location")
            ?.get("homeLocation")
            ?.get("city")
            .setValue([]);
        }
        this.allHomeCities = this.homeLocations
          .filter((item) => value?.includes(item.district))
          .map(function (item) {
            return item["cities"];
          })
          .toString()
          .split(",");
      });
  }

  onAgeChecked(val): void {
    this.ageOptions = Object.assign({}, this.ageOptions, { disabled: !val });
  }

  onGenderChecked(val): void {
    if (val) {
      this.form.get("gender").get("value").enable();
    } else {
      this.form.get("gender").get("value").disable();
      this.form.get("gender").reset();
    }
  }

  onLanguageChecked(val): void {
    if (val) {
      this.form.get("language").get("value").enable();
    } else {
      this.form.get("language").get("value").disable();
      this.form.get("language").reset();
    }
  }

  onDeviceTypeChecked(val): void {
    if (val) {
      this.form.get("device_type").get("value").enable();
    } else {
      this.form.get("device_type").get("value").disable();
      this.form.get("device_type").reset();
    }
  }

  onLocationChecked(val): void {
    if (val) {
      this.form.get("location").get("workLocation").get("checked").enable();
      this.form.get("location").get("homeLocation").get("checked").enable();
      this.form.get("location").get("billingLocation").get("checked").enable();
    } else {
      this.form.get("location").get("workLocation").disable();
      this.form.get("location").get("homeLocation").disable();
      this.form.get("location").get("billingLocation").disable();
      this.form.get("location").reset();
      this.workshopDistrictsChild.removeAllChip(this.allWorkDistricts);
      this.workshopCitiesChild.removeAllChip(this.allWorkCities);
      this.homeDistrictsChild.removeAllChip(this.allHomeDistricts);
      this.homeCitiesChild.removeAllChip(this.allHomeCities);
      this.billingDistrictsChild.removeAllChip(this.allBillingDistricts);
    }
  }

  onWorkLocationChecked(val): void {
    if (val) {
      this.form.get("location").get("workLocation").get("district").enable();
      this.form.get("location").get("workLocation").get("city").enable();
    } else {
      this.form.get("location").get("workLocation").get("district").disable();
      this.form.get("location").get("workLocation").get("city").disable();
      this.form.get("location").get("workLocation").reset();
      this.workshopDistrictsChild.removeAllChip(this.allWorkDistricts);
      this.workshopCitiesChild.removeAllChip(this.allWorkCities);
    }
  }

  onHomeLocationChecked(val): void {
    if (val) {
      this.form.get("location").get("homeLocation").get("district").enable();
      this.form.get("location").get("homeLocation").get("city").enable();
    } else {
      this.form.get("location").get("homeLocation").get("district").disable();
      this.form.get("location").get("homeLocation").get("city").disable();
      this.form.get("location").get("homeLocation").reset();
      this.homeDistrictsChild.removeAllChip(this.allHomeDistricts);
      this.homeCitiesChild.removeAllChip(this.allHomeCities);
    }
  }

  onBillingLocationChecked(val): void {
    if (val) {
      this.form.get("location").get("billingLocation").get("district").enable();
    } else {
      this.form
        .get("location")
        .get("billingLocation")
        .get("district")
        .disable();
      this.form.get("location").get("billingLocation").reset();
      this.billingDistrictsChild.removeAllChip(this.allBillingDistricts);
    }
  }

  onArpuChecked(val): void {
    this.arpuOptions = Object.assign({}, this.arpuOptions, {
      disabled: !val,
    });
  }
}
