import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { AuthService } from "../auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  show: boolean;
  constructor(public authService: AuthService) {
    this.show = false;
  }

  ngOnInit(): void {
    //clear all sesseion data
    this.authService.logout();
  }
  onLogin(form: NgForm) {
    if (form.invalid) {
      return;
    }
    this.authService.loginUser(form.value.username, form.value.password);
  }
  password() {
    this.show = !this.show;
  }
}
