import { Component, Input, OnInit } from "@angular/core";
import { AuthService } from "../auth/auth.service";

@Component({
  selector: "app-help-banner",
  templateUrl: "./help-banner.component.html",
  styleUrls: ["./help-banner.component.css"],
})
export class HelpBannerComponent implements OnInit {

  constructor(public authService: AuthService) {}

  ngOnInit(): void {}

  closeBtn() {
    this.authService.showBanner = true;
  }
}
