import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormGroupDirective,
} from "@angular/forms";
import { Route } from "@angular/compiler/src/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";

@Component({
  selector: "app-verify-mobile",
  templateUrl: "./verify-mobile.component.html",
  styleUrls: ["./verify-mobile.component.css"],
})
export class VerifyMobileComponent implements OnInit {
  form: FormGroup;
  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      mobile: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(11),
          Validators.pattern("(^07[0-9]{8}$)|(^947[0-9]{8}$)|(^7[0-9]{8}$)"),
        ],
      }),
    });
  }
  onVerifyPin(formDirective: FormGroupDirective) {
    if (
      this.form.invalid ||
      (this.form.value.mobile.length != 9 &&
        this.form.value.mobile.length != 10 &&
        this.form.value.mobile.length != 11)
    ) {
      return;
    } else {
      let mobileNumber;
      if (this.form.value.mobile.length == 9) {
        mobileNumber = this.form.value.mobile;
      } else if (this.form.value.mobile.length == 10) {
        mobileNumber = this.form.value.mobile.substr(1);
      } else if (this.form.value.mobile.length == 11) {
        mobileNumber = this.form.value.mobile.substr(2);
      }
      this.authService.verifyMobile(mobileNumber).subscribe(
        (response: any) => {
          //console.log(response);
          const verifyMobileToken = response.verifyMobileToken;
          const verifyMobileExpiration = response.verifyMobileTokenExpiration;
          const now = new Date();
          const verifyMobileExpirationDate = new Date(
            now.getTime() + verifyMobileExpiration * 1000
          );

          this.authService.saveVerifyMobileAuthData(
            verifyMobileToken,
            verifyMobileExpirationDate.toISOString()
          );
          this.router.navigate([
            "/account/forgot-password/enter-pin",
            this.form.value.mobile,
          ]);
        },
        (error) => {
          //console.log(error);
          this.router.navigate(["/login"]);
        }
      );
    }
  }
}
