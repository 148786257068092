import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SuccessDialogBoxComponent } from "src/app/pop-up-messages/success-dialog-box/success-dialog-box.component";
import { DatePipe } from "@angular/common";
import { CampaignService } from "src/app/campaign/campaign.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { AuthService } from "src/app/auth/auth.service";
import { HttpClient } from "@angular/common/http";
import { NumberMimeType } from "./mime-type.validator";
import * as moment from "moment";
import * as momentT from "moment-timezone";
import { SuccessComponent } from "src/app/success/success.component";
@Component({
  selector: "app-create-list",
  templateUrl: "./create-list.component.html",
  styleUrls: ["./create-list.component.css"],
})
export class CreateListComponent implements OnInit {
  masks = [];
  form: FormGroup;
  isLoading = false;
  messageRemLength = 160;
  numberOfMessages = 1;
  msg_default_size = 1;
  msg_multiplier = 1;
  file_select = false;
  minDate;
  maxDate;
  maskName = null;
  signatureText = "";
  account;
  constructor(
    private http: HttpClient,
    private campaignService: CampaignService,
    private router: Router,
    private matDialog: MatDialog,
    private authService: AuthService
  ) {
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();
    const currentDay = date.getDate();
    this.minDate = new Date(currentYear - 0, currentMonth, currentDay);
    this.maxDate = new Date(currentYear + 0, currentMonth, currentDay + 7);
  }

  ngOnInit(): void {
    //form validations
    this.form = new FormGroup({
      contactListName: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern("^[a-zA-Z0-9-.,_'\"`|:() /]*$"),
        ],
      }),
      msisdns: new FormControl("", {
        validators: [Validators.pattern("^[0-9]{9}([,][0-9]{9})*$")],
      }),
      fileSelect: new FormControl(null, {
        validators: [Validators.required],
        asyncValidators: [NumberMimeType],
        // updateOn: "submit",
      }),
      fileName: new FormControl(null, {
        validators: [Validators.required],
      }),
    });
  }

  createCampaign() {
    this.file_select = true;
    if (this.form.invalid) {
      return;
    }
    let scheduleNewTime = this.form.value.scheduleTime;
    if (this.form.value.scheduleNow == true) {
      scheduleNewTime = momentT()
        .add(2, "minutes")
        .tz("Asia/kolkata")
        .format("HH:mm A");
    }
    this.isLoading = true;
    this.campaignService
      .createContactList(
        this.form.value.contactListName,
        this.form.value.msisdns,
        this.form.value.fileSelect
      )
      .subscribe(
        (response: any) => {
          this.isLoading = false;

          if (response.status == "success") {
            this.file_select = false;
            //console.log(response);
            //navigate
            this.router.navigate(["/contact/list"]);
            //how capture success response
            this.matDialog.open(SuccessComponent, {
              width: "542px",
              data: {
                message:
                  "New Contact List Created. We have removed " +
                  response.data.duplicates +
                  " duplicates and " +
                  response.data.invalidNumbers +
                  " invalid mobile numbers from the list.",
              },
            });
            this.router.navigate(["/contact/list"]);
          } else {
            console.log("invalid");
          }
        },
        (error) => {
          this.isLoading = false;
          console.log(error);
        }
      );
    //console.log(this.form);
  }

  onNumberClick(event: Event) {
    this.file_select = true;
  }
  onExcelPicked(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({ fileSelect: file });
    this.form.get("fileSelect").updateValueAndValidity();
    this.form.patchValue({ fileName: file.name });
    this.form.get("fileName").updateValueAndValidity();
  }
}
