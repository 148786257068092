<app-campaign-secondary-header></app-campaign-secondary-header>
<div class="container-fluid e-filter-section e-filter-section--paddings-x2">
  <div class="row">
    <div class="col-12 col-md-10">
      <h4 class="pt-4 pb-2 e-page-title">Filter</h4>
    </div>
  </div>
  <form [formGroup]="form" (submit)="applySearch(formDirective)" #formDirective="ngForm">
    <div style="padding: 10px"></div>
    <div class="row">
      <div class="col-12 col-md-4">
        <mat-form-field appearance="outline">
          <mat-label>Campaign Type</mat-label>
          <mat-select formControlName="campaignType" name="campaignTypeObjet">
            <mat-option *ngFor="let type of campaignTypes" [value]="type.value">
              {{type.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('campaignType').hasError('required')">You must make a selection</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-4">
        <mat-form-field appearance="outline">
          <mat-label>Search</mat-label>
          <input matInput name="searchParamObject" formControlName="searchParam" />
          <mat-error *ngIf="form.get('searchParam').hasError('required')">Search can't be empty
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-4">
        <div class="row">
          <div class="col-4 pt-2">
            <button mat-flat-button color="primary" class="e-btn ml-2">
              VIEW
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <hr />
</div>
<section class="pt-5 e-body-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-12 e-card e-card--no-paddings">
        <section>
          <div class="e-card--table">
            <table mat-table matSort [dataSource]="dataSourceOne" #TableOneSort="matSort">
              <!-- Position Column -->
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>No.</th>
                <td mat-cell *matCellDef="let element">{{ element.id }}</td>
              </ng-container>
              <!-- Campaign Column -->
              <ng-container matColumnDef="campaign_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name.</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.campaign_name }}
                </td>
              </ng-container>
              <!-- Campaign Type -->
              <ng-container matColumnDef="campaign_type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Type.</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.api_campaign==1 ? 'API': element.campaign_type==3 ||
                  element.campaign_type==4?'personalized':'bulk'
                  }}
                </td>
              </ng-container>
              <!-- ID Column -->
              <ng-container matColumnDef="source_address">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Sender.
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.source_address }}
                </td>
              </ng-container>
              <!-- ID Column -->
              <ng-container matColumnDef="created_time">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Created.
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.created_time | date: "yyyy-MM-dd HH:mm":"+0000" }}
                </td>
              </ng-container>
              <!-- ID Column -->
              <ng-container matColumnDef="schedule_time">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Scheduled.
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.schedule_time | date: "yyyy-MM-dd HH:mm":"+0000" }}
                </td>
              </ng-container>
              <!-- ID Column -->
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Status.
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ this.fetchedCampaignStatus(element.status) }}
                </td>
              </ng-container>
              <!-- Message ID Column -->

              <ng-container matColumnDef="message_id">
                <th mat-header-cell *matHeaderCellDef>View</th>
                <td mat-cell *matCellDef="let element">
                  <a [routerLink]="['/campaign/view', element.id]">
                    <mat-icon>visibility</mat-icon>
                  </a>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsOne"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsOne"></tr>
            </table>
            <div *ngIf="isLoading" class="col-12 d-flex justify-content-center align-items-center"
              style="min-height: 50vh;">
              <mat-spinner></mat-spinner>
            </div>
          </div>
          <mat-paginator *ngIf="!isRefresh" #TableOnePaginator="matPaginator" [length]="this.totalPosts"
            [showFirstLastButtons]="false" [pageSizeOptions]="pageSizeOption" [pageSize]="postsPerPage"
            (page)="onChangedPage($event)"></mat-paginator>
        </section>
      </div>
    </div>
  </div>
</section>
