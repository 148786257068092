import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { DatePipe } from "@angular/common";
import { CampaignService } from "src/app/campaign/campaign.service";
import { MatTableDataSource } from "@angular/material/table";
import { AuthService } from "../../auth/auth.service";
import { DialogData } from "src/app/campaign/campaign-create/campaign-create.component";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import {
  Validators,
  FormControl,
  FormGroup,
  FormGroupDirective,
} from "@angular/forms";
import { SuccessComponent } from "src/app/success/success.component";
import * as moment from "moment";
import * as momentT from "moment-timezone";
import { SuccessDialogBoxComponent } from "src/app/pop-up-messages/success-dialog-box/success-dialog-box.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfirmDialogBoxComponent } from "src/app/pop-up-messages/confirm-dialog-box/confirm-dialog-box.component";
@Component({
  selector: "app-user-dashboard",
  templateUrl: "./user-dashboard.component.html",
  styleUrls: ["./user-dashboard.component.css"],
})
export class UserDashboardComponent implements OnInit {
  dataSourceCurrent;
  dataSourceUpcomming;
  isLoading = true;
  isLoadingBal = true;
  urlMsgLoading = true;

  paymentType;

  urlMsgKey;
  account;
  additionalAddons;
  dataSourceTwo;
  displayedColumnsTwo: string[] = [
    "id",
    "campaign_name",
    "source_address",

    "schedule_time",
    "message_id",
  ];

  // @ViewChild(MatPaginator) set TableTwoPaginator(
  //   tableTwoPaginator: MatPaginator
  // ) {
  //   if (this.dataSourceTwo) {
  //     this.dataSourceTwo.paginator = tableTwoPaginator;
  //   }
  // }
  @ViewChild(MatSort) set TableTwoSort(tableTwoSort: MatSort) {
    if (this.dataSourceTwo) {
      this.dataSourceTwo.sort = tableTwoSort;
    }
  }
  dataSourceThree;
  displayedColumnsThree: string[] = [
    "id",
    "campaign_name",
    "source_address",
    "schedule_time",

    "message_id",
  ];

  // @ViewChild("TableThreePaginator") set TableThreePaginator(
  //   tableThreePaginator: MatPaginator
  // ) {
  //   if (this.dataSourceThree) {
  //     this.dataSourceThree.paginator = tableThreePaginator;
  //   }
  // }
  @ViewChild("TableThreeSort") set TableThreeSort(tableThreeSort: MatSort) {
    if (this.dataSourceThree) {
      this.dataSourceThree.sort = tableThreeSort;
    }
  }
  constructor(
    private textCapaignService: CampaignService,
    private datePipe: DatePipe,
    private authService: AuthService,
    private matDialog: MatDialog,
    private router: Router,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.paymentType = this.authService.getPaymentType();
    this.initialLoad();
  }

  initialLoad() {
    this.isLoading = true;
    this.isLoadingBal = true;
    this.authService.getDashBoardInfo().subscribe(
      (response: any) => {
        // console.log(response);
        this.account = response;
        this.additionalAddons = JSON.parse(this.account?.userData?.additional_addons);
        if(this.additionalAddons?.isAbleSendUrlMessages){
          this.viewMsgKey();
        }
        const date = new Date();
        let j = 0;
        this.dataSourceCurrent = response.userData.campaignList.filter(
          (campaign) =>
            // this.datePipe.transform(campaign.schedule_time, "yyyy-MM-dd") ===
            //   this.datePipe.transform(date, "yyyy-MM-dd") &&
            campaign.status == 1 && j++ < 5
        );
        let i = 0;
        this.dataSourceUpcomming = response.userData.campaignList.filter(
          (campaign) =>
            new Date(campaign.schedule_time) > date &&
            campaign.status != 11 &&
            campaign.status == 0 &&
            i++ < 5
        );
        this.dataSourceTwo = new MatTableDataSource(this.dataSourceCurrent);
        this.dataSourceThree = new MatTableDataSource(this.dataSourceUpcomming);

        this.isLoading = false;
        this.isLoadingBal = false;
      },
      (error) => {
        //console.log(error);
        this.isLoading = false;
        this.isLoadingBal = false;
        // this.router.navigate(["/login"]);
      }
    );
  }
  openReload() {
    if (this.account.userData != null) {
      const dialogRefOpen = this.matDialog.open(
        UserDashBoardReloadPopupDialogComponent,
        {
          width: "630px",
          height: "700px",
          data: {
            mobile: this.account.userData.mobile,
          },
        }
      );
      dialogRefOpen.afterClosed().subscribe((result) => {
        if (result.data == true) {
          this.isLoadingBal = true;
          this.authService.getDashBoardInfo().subscribe(
            (response: any) => {
              //console.log(response);
              this.isLoadingBal = false;
              this.account = response;
            },
            () => {
              this.isLoadingBal = false;
            }
          );
        } else {
          this.isLoadingBal = false;
        }
      });
    }
  }
  fetchedCampaignStatus(status) {
    let status_string = null;
    switch (status) {
      case 0:
        status_string = "pending";
        break;
      case 1:
        status_string = "running";
        break;
      case 2:
        status_string = "completed";
        break;
      case 3:
        status_string = "deleted ";
        break;
      case 4:
        status_string = "modified";
        break;
      case 5:
        status_string = "pause";
        break;
      case 6:
        status_string = "expired";
        break;
      case 7:
        status_string = "two phase locked ";
        break;
      case 8:
        status_string = "stopped";
        break;
      case 9:
        status_string = "waiting for payment";
        break;
      case 11:
        status_string = "payment failed";
        break;
      default:
        status_string = "inactive";
        break;
    }
    return status_string;
  }
  messageCount(inputArray: any) {
    let i;
    let count = 0;
    for (i = 0; i < inputArray?.length; i++) {
      count += inputArray[i]?.campaign_messages;
    }
    return count;
  }
  getTimeWelcome() {
    const hours = momentT().tz("Asia/kolkata").format("H");

    if (+hours < 12) {
      return "Good Morning";
    } else if ((+hours > 12 && +hours < 16) || +hours == 12) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  }

  viewMsgKey(){
    this.urlMsgLoading = true;
    this.authService.getMsgKey().subscribe(
      (response: any) => {
        // console.log(response);

        this.urlMsgKey = response?.data?.url_msg_key;
        this.urlMsgLoading = false;

      },
      (error: any) => {
        console.log(error);
        this.urlMsgLoading = false;

      }
    );
  }

  generateMsgKey(){
    var title;
    var message;

      title = "URL Message Key Generation";
      message =
        "Are you sure, you want to generate URL Message key";
    const confirmDialog = this.matDialog.open(ConfirmDialogBoxComponent, {
      data: {
        title: title,
        message: message,
      },
    });
    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.authService.generateMsgKey().subscribe(
          (response: any) => {
            // //console.log(response);

            this.matDialog.open(SuccessComponent, {
              width: "542px",
              data: { message: response.comment },
            });

            this.viewMsgKey();
          },
          (error: any) => {
            console.log(error);
          }
        );
      }
    });
  }

  removeMsgKey(){
    var title;
    var message;

      title = "URL Message Key Delete";
      message =
        "Are you sure, you want to remove URL Message key";
    const confirmDialog = this.matDialog.open(ConfirmDialogBoxComponent, {
      data: {
        title: title,
        message: message,
      },
    });
    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.authService.deleteMsgKey().subscribe(
          (response: any) => {
            // //console.log(response);

            this.matDialog.open(SuccessComponent, {
              width: "542px",
              data: { message: response.comment },
            });

            this.viewMsgKey();
          },
          (error: any) => {
            console.log(error);
          }
        );
      }
    });
  }
  openSnackBar() {
    this._snackBar.openFromComponent(SnackBarComponent, {
      duration: 1 * 1000,
    });
  }

  isAfterNow(date) {
    return new Date(date).valueOf() > new Date().valueOf();
  }
}

@Component({
  selector: "snack-bar-component-example-snack",
  templateUrl: "./snack-bar-component-dashboard.html",
  styles: [
    `
      /* .snack-word {
      color: white;
    } */
    `,
  ],
})
export class SnackBarComponent {}
@Component({
  selector: "user-dashboard-reload-popup-dialog",
  templateUrl: "./payment-modal.component.html",
  styleUrls: ["./payment-modal.component.css"],
})
export class UserDashBoardReloadPopupDialogComponent implements OnInit {
  selectedPaymentOption = null;
  form: FormGroup;
  isLoading = false;
  paymentStatus = false;
  ngOnInit() {
    if (this.data.mobile == null) {
      this.closeDialog();
    }
    this.form = new FormGroup({
      amount: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(11),
          Validators.pattern("^[0-9]*$"),
        ],
      }),
      relaodType: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1),
          Validators.pattern("^[0-9]*$"),
        ],
      }),
    });
  }
  constructor(
    public dialogRef: MatDialogRef<UserDashBoardReloadPopupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private campaignService: CampaignService,
    private router: Router,
    private matDialog: MatDialog
  ) {
    dialogRef.disableClose = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close({ event: "close", data: this.paymentStatus });
  }
  ezCashOption() {
    this.selectedPaymentOption = 1;
    this.form.patchValue({ relaodType: 1 });
    this.form.get("relaodType").updateValueAndValidity();
  }
  addToBillOption() {
    this.selectedPaymentOption = 2;
    this.form.patchValue({ relaodType: 2 });
    this.form.get("relaodType").updateValueAndValidity();
  }
  initiateReload(formDirective: FormGroupDirective) {
    this.paymentStatus = false;
    if (
      this.form.invalid ||
      (this.selectedPaymentOption != 1 && this.selectedPaymentOption != 2)
    ) {
      return;
    }
    this.isLoading = true;
    //user id is handled in backend
    let default_user_id = 0;
    //0 stands for campaign payment 1 for topup
    let type = 1;
    //campign value is handled in backend
    //topup value
    let default_val = this.form.value.amount;
    this.campaignService
      .initiateTopUp(
        default_user_id,
        this.form.value.relaodType,
        type,
        default_val
      )
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          //console.log(response);
          //how capture success response

          //reset payment status to true
          this.paymentStatus = true;
          this.closeDialog();
          this.matDialog.open(SuccessDialogBoxComponent, {
            width: "532px",
            data: { message: response.comment },
          });
        },
        (error) => {
          this.isLoading = false;
          this.closeDialog();
          //console.log(error);
        }
      );
  }
}
