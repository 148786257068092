<!-- <section class="container-fluid e-body-wrapper pt-5">
  <div class="row">
    <div class="col-md-6 offset-md-3 col-lg-4 offset-lg-4">
      <div class="e-card text-center">
        <form
          [formGroup]="form"
          (submit)="onVerifyPin(formDirective)"
          #formDirective="ngForm"
        >
          <div class="e-card__header mb-4 row">
            <div class="col-2">
              <button
                mat-icon-button
                aria-label="Back icon"
                [routerLink]="['/login']"
              >
                <span class="material-icons">
                  arrow_back
                </span>
              </button>
            </div>
            <h4 class="col-8 e-page-title">Please enter your mobile number</h4>
          </div>
          <mat-form-field appearance="outline" class="e-input">
            <mat-label>Mobile number</mat-label>
            <input
              matInput
              matInput
              type="text"
              name="mobile"
              #input
              formControlName="mobile"
              maxlength="9"
            />
            <mat-hint align="end">{{ input.value?.length || 0 }}/9</mat-hint>
            <mat-hint
              >eg: 771234567 (Without the Leading Zero and should have 9
              digits)</mat-hint
            >
            <mat-error *ngIf="form.get('mobile').hasError('required')"
              >Mobile can't be null</mat-error
            >
            <mat-error
              *ngIf="
                form.get('mobile').hasError('minlength') ||
                form.get('mobile').hasError('maxlength')
              "
              >Mobile must contain 9 digits</mat-error
            >
            <mat-error *ngIf="form.get('mobile').hasError('pattern')"
              >Mobile can only have digits</mat-error
            >
          </mat-form-field>
          <button
            mat-flat-button
            color="primary"
            class="e-btn mt-2"
            type="submit"
          >
            NEXT
          </button>
        </form>
      </div>
    </div>
  </div>
</section> -->

<section class="e-body-wrapper pt-5 pb-5">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 offset-md-3 col-lg-4 offset-lg-4">
        <div class="e-card text-center">
          <form
            [formGroup]="form"
            (submit)="onVerifyPin(formDirective)"
            #formDirective="ngForm"
          >
            <div class="e-card__header mb-4 row">
              <div class="col-2">
                <button
                  mat-icon-button
                  aria-label="Back icon"
                  [routerLink]="['/login']"
                >
                  <span class="material-icons">
                    arrow_back
                  </span>
                </button>
              </div>
              <h4 class="col-8 e-page-title">
                Please enter your mobile number
              </h4>
            </div>
            <mat-form-field appearance="outline" class="e-input mb-3">
              <mat-label>Mobile number</mat-label>
              <input
                matInput
                matInput
                type="text"
                name="mobile"
                #input
                formControlName="mobile"
                maxlength="11"
              />
              <!-- <mat-hint align="end">{{ input.value?.length || 0 }}/9</mat-hint>
              <mat-hint
                >eg: 771234567 (Without the Leading Zero and should have 9
                digits)</mat-hint
              > -->
              <mat-error *ngIf="form.get('mobile').hasError('required')"
                >Mobile can't be null</mat-error
              >
              <mat-error
                *ngIf="
                  form.get('mobile').hasError('minlength') ||
                  form.get('mobile').hasError('maxlength')
                "
                >Mobile must contain 9 digits</mat-error
              >
              <mat-error *ngIf="form.get('mobile').hasError('pattern')"
                >Invalid Mobile format</mat-error
              >
            </mat-form-field>
            <button
              mat-flat-button
              color="primary"
              class="e-btn mt-2"
              type="submit"
            >
              NEXT
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
