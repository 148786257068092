<form
  [formGroup]="form"
  (submit)="addNumber(formDirective)"
  #formDirective="ngForm"
  autocomplete="off"
>
  <div class="container-fluid e-modal">
    <button
      mat-mini-fab
      color="primary"
      class="e-btn-modal-close"
      type="button"
    >
      <mat-icon class="e-btn-modal-close__icon" (click)="closeDialog()"
        >close</mat-icon
      >
    </button>
    <div class="row mt-3">
      <div class="col-12 e-modal-row-1">
        <span class="material-icons e-status-syb__stop-icon">info</span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 e-modal-row-1 mt-4">
        <p>Type the new number</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center mt-5 mb-3">
        <mat-form-field appearance="outline" class="e-input">
          <mat-label>Mobile</mat-label>
          <input
            matInput
            type="text"
            formControlName="mobile"
            class="textbox"
            maxlength="11"
          />
          <mat-error *ngIf="form.get('mobile').hasError('required')"
            >Mobile number can't be null</mat-error
          >
          <mat-error
            *ngIf="
              form.get('mobile').hasError('minlength') ||
              form.get('mobile').hasError('maxlength')
            "
            >Mobile number should contain 9 digits</mat-error
          >
          <mat-error *ngIf="form.get('mobile').hasError('pattern')"
            >Illegal characters in the Mobile Number</mat-error
          >
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="e-modal-footer mb-1" mat-dialog-actions>
    <button
      mat-stroked-button
      class="e-btn-stroke e-btn-stroke--colored-txt mr-2"
      (click)="closeDialog()"
      type="button"
    >
      NO
    </button>
    <button
      mat-flat-button
      color="primary"
      class="e-btn e-btn--small"
      type="submit"
      [disabled]="disableYes"
    >
      YES
    </button>
  </div>
</form>
