import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import * as XLSX from "xlsx";
import { MatSort } from "@angular/material/sort";
import { CampaignService } from "../../campaign.service";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-bulk-campaign",
  templateUrl: "./bulk-campaign.component.html",
  styleUrls: ["./bulk-campaign.component.css"],
})
export class BulkCampaignComponent implements OnInit {
  campaignType: any = "ALL";
  constructor() {}
  ngOnInit() {}

  tabChange(tabChangeEvent: MatTabChangeEvent) {
    if (tabChangeEvent.tab.textLabel == "ALL") {
      this.campaignType = "ALL";
    } else if (tabChangeEvent.tab.textLabel == "PENDING") {
      this.campaignType = 0;
    } else if (tabChangeEvent.tab.textLabel == "RUNNING") {
      this.campaignType = 1;
    } else if (tabChangeEvent.tab.textLabel == "COMPLETED") {
      this.campaignType = 2;
    } else if (tabChangeEvent.tab.textLabel == "DELETED") {
      this.campaignType = 3;
    } else if (tabChangeEvent.tab.textLabel == "MODIFIED") {
      this.campaignType = 4;
    } else if (tabChangeEvent.tab.textLabel == "PAUSED") {
      this.campaignType = 5;
    } else if (tabChangeEvent.tab.textLabel == "EXPIRED") {
      this.campaignType = 6;
    } else if (tabChangeEvent.tab.textLabel == "TWO PHASE LOCKED") {
      this.campaignType = 7;
    } else if (tabChangeEvent.tab.textLabel == "STOPPED") {
      this.campaignType = 8;
    } else if (tabChangeEvent.tab.textLabel == "WAITING FOR PAYMENT") {
      this.campaignType = 9;
    } else if (tabChangeEvent.tab.textLabel == "PAYMENT FAILED") {
      this.campaignType = 11;
    } else if (tabChangeEvent.tab.textLabel == "WAITING FOR EzCash") {
      this.campaignType = 12;
    } else if (tabChangeEvent.tab.textLabel == "DRAFT") {
      this.campaignType = "DRAFT";
    }
  }
}

export interface CampaignList {
  campaign_cost: number;
  campaign_messages: number;
  campaign_name: string;
  campaign_ready_status: number;
  campaign_type: number;
  created_time: string;
  creator_id: number;
  expiry_time: string;
  id: number;
  message_id: string;
  schedule_time: string;
  source_address: string;
  status: number;
  text: string;
  transaction_id: string;
}
