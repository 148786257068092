<app-secondary-header></app-secondary-header>
<div class="container">
  <div class="row">
    <div class="col-12" style="display: flex; justify-content: center;">
      <mat-radio-group [(ngModel)]="reportType" class="e-radio">
        <mat-radio-button [value]="1">Daily Report</mat-radio-button>
        <mat-radio-button [value]="2">Monthly Report</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>
<mat-tab-group class="e-body-wrapper" mat-align-tabs="center" (selectedTabChange)="tabChangeMain($event)">
  <mat-tab label="Bulk">
    <app-daily-bulk-summary-report *ngIf="tabType === 1 && reportType === 1"
      [displayedColumnsTwo]="displayedColumnsMaskWiseBulkDaily" [campaignType]="5"></app-daily-bulk-summary-report>
    <app-mask-usage-report *ngIf="tabType === 1 && reportType === 2" [displayedColumnsTwo]="displayedColumnsBulkDaily"
      [campaignType]="1"></app-mask-usage-report>
  </mat-tab>
  <mat-tab label="Personalized">
    <app-daily-bulk-summary-report *ngIf="tabType === 2 && reportType === 1"
      [displayedColumnsTwo]="displayedColumnsMaskWisePersonalizedDaily" [campaignType]="6"></app-daily-bulk-summary-report>
    <app-mask-usage-report *ngIf="tabType === 2  && reportType === 2" [displayedColumnsTwo]="displayedColumnsPersonalizedDaily"
      [campaignType]="2"></app-mask-usage-report>
  </mat-tab>
  <mat-tab label="Targeting">
    <app-daily-bulk-summary-report *ngIf="tabType === 3 && reportType === 1"
      [displayedColumnsTwo]="displayedColumnsMaskWiseTargetingDaily" [campaignType]="7"></app-daily-bulk-summary-report>
    <app-mask-usage-report *ngIf="tabType === 3 && reportType === 2" [displayedColumnsTwo]="displayedColumnsTargetingDaily"
      [campaignType]="3"></app-mask-usage-report>
  </mat-tab>
  <mat-tab label="Package">
    <app-daily-bulk-summary-report *ngIf="tabType === 4 && reportType === 1"
      [displayedColumnsTwo]="displayedColumnsMaskWisePackageDaily" [campaignType]="8"></app-daily-bulk-summary-report>
    <app-mask-usage-report *ngIf="tabType === 4 && reportType === 2" [displayedColumnsTwo]="displayedColumnsPackageDaily"
      [campaignType]="4"></app-mask-usage-report>
  </mat-tab>
</mat-tab-group>
