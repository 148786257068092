<app-landing-header></app-landing-header>
<section class="e-main-banner">
  <div class="container">
    <div class="row">
      <div class="col-md-7 e-main-banner__txt pt-5 pl-4">
        <!-- <h1 class="e-main-txt mb-3">Pricing</h1> -->
        <!-- <h2 class="e-sub-header mb-3">Transactional SMS</h2> -->
        <h1 class="e-main-txt mb-3">Transactional SMS</h1>
        <P class="e-p-txt"
          >Messages sent to the customers to pass on information necessary for
          using a product or service. These messages are delivered to DND (Do
          Not Disturb) numbers as well.</P
        >
        <p>
          Ex: A message sent to the client by a company regarding his Invoice
          amount.
        </p>
        <div class="row">
          <ul class="offset-md-1 col-12 col-md-11 e-sec-list px-5 px-md-0">
            <li>40 Cents +Tax for Dialog per SMS</li>
            <li>50 Cents +Tax for Non Dialog per SMS</li>
          </ul>
        </div>

        <h1 class="e-main-txt mb-3">Promotional SMS</h1>
        <P class="e-p-txt"
          >Messages sent with the objective of promoting a product or service.
          Includes any sales & marketing messages which may or may not be
          solicited by the recipient. SMSs that have offers, coupons etc.</P
        >
        <p>
          Ex: Download & register the ABC Mobile Banking app. Stand a chance to
          WIN a SAMSUNG GALAXY NOTE 8. Download now via www.ABC. T&C Apply
        </p>
        <div class="row">
          <ul class="offset-md-1 col-12 col-md-11 e-sec-list px-5 px-md-0">
            <li>40 Cents +Tax for Dialog per SMS</li>
            <li>50 Cents +Tax for Non Dialog per SMS</li>
            <li>25 Cents for per SMS (SMS Levy)</li>
          </ul>
        </div>
      </div>
      <div class="col-md-5 e-main-banner__img-wrapper">
        <img
          src="../../../assets/images/banner.png"
          class="e-home-img e-main-banner__img"
        />
      </div>
    </div>
  </div>
</section>
