<section class="e-body-wrapper py-5">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-8">
        <div class="e-card e-card--no-paddings mb-4 pb-2">
          <h4 class="pt-4 mb-3 text-center e-page-title">Available masks</h4>
          <mat-spinner *ngIf="isLoading"></mat-spinner>
          <ng-container *ngIf="!isLoading">
            <div class="e-card--table">
              <table mat-table [dataSource]="dataSource" #table matSort>
                <!-- Position Column -->
                <ng-container matColumnDef="position">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>No.</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.position }}
                  </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Name
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Status
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.status }}
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
            </div>

            <mat-paginator
            [pageSizeOptions]="[5, 10, 20]"
            showFirstLastButtons="false"
          ></mat-paginator>
          </ng-container>
        </div>
      </div>
      <div class="col-12 col-md-4 mt-4 mt-sm-0">
        <mat-spinner *ngIf="isLoadingSecond"></mat-spinner>
        <form
          [formGroup]="form"
          (submit)="onRequestNewMask(formDirective)"
          #formDirective="ngForm"
          *ngIf="!isLoadingSecond"
          autocomplete="off"
        >
          <div class="e-card e-card-campaign-right mb-4">
            <div class="e-card-campaign-right__sec-txt">
              Request new masks
            </div>
            <div class="mt-3 mb-3">
              <mat-form-field appearance="outline" class="e-input">
                <mat-label>New mask *</mat-label>
                <input
                  matInput
                  type="text"
                  name="mask"
                  formControlName="mask"
                  maxlength="11"
                  #userMask
                  ngModel
                />
                <mat-hint align="end"
                  >{{ userMask.value?.length || 0 }}/11</mat-hint
                >
                <mat-error *ngIf="form.get('mask').hasError('required')"
                  >Mask can't be null</mat-error
                >
                <mat-error *ngIf="form.get('mask').hasError('minlength')"
                  >Mask should contain atleast 3 characters</mat-error
                >
                <mat-error *ngIf="form.get('mask').hasError('pattern')"
                  >Mask can only have leters,numbers and
                  spaces</mat-error
                >
                <mat-error *ngIf="form.get('mask').hasError('maxlength')"
                  >Mask can only have 11 characters</mat-error
                >
              </mat-form-field>
              <mat-form-field appearance="outline" class="e-input">
                <mat-label>Purpose for requesting*</mat-label>
                <textarea
                  matInput
                  type="text"
                  name="message"
                  formControlName="message"
                  minlength="3"
                  maxlength="150"
                  #userMaskPurpose
                  ngModel
                ></textarea>
                <mat-hint align="end"
                  >{{ userMaskPurpose.value?.length || 0 }}/150</mat-hint
                >
                <mat-error *ngIf="form.get('message').hasError('required')"
                  >This can't be null</mat-error
                >
                <mat-error *ngIf="form.get('message').hasError('minlength')"
                  >This should contain atleast 3 characters</mat-error
                >
                <mat-error *ngIf="form.get('message').hasError('pattern')"
                  >Illegal characters found.</mat-error
                >
                <mat-error *ngIf="form.get('message').hasError('maxlength')"
                  >This can only have maximum of 150 characters</mat-error
                >
              </mat-form-field>
            </div>
            <button
              mat-flat-button
              color="primary"
              class="e-btn mt-2 mb-1"
              type="submit"
              [disabled]="
                form.get('message').value == '' ||
                form.get('mask').value == '' ||
                form.invalid
              "
            >
              REQUEST
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
