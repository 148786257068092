<section class="e-body-wrapper">
  <div class="container">
    <div class="row pt-4 pb-2 e-body-header">
      <button mat-button class="e-back-btn" routerLink="/contact/list">
        <span class="material-icons e-back-btn__icon">arrow_back</span>
        BACK
      </button>
      <h4 class="e-page-title">Contact List ID: {{ this.contactList?.id }}</h4>
      <button mat-flat-button color="primary" class="e-btn mt-2 mb-3" (click)="addNewNumberOpenModal()"
        [disabled]="isLoading && !isAllPaginatorChanged && !isSearchPaginatorChanged">
        Add Number
      </button>
      <button color="primary" mat-flat-button class="e-btn mt-2 mb-3" (click)="removeNewNumberOpenModal()"
        [disabled]="isLoading && !isAllPaginatorChanged && !isSearchPaginatorChanged">
        Remove Number
      </button>
    </div>
    <div class="row">
      <div class="col-12 col-md-8">
        <div class="e-card">
          <div *ngIf="isLoading && !isAllPaginatorChanged && !isSearchPaginatorChanged"
            class="col-12 d-flex justify-content-center align-items-center">
            <mat-spinner></mat-spinner>
          </div>
          <div *ngIf="!isLoading || isAllPaginatorChanged || isSearchPaginatorChanged" class="row mb-2">
            <div class="col-6 col-md-6">
              <div class="e-inp-view">
                <div class="e-inp-view__lbl">Contact List Name</div>
                <div class="e-inp-view__val">
                  {{ this.contactList?.name }}
                </div>
              </div>
            </div>
            <div class="col-6 col-md-6">
              <div class="e-inp-view__lbl">Created time</div>
              <div class="e-inp-view__val">
                {{
                this.contactList?.created | date: "yyyy-MM-dd HH:mm":"+0000"
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mt-4 mt-sm-0">
        <div class="e-card e-card-campaign-right mb-4">
          <div class="spinner-grow text-primary" role="status"
            *ngIf="isLoading && !isAllPaginatorChanged && !isSearchPaginatorChanged">
            <span class="sr-only">Loading...</span>
          </div>
          <div *ngIf="!isLoading || isAllPaginatorChanged || isSearchPaginatorChanged">
            <div class="e-card-campaign-right__sec-txt">Number Count</div>
            <div class="e-card-campaign-right__primary-txt e-card-campaign-right__primary-txt--colored mt-3">
              {{ this.contactList?.count }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="this.contactList?.status == 0">
    <div class="alert alert-danger" role="alert">
      Numbers processing in progress!
    </div>
  </div>
  <div *ngIf="this.contactList?.status != 0">
    <div *ngIf="!isLoading || isAllPaginatorChanged || isSearchPaginatorChanged" class="navbar-light bg-light">
      <nav class="container">
        <div class="e-sec-header navbar navbar-expand-lg navbar-light bg-light">
          <ul class="navbar-nav mr-auto e-sec-header-nav">
            <li class="nav-item active">
              <button class="e-sec-header-nav__item" [ngClass]="{'e-sec-header-nav__item--active': isClickContactList }"
                mat-button (click)="headerClick(true)">
                All Contacts
              </button>
            </li>
            <li class="nav-item">
              <button class="e-sec-header-nav__item"
                [ngClass]="{'e-sec-header-nav__item--active': !isClickContactList }" mat-button
                (click)="headerClick(false)">
                Search
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <section class="pt-5 pb-5">
      <div class="container">
        <div class="row">
          <div class="col-12 e-card e-card--no-paddings">
            <span *ngIf="isClickContactList">
              <section>
                <div #TABLE>
                  <ng-container>
                    <div class="e-card--table">
                      <table mat-table matSort [dataSource]="dataSourceOne" #TableOneSort="matSort">
                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="select">
                          <th mat-header-cell *matHeaderCellDef style="width: 2%;">
                            <div class="row">
                              <div class="col">
                                <mat-checkbox color="primary"
                                  (change)="$event ? toggleAllRows(dataSourceOne, selectionOne) : null"
                                  [checked]="isAllSelected(dataSourceOne, selectionOne)">
                                </mat-checkbox>
                              </div>
                              <div class="col">
                                <a (click)="removeNewNumberModalInListView(selectionOne)" matTooltip="Delete"
                                  [matTooltipPosition]="'below'" matTooltipHideDelay="200"
                                  [class.disabled]="selectionOne.length>0 ? null: true">
                                  <mat-icon>delete</mat-icon>
                                </a>
                              </div>
                            </div>
                          </th>
                          <td mat-cell *matCellDef="let row">
                            <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                              (change)="$event ? selectionChange(row, $event, selectionOne) : null"
                              [checked]="selectionChecked(row,selectionOne)">
                            </mat-checkbox>
                          </td>
                        </ng-container>
                        <!-- Campaign Column -->
                        <ng-container matColumnDef="mobile_number">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Mobile.
                          </th>
                          <td mat-cell *matCellDef="let element">
                            {{ element.mobile_number }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="disclaimer">
                          <td mat-footer-cell *matFooterCellDef colspan="2">
                            Selected Count: {{selectionOne.length}}
                          </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumnsOne"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsOne"></tr>
                        <tr mat-footer-row *matFooterRowDef="['disclaimer']"></tr>
                      </table>
                      <div *ngIf="isLoading || isAllPaginatorChanged"
                        class="col-12 d-flex justify-content-center align-items-center">
                        <mat-spinner></mat-spinner>
                      </div>
                    </div>
                    <mat-paginator #TableOnePaginator="matPaginator" [length]="this.totalPostsOne"
                      [showFirstLastButtons]="false" [pageSizeOptions]="pageSizeOptionOne" [pageSize]="postsPerPageOne"
                      (page)="onChangedAllPage($event)"></mat-paginator>
                  </ng-container>
                </div>
              </section>
            </span>
            <span *ngIf="!isClickContactList">
              <div class="container-fluid e-filter-section e-filter-section--paddings-x2">
                <div class="row">
                  <div class="col-12 col-md-10">
                    <h4 class="pt-4 pb-2 e-page-title">Filter</h4>
                  </div>
                </div>
                <form [formGroup]="form" (submit)="applySearch(formDirective)" #formDirective="ngForm">
                  <div style="padding: 10px"></div>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <mat-form-field appearance="outline">
                        <mat-label>Search</mat-label>
                        <input matInput name="searchParamObject" formControlName="searchParam"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          maxlength="11" />
                        <mat-error *ngIf="form.get('searchParam').hasError('required')">Search can't be empty
                        </mat-error>
                        <mat-error *ngIf="
                        form.get('searchParam').hasError('minlength') ||
                        form.get('searchParam').hasError('maxlength')
                      ">Searching mobile must contain 11 to 9 digits</mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-12 col-md-4">
                      <div class="row">
                        <div class="col-4 pt-2">
                          <button mat-flat-button color="primary" class="e-btn ml-2">
                            VIEW
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <hr />
              </div>
              <section>
                <div #TABLE>
                  <ng-container>
                    <div class="e-card--table">
                      <table mat-table matSort [dataSource]="dataSourceTwo" #TableTwoSort="matSort" *ngIf="!isRefresh">
                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="select">
                          <th mat-header-cell *matHeaderCellDef style="width: 2%;">
                            <div class="row">
                              <div class="col">
                                <mat-checkbox color="primary"
                                  (change)="$event ? toggleAllRows(dataSourceTwo, selectionTwo) : null"
                                  [checked]="isAllSelected(dataSourceTwo, selectionTwo)">
                                </mat-checkbox>
                              </div>
                              <div class="col">
                                <a (click)="removeNewNumberModalInListView(selectionTwo)" matTooltip="Delete"
                                  [matTooltipPosition]="'below'" matTooltipHideDelay="200"
                                  [class.disabled]="selectionTwo.length>0 ? null: true">
                                  <mat-icon>delete</mat-icon>
                                </a>
                              </div>
                            </div>
                          </th>
                          <td mat-cell *matCellDef="let row">
                            <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                              (change)="$event ? selectionChange(row, $event, selectionTwo) : null"
                              [checked]="selectionChecked(row,selectionTwo)">
                            </mat-checkbox>
                          </td>
                        </ng-container>
                        <!-- Campaign Column -->
                        <ng-container matColumnDef="mobile_number">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Mobile.
                          </th>
                          <td mat-cell *matCellDef="let element">
                            {{ element.mobile_number }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="disclaimer">
                          <td mat-footer-cell *matFooterCellDef colspan="2">
                            Selected Count: {{selectionTwo.length}}
                          </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumnsTwo"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsTwo"></tr>
                        <tr mat-footer-row *matFooterRowDef="['disclaimer']"></tr>
                      </table>
                      <div *ngIf="isLoading || isSearchPaginatorChanged"
                        class="col-12 d-flex justify-content-center align-items-center">
                        <mat-spinner></mat-spinner>
                      </div>
                    </div>
                    <mat-paginator *ngIf="!isRefresh" #TableTwoPaginator="matPaginator" [length]="this.totalPostsTwo"
                      [showFirstLastButtons]="false" [pageSizeOptions]="pageSizeOptionTwo" [pageSize]="postsPerPageTwo"
                      (page)="onChangedSearchPage($event)"></mat-paginator>
                  </ng-container>
                </div>
              </section>
            </span>
          </div>
        </div>
      </div>
    </section>
  </div>
</section>
