<section class="e-body-wrapper pb-5">
  <div class="container e-body-wrapper">
    <div class="row pt-4 pb-2">
      <button mat-button class="e-btn e-back-btn" routerLink="/contact/list">
        <span class="material-icons e-back-btn__icon"> arrow_back </span>BACK
      </button>
    </div>
    <div class="row">
      <div class="offset-md-1 col-md-10 e-card">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <form *ngIf="!isLoading" (submit)="createCampaign()" [formGroup]="form" #campaignFormDirective="ngForm">
          <h4 class="mb-4 e-page-title text-center">Add New Contact List</h4>
          <div class="row">
            <div class="col-6">
              <mat-form-field appearance="outline" class="e-input">
                <mat-label>List name</mat-label>
                <input matInput type="text" formControlName="contactListName" maxlength="44" />
                <mat-error *ngIf="form.get('contactListName').hasError('required')">Campaign name can't be
                  null</mat-error>
                <mat-error *ngIf="form.get('contactListName').hasError('minlength')">Campaign name contain atleast 3
                  characters</mat-error>
                <mat-error *ngIf="form.get('contactListName').hasError('pattern')">Illegal characters in the Campaign
                  name</mat-error>
              </mat-form-field>
            </div>

            <div class="col-6"></div>
          </div>
          <div class="row">
            <div class="col-6">
              <mat-form-field appearance="outline" class="e-input">
                <mat-label>Phone numbers</mat-label>
                <input matInput type="text" placeholder="Comma seperated 9 digit numbers. Ex:76######1,77#####2"
                  formControlName="msisdns" />
                <mat-hint>Ex: 7XXXXXXXX, 7XXXXXXXX</mat-hint>
                <mat-error *ngIf="form.get('msisdns').hasError('pattern')">9 Digit Numbers Only.Use comma to seperate
                  numbers</mat-error>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field appearance="outline" class="e-f-inp">
                <mat-label>Number file</mat-label>
                <input matInput type="text" name="" readonly formControlName="fileName" class="e-f-inp" />
                <mat-hint *ngIf="
                    (!form.get('fileSelect').valid && this.file_select) ||
                    (!form.get('fileName').valid &&
                      form.get('fileName').touched)
                  " style="color: red">Not a csv or excel and maximum file size is 2MB</mat-hint>
                <mat-hint *ngIf="
                    !(!form.get('fileSelect').valid && this.file_select) &&
                    !(
                      !form.get('fileName').valid &&
                      form.get('fileName').touched
                    )
                  ">Only supports CSV and Excel</mat-hint>
                <button mat-button type="button" (click)="filePicker.click()" class="e-f-inp__select">
                  UPLOAD
                </button>

                <input type="file" style="display: none" #filePicker (change)="onExcelPicked($event)" />
              </mat-form-field>
            </div>
          </div>

          <div class="row e-btn-group e-btn-group--center mt-2">
            <button mat-stroked-button class="e-btn-stroke mr-2" type="button" [routerLink]="['/campaign/list']">
              CANCEL
            </button>
            <button mat-flat-button color="primary" class="e-btn ml-2" type="submit">
              CREATE
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
