<div class="e-filter-section">
  <div class="container e-filter-section--paddings-x2">
    <div class="row">
      <div class="col-12 col-md-10">
        <h4 class="pt-4 pb-2 e-page-title">Filter messages</h4>
      </div>
    </div>
    <form [formGroup]="form" (submit)="applySearch(formDirective)" #formDirective="ngForm">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-3">
          <mat-form-field appearance="outline">
            <mat-label>Year</mat-label>
            <mat-select formControlName="year" (selectionChange)="yearChanged($event)" name="yearObjet">
              <mat-option *ngFor="let type of years" [value]="type.value">
                {{type.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('year').hasError('required')">You must make a selection</mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <mat-form-field appearance="outline">
            <mat-label>Month</mat-label>
            <mat-select formControlName="month" name="monthObjet">
              <mat-option *ngFor="let type of months" [value]="type.value">
                {{type.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('month').hasError('required')">You must make a selection</mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-12 col-lg-3 mb-3 mb-lg-0">
          <div class="row">
            <div class="col-6 col-md-4 pt-2 e-fm-md-rl">
              <button mat-flat-button color="primary" class="e-btn ml-2" [disabled]="isLoading">
                VIEW
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<section class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="e-card e-card--no-paddings">
          <div class="row">
            <div class="col-12">
            </div>
            <div class="col-12" style="display: flex; justify-content: end;">
              <div class="col-1">
                <button aria-label="filter columns btn" class="e-btn-mini-fab" (click)="select.open()">
                  <mat-icon>tune</mat-icon>
                  <mat-select #select [formControl]="filterColumns" multiple>
                    <mat-select-trigger>
                    </mat-select-trigger>
                    <mat-option #columnSelected *ngFor="let filterColumn of displayedColumnsTwo"
                      (click)="toggleColumnSelection(columnSelected)" [value]="filterColumn.key">
                      {{filterColumn.value}}</mat-option>
                  </mat-select>
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="isLoading" class="col-12 d-flex justify-content-center align-items-center">
            <mat-spinner></mat-spinner>
          </div>
          <div>
            <ng-container>
              <div class="e-card--table">
                <table mat-table matSort [dataSource]="dataSourceTwo" #TableTwoSort="matSort">
                  <ng-container [matColumnDef]="column?.key" *ngFor="let column of displayedColumnsTwo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{column?.value}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element[column.key] }}
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="columnsToDisplayTwo"></tr>
                  <tr mat-row *matRowDef="let row; columns: columnsToDisplayTwo"></tr>
                </table>
              </div>
              <mat-paginator *ngIf="!isRefresh" #TableTwoPaginator="matPaginator" [length]="this.totalPostsTwo"
                [showFirstLastButtons]="false" [pageSizeOptions]="pageSizeOptionTwo" [pageSize]="postsPerPageTwo"
                (page)="onChangedPageTwo($event)"></mat-paginator>
              <div class="col-12">
                <button class="btn btn-primary"
                  [disabled]="isExportingExcel || dataSourceTwo?.filteredData?.length == 0 || !dataSourceTwo?.filteredData || columnsToDisplayTwo?.length == 0"
                  (click)="ExportTOExcel()">
                  <span><i class="fa fa-download pr-3"></i><b>Export as
                      Excel</b></span>&nbsp;<i class="fa fa-spinner fa-spin" *ngIf="this.isExportingExcel"></i>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
