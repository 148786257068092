import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CampaignService } from '../campaign/campaign.service';

export interface DialogData {
  short_code: string;
  message_id: string;
  message: string;
  msg_status: string;
}

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.css']
})
export class InboxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

@Component({
  selector: "read-message-popup-dialog",
  templateUrl: "./read-message-dialog.component.html",
  styleUrls: ["./read-message-dialog.component.css"],
})
export class ReadMessagePopupDialogComponent implements OnInit {
  onSubmitRequest = new EventEmitter();
  form: FormGroup;
  isReadMessage = false;
  isRequesting = false;
  ngOnInit() {
    if (this.data.message_id == null) {
      this.closeDialog();
    }else if (this.data.msg_status == '1'){
      this.isReadMessage = false;
      // this.readUnreadMessage('0');
    }else if(this.data.msg_status == '0'){
      this.isReadMessage = true;
    }
  }
  constructor(
    public dialogRef: MatDialogRef<ReadMessagePopupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private campaignService: CampaignService,
    private router: Router,
    private matDialog: MatDialog
  ) {
    dialogRef.disableClose = true;
  }


  closeDialog() {
    this.dialogRef.close();
  }

  readUnreadMessage(status: string){
    this.isRequesting = true;
    this.campaignService
      .markReadUnreadMessage(
        this.data.short_code,
        this.data.message_id,
        status,
      )
      .subscribe(
        (response: any) => {
          if(response.status == "success"){
            if(status=='0'){
              let data = { status : response.status, comment: response.comment};
              this.onSubmitRequest.emit(data);
              this.isReadMessage = true;
              this.closeDialog();
            }else if(status=='1'){
              let data = { status : response.status, comment: response.comment};
              this.onSubmitRequest.emit(data);
              this.isReadMessage = false;
              this.closeDialog();
            }
          }
          this.isRequesting = false;
        },
        (error) => {
          this.isRequesting = false;
          console.log(error);
        }
      );
  }
}

