<div class="col-md-12 col-12 targeting-fields" [formGroup]="form">
  <div class="col-12 mb-4" style="padding: 0 !important;">
    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Rates
          </mat-panel-title>
          <mat-panel-description>
            click to view rates {{rateDescription}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <table mat-table [dataSource]="dataSource" class="mat-table">

          <!-- up to 100K Column -->
          <ng-container matColumnDef="_100000">
            <th mat-header-cell *matHeaderCellDef> up to 100K </th>
            <td mat-cell *matCellDef="let element"> {{element?._100000}} </td>
          </ng-container>

          <!-- 100K - 500K Column -->
          <ng-container matColumnDef="_500000">
            <th mat-header-cell *matHeaderCellDef> 100K - 500K </th>
            <td mat-cell *matCellDef="let element"> {{element?._500000}} </td>
          </ng-container>

          <!-- 100K -1Mn Column -->
          <ng-container matColumnDef="_1000000">
            <th mat-header-cell *matHeaderCellDef> 500K -1Mn </th>
            <td mat-cell *matCellDef="let element"> {{element?._1000000}} </td>
          </ng-container>

          <!-- above 1 Mn Column -->
          <ng-container matColumnDef="_1000001">
            <th mat-header-cell *matHeaderCellDef> above 1 Mn </th>
            <td mat-cell *matCellDef="let element"> {{element?._1000001}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </mat-expansion-panel>
      </mat-accordion>
  </div>
  <mat-error *ngIf="form.hasError('atLeastOneChecked')">At least one should be
    checked</mat-error>
  <div *ngIf="form.get('age')" class="targeting-group row" [formGroup]="form.get('age')">
    <div class="col-md-3 check-container">
      <mat-checkbox color="primary" class="targeting-field-margin" formControlName="checked"
        (change)="onAgeChecked($event.checked)">Age
      </mat-checkbox>
    </div>
    <div class="col-md-6">
      <ngx-slider [options]="ageOptions" formControlName="value"></ngx-slider>
    </div>
  </div>
  <div *ngIf="form.get('gender')" class="targeting-group row" [formGroup]="form.get('gender')">
    <div class="col-md-3 check-container">
      <mat-checkbox color="primary" class="targeting-field-margin" formControlName="checked"
        (change)="onGenderChecked($event.checked)">Gender
      </mat-checkbox>
    </div>
    <div class="col-md-9">
      <mat-form-field appearance="outline" class="e-select">
        <mat-label>Select Gender</mat-label>
        <mat-select formControlName="value" multiple>
          <mat-option *ngFor="let gender of genderList" [value]="gender.value">{{gender.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error *ngIf="form.get('gender').hasError('required')">gender can't be
        empty
      </mat-error>
    </div>
  </div>
  <div *ngIf="form.get('language')" class="targeting-group row" [formGroup]="form.get('language')">
    <div class="col-md-3 check-container">
      <mat-checkbox color="primary" class="targeting-field-margin" formControlName="checked"
        (change)="onLanguageChecked($event.checked)">Language
      </mat-checkbox>
    </div>
    <div class="col-md-9">
      <mat-form-field appearance="outline" class="e-select">
        <mat-label>Select Language</mat-label>
        <mat-select formControlName="value" multiple>
          <mat-option *ngFor="let language of languagesList" [value]="language.value">{{language.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error *ngIf="form.get('language').hasError('required')">language can't be
        empty
      </mat-error>
    </div>
  </div>
  <div *ngIf="form.get('device_type')" class="targeting-group row" [formGroup]="form.get('device_type')">
    <div class="col-md-3 check-container">
      <mat-checkbox color="primary" class="targeting-field-margin" formControlName="checked"
        (change)="onDeviceTypeChecked($event.checked)">Device Type
      </mat-checkbox>
    </div>
    <div class="col-md-9">
      <mat-form-field appearance="outline" class="e-select">
        <mat-label>Select Device Type</mat-label>
        <mat-select formControlName="value" multiple>
          <mat-option *ngFor="let deviceType of deviceTypeList" [value]="deviceType.value">{{deviceType.label}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error *ngIf="form.get('device_type').hasError('required')">device type
        can't be empty
      </mat-error>
    </div>
  </div>
  <div *ngIf="form.get('location')" class="targeting-group row" [formGroup]="form.get('location')">
    <div class="col-md-3 check-container">
      <mat-checkbox color="primary" class="targeting-field-margin" formControlName="checked"
        (change)="onLocationChecked($event.checked)">Location
      </mat-checkbox>
    </div>
    <div class="col-md-9">
      <mat-error *ngIf="form.get('location').hasError('locationAtLeastOneChecked')">
        At least
        one should be
        checked</mat-error>
      <div class="targeting-sub-group row" [formGroup]="form.get('location').get('workLocation')">
        <div class="col-md-12 check-container">
          <mat-checkbox color="primary" class="targeting-field-margin" formControlName="checked"
            (change)="onWorkLocationChecked($event.checked)">Work
            Location
          </mat-checkbox>
        </div>
        <div class="col-md-12">
          <mat-error *ngIf="form.get('location').get('workLocation').hasError('locationAtLeastOneRequired')">
            At least one should be
            required</mat-error>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <app-chip-autocomplete-tageted #workshopDistricts [form]="form.get('location').get('workLocation')"
            [formName]="'district'" [label]="'District'" [placeholder]="'Select Districts'" [allChips]="allWorkDistricts">
          </app-chip-autocomplete-tageted>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <app-chip-autocomplete-tageted #workshopCities [form]="form.get('location').get('workLocation')"
            [formName]="'city'" [label]="'City'" [placeholder]="'Select Cities'" [allChips]="allWorkCities">
          </app-chip-autocomplete-tageted>
        </div>
      </div>
      <hr>
      <div class="targeting-sub-group row" [formGroup]="form.get('location').get('homeLocation')">
        <div class="col-md-12 check-container">
          <mat-checkbox color="primary" class="targeting-field-margin" formControlName="checked"
            (change)="onHomeLocationChecked($event.checked)">Home
            Location
          </mat-checkbox>
        </div>
        <div class="col-md-12">
          <mat-error *ngIf="form.get('location').get('homeLocation').hasError('locationAtLeastOneRequired')">
            At least one should be
            required</mat-error>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <app-chip-autocomplete-tageted #homeDistricts [form]="form.get('location').get('homeLocation')"
            [formName]="'district'" [label]="'District'" [placeholder]="'Select Districts'" [allChips]="allHomeDistricts">
          </app-chip-autocomplete-tageted>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <app-chip-autocomplete-tageted #homeCities [form]="form.get('location').get('homeLocation')"
            [formName]="'city'" [label]="'City'" [placeholder]="'Select Cities'" [allChips]="allHomeCities">
          </app-chip-autocomplete-tageted>
        </div>
      </div>
      <hr>
      <div class="targeting-sub-group row" [formGroup]="form.get('location').get('billingLocation')">
        <div class="col-md-12 check-container">
          <mat-checkbox color="primary" class="targeting-field-margin" formControlName="checked"
            (change)="onBillingLocationChecked($event.checked)">Billing
            Location
          </mat-checkbox>
        </div>
        <div class="col-md-12">
          <mat-error *ngIf="form.get('location').get('billingLocation').hasError('locationAtLeastOneRequired')">
            At least one should be
            required</mat-error>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <app-chip-autocomplete-tageted #billingDistricts [form]="form.get('location').get('billingLocation')"
            [formName]="'district'" [label]="'District'" [placeholder]="'Select Districts'" [allChips]="allBillingDistricts">
          </app-chip-autocomplete-tageted>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="form.get('arpu')" class="targeting-group row" [formGroup]="form.get('arpu')">
    <div class="col-md-3 check-container">
      <mat-checkbox color="primary" class="targeting-field-margin" formControlName="checked"
        (change)="onArpuChecked($event.checked)">ARPU
      </mat-checkbox>
    </div>
    <div class="col-md-6">
      <ngx-slider [options]="arpuOptions" formControlName="value"></ngx-slider>
    </div>
  </div>
</div>
