import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormGroupDirective,
} from "@angular/forms";
import { passValidator } from "../../auth/signup/confirm-password.validator";
import { AuthService } from "src/app/auth/auth.service";
import { Router } from "@angular/router";
@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup;
  counterTime = 0;
  show;
  confirmShow;
  constructor(private router: Router, public authService: AuthService) {}

  ngOnInit(): void {
    this.show = false;
    this.confirmShow = false;
    const now = new Date();
    if (
      !localStorage.getItem("changePasswordToken") ||
      !localStorage.getItem("changePasswordExpirationDate")
    ) {
      this.router.navigate(["/"]);
    }
    const expireTime = localStorage.getItem("changePasswordExpirationDate");
    const expiresIn = new Date(expireTime).getTime() - now.getTime();

    this.counterTime = expiresIn / 1000;
    if (expiresIn < 0) {
      this.router.navigate(["/"]);
    }
    this.form = new FormGroup({
      password: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.pattern(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,50}$/
          ),
        ],
      }),
      //^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$

      cpassword: new FormControl(null, {
        validators: [Validators.required, passValidator],
      }),
    });
  }
  resetPassword() {
    this.form.get("cpassword").reset();
  }
  onAddNewPassword(formDirective: FormGroupDirective) {
    if (
      this.form.invalid ||
      this.form.get("password").value != this.form.get("cpassword").value
    ) {
      return;
    }
    this.authService.updatePassword(this.form.get("password").value);
  }
  passwordShow() {
    this.show = !this.show;
  }
  confirmPasswordShow() {
    this.confirmShow = !this.confirmShow;
  }
}
