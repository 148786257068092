<div *ngIf="isLoading" class="col-12 d-flex justify-content-center align-items-center" style="min-height: 50vh;">
  <mat-spinner></mat-spinner>
</div>
<section>
<div class="e-card--table">
  <table mat-table matSort [dataSource]="dataSourceOne" #TableOneSort="matSort">
    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">{{element.id}}</td>
    </ng-container>
    <!-- Template Name Column -->
    <ng-container matColumnDef="template_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name.</th>
      <td mat-cell *matCellDef="let element">
        {{ element.template_name }}
      </td>
    </ng-container>
    <!-- Message Column -->
    <ng-container matColumnDef="message">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Message.</th>
      <td mat-cell *matCellDef="let element">
        <div [innerHTML]="formatContent(element.message)"></div>
      </td>
    </ng-container>
    <!-- Status Column -->
    <!-- <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status.</th>
      <td mat-cell *matCellDef="let element">
        {{ fetchedTemplateStatus(element.status) }}
      </td>
    </ng-container> -->
    <!-- Created Date Column -->
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Created.
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.createdAt | date: "yyyy-MM-dd HH:mm":"+0000" }}
      </td>
    </ng-container>
    <!-- Updated Date Column -->
    <ng-container matColumnDef="updatedAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Updated.
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.updatedAt | date: "yyyy-MM-dd HH:mm":"+0000" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="creator_id">
      <th mat-header-cell *matHeaderCellDef>View</th>
      <td mat-cell *matCellDef="let element">
        <a [routerLink]="['/template/view-list', element.id]">
          <mat-icon>visibility</mat-icon>
        </a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsOne"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsOne"></tr>
  </table>
</div>
<mat-paginator #TableOnePaginator="matPaginator" [length]="this.totalPosts" [showFirstLastButtons]="false"
    [pageSizeOptions]="pageSizeOption" [pageSize]="postsPerPage" (page)="onChangedPage($event)"></mat-paginator>
</section>
