<section class="e-body-wrapper pb-5">
  <div class="container e-body-wrapper">
    <div class="row pt-4 pb-2">
      <button mat-button class="e-back-btn" [routerLink]="['/campaign/view', this.campaign?.id]">
        <span class="material-icons e-back-btn__icon"> arrow_back </span>BACK
      </button>
    </div>
    <div class="row">
      <div class="offset-md-1 col-md-10 e-card">
        <div class="spinner-grow text-primary" role="status" *ngIf="isLoading">
          <span class="sr-only">Loading...</span>
        </div>
        <form *ngIf="!isLoading" (submit)="updateCampaign()" [formGroup]="form" #campaignFormDirective="ngForm">
          <h4 class="mb-4 e-page-title text-center">
            Edit Campaign ID: {{ this.campaign.id }}
          </h4>

          <div class="row">
            <div class="col-6">
              <mat-form-field appearance="outline" class="e-select">
                <mat-label>Mask</mat-label>

                <input matInput type="text" formControlName="sourceAddress" maxlength="44" readonly />
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field appearance="outline" class="e-input">
                <mat-label>Campaigne name</mat-label>
                <input matInput type="text" formControlName="campaignName" maxlength="44" readonly />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <mat-form-field appearance="outline" (click)="date_picker.open()">
                <mat-label>Schedule Date</mat-label>
                <input matInput [matDatepicker]="date_picker" maxlength="10" minlength="8"
                  formControlName="scheduleDate" readonly [min]="minDate" [max]="maxDate" [disabled]="this.form.value.scheduleNow == true"/>

                <mat-datepicker-toggle matSuffix [for]="date_picker"></mat-datepicker-toggle>
                <mat-datepicker #date_picker></mat-datepicker>

                <mat-error *ngIf="
                    form.get('scheduleDate').hasError('minLength') ||
                    form.get('scheduleDate').hasError('maxLength') ||
                    form.get('scheduleDate').hasError('required')
                  ">Invalid Date</mat-error>
                <mat-error *ngIf="
                    form.get('scheduleDate').hasError('matDatepickerMin') ||
                    form.get('scheduleDate').hasError('matDatepickerMax')
                  ">
                  Date is in past or not with in 7 days from today
                </mat-error>
              </mat-form-field>
            </div>
            <!-- min="06:00 am"
                  max="08:00 pm" -->
            <div class="col-6">
              <mat-form-field appearance="outline" class="e-time-inp">
                <mat-label>Schedule Time</mat-label>
                <input matInput type="text" [ngxTimepicker]="sch_time_picker" readonly #scheduleTimeUI
                  formControlName="scheduleTime" [disabled]="this.form.value.scheduleNow == true"/>
                <ngx-material-timepicker #sch_time_picker [enableKeyboardInput]="true"></ngx-material-timepicker>
                <mat-icon (click)="scheduleTimeUI.click()" matSuffix>access_time</mat-icon>
                <mat-error *ngIf="
                    form.get('scheduleTime').hasError('minLength') ||
                    form.get('scheduleTime').hasError('maxLength') ||
                    form.get('scheduleTime').hasError('required') ||
                    form.get('scheduleTime').hasError('pattern')
                  ">Invalid Time</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12 e-cb-wrapper mb-4" style="display: flex; justify-content: end;">
              <mat-checkbox
                class="e-cb-wrapper__cb"
                formControlName="scheduleNow"
                (change)="scheduleNowChange()"
                >Schedule Now!</mat-checkbox
              >
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex justify-content-center">
              <h4 class="pt-3 pb-2 e-page-title mb-2">Message </h4>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-5 d-flex justify-content-center">
              <div class="text-area pt-3 pb-3 pl-3" [innerHTML]="formatTemplatedMessage(campaign.text)">
              </div>
            </div>
          </div>
          <div class="row e-btn-group e-btn-group--center mt-2">
            <button mat-stroked-button class="e-btn-stroke mr-2" type="button"
              [routerLink]="['/campaign/view', this.campaign?.id]">
              CANCEL
            </button>
            <button mat-flat-button color="primary" class="e-btn ml-2" type="submit">
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
