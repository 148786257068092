import { FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export const leastOneCheckedValidator: ValidatorFn = (
  controlGroup: FormGroup
): ValidationErrors | null => {
  let controls = controlGroup.controls;
  let theOne = Object.keys(controls).find(
    (key) => controls[key]?.value?.checked == true && !controls[key]?.disabled
  );
  if (!theOne) {
    return { atLeastOneChecked: true };
  } else {
    return null;
  }
};

export const locationLeastOneCheckedValidator: ValidatorFn = (
  controlGroup: FormGroup
): ValidationErrors | null => {
  let controls = controlGroup.controls;
  let theOne = Object.keys(controls).find(
    (key) => controls[key]?.value?.checked == true && !controls[key]?.disabled
  );
  if (!theOne && controlGroup.controls?.checked?.value) {
    return { locationAtLeastOneChecked: true };
  } else {
    return null;
  }
};

export const locationLeastOneRequiredValidator: ValidatorFn = (
  controlGroup: FormGroup
): ValidationErrors | null => {
  if (
    controlGroup.controls?.checked?.value &&
    (!controlGroup.controls?.district?.value ||
      controlGroup.controls?.district?.value.length == 0) &&
    (!controlGroup.controls?.city?.value ||
      controlGroup.controls?.city?.value.length == 0)
  ) {
    return { locationAtLeastOneRequired: true };
  } else {
    return null;
  }
};

export const requiredValidator: ValidatorFn = (
  controlGroup: FormGroup
): ValidationErrors | null => {
  if (
    controlGroup.controls?.checked?.value == true &&
    (controlGroup.controls?.value?.value?.length == 0 ||
      !controlGroup.controls?.value?.value)
  ) {
    return { required: true };
  } else {
    return null;
  }
};
