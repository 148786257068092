import { OnInit, Component } from "@angular/core";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { AuthService } from "src/app/auth/auth.service";

@Component({
  selector: "app-campaign-list",
  templateUrl: "./campaign-list.component.html",
  styleUrls: ["./campaign-list.component.css"],
})
export class CampaignListComponent implements OnInit {
  tabType = 1;
  isEligibleForTargetingCampaigns;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.isEligibleForTargetingCampaigns = this.authService.getEligibilityForTargetingCampaign();
  }

  tabChangeMain(tabChangeEvent: MatTabChangeEvent) {
    if (tabChangeEvent.tab.textLabel == "Bulk SMS") {
      this.tabType = 1;
    } else if (tabChangeEvent.tab.textLabel == "Personalized SMS") {
      this.tabType = 2;
    } else if (tabChangeEvent.tab.textLabel == "Targeted SMS") {
      this.tabType = 3;
    }
  }
}
