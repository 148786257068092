import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { Router } from "@angular/router";

import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { SuccessComponent } from "./success/success.component";
@Injectable({
  providedIn: "root",
})
export class MatDialogService {
  dialogRef;
  constructor(private matDialog: MatDialog) {}
  createDialog(comment: string, width: string) {
    this.dialogRef = this.matDialog.open(SuccessComponent, {
      width: width,
      data: { message: comment },
    });
    // this.dialogRef.afterClosed().subscribe((result) => {
    //   console.log("The dialog was closed");
    // });
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
