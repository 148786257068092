<div [formGroup]="form">
  <mat-form-field appearance="outline" class="e-select">
    <mat-label>{{label}}</mat-label>
    <mat-chip-list #chipGrid [formControlName]="formName">
      <mat-chip *ngFor="let chip of chips" (removed)="remove(chip)" [removable]="true" [selectable]="true">
        {{chip}}
          <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
    <input placeholder="{{placeholder}}" #chipInput [matChipInputFor]="chipGrid"
      [matAutocomplete]="auto" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [formControl]="chipCtrl"/>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let chip of filteredChips | async" [value]="chip">
        {{chip}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
