<section class="e-body-wrapper pb-5">
  <div class="container">
    <div class="row pt-4 pb-2 e-body-header e-body-header--rem-jc">
      <div class="col-4">
        <button
          mat-button
          class="e-btn e-back-btn"
          routerLink="/user/dashboard"
        >
          <span class="material-icons e-back-btn__icon">arrow_back</span>
          BACK
        </button>
      </div>
      <h4 class="e-page-title col-4 text-center">Your Notifications</h4>
    </div>
    <div class="spinner-grow text-primary" role="status" *ngIf="isLoading">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="row" *ngIf="!isLoading">
      <div class="offset-md-2 col-12 col-md-8">
        <div
          class="e-card e-card--no-paddings e-ntf-card pb-4"
          style="overflow-y: scroll; overflow-x: hidden; max-height: 450px"
        >
          <div class="e-ntf-card__header">
            <span class="e-ntf-card__header-txt"
              ><a (click)="marAllAsRead()">Mark all as read</a></span
            >
          </div>
          <ng-container *ngIf="this.account">
            <ng-container
              *ngFor="let item of this.account?.notification; let i = index"
            >
              <ng-container *ngIf="item.status == 1">
                <div
                  class="e-ntf-card__inner-wrapper"
                  (click)="
                    openDialog(
                      i,
                      item.id,
                      item.created_time,
                      item.creator_id,
                      item.formal_message,
                      item.msg_type,
                      item.status,
                      item.subject,
                      item.url,
                      item.user_id
                    )
                  "
                >
                  <div class="e-ntf-card__txt">
                    <div class="e-menu__sec-txt">
                      {{ item.created_time | date: "yyyy-MM-dd HH:mm":"+0530" }}
                    </div>
                    <div class="e-menu__primary-txt mt-1">
                      {{ item.formal_message }}
                    </div>
                  </div>
                  <div class="e-ntf-card__circle"></div>
                </div>
              </ng-container>
              <ng-container *ngIf="item.status == 0">
                <div
                  class="e-ntf-card__inner-wrapper e-ntf-card__inner-wrapper--new"
                  (click)="
                    openDialog(
                      i,
                      item.id,
                      item.created_time,
                      item.creator_id,
                      item.formal_message,
                      item.msg_type,
                      item.status,
                      item.subject,
                      item.url,
                      item.user_id
                    )
                  "
                >
                  <div class="e-ntf-card__txt">
                    <div class="e-menu__sec-txt">
                      {{ item.created_time | date: "yyyy-MM-dd HH:mm":"+0530" }}
                    </div>
                    <div class="e-menu__primary-txt mt-1">
                      {{ item.formal_message }}
                    </div>
                  </div>
                  <div class="e-ntf-card__circle"></div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>
