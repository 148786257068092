<section class="container-fluid e-body-wrapper pt-5">
  <div class="row">
    <div class="col-md-6 offset-md-3 col-lg-4 offset-lg-4">
      <div class="e-card text-center">
        <h2 class="mb-4 e-page-title">Verify Your Mobile</h2>

        <h3>We will send an 6 digit pin number to your mobile</h3>
        <h4>
          0{{ this.mobile.substr(0, 2) }} - {{ this.mobile.substr(2, 3) }} -
          {{ this.mobile.substr(5, 2) }}{{ this.mobile.substr(7, 1)
          }}{{ this.mobile.substr(8) }}
          <!-- {{ this.mobile }} -->
        </h4>
        <form (submit)="onGetPin()" #getRegPinForm="ngForm">
          <button
            mat-flat-button
            color="primary"
            type="submit"
            class="e-btn mt-4"
          >
            Get Pin
          </button>
        </form>
      </div>
    </div>
  </div>
</section>
