<section class="e-body-wrapper pb-5">
  <mat-spinner *ngIf="isLoading"></mat-spinner>
  <div class="container">
    <div class="row pt-4 pb-2 e-body-header">
      <button mat-button class="e-back-btn" (click)="onBackPressed()">
        <span class="material-icons e-back-btn__icon">arrow_back</span>
        BACK
      </button>
      <h4 class="e-page-title">Campaign ID: {{ this.campaign?.id }}</h4>
      <button *ngIf="!(isLoading && !isAllPaginatorChanged && !isSearchPaginatorChanged)"
        [routerLink]="['/campaign/edit', this.campaign?.id]" mat-flat-button [color]="
          !(this.campaign.status == '9' || this.campaign.status == '11')
            ? 'default'
            : 'primary'
        " class="e-btn mt-2 mb-3" [disabled]="
          !(this.campaign.status == '9' || this.campaign.status == '11') || (isLoading && !isAllPaginatorChanged && !isSearchPaginatorChanged)
        ">
        EDIT CAMPAIGN
      </button>
      <button *ngIf="!(isLoading && !isAllPaginatorChanged && !isSearchPaginatorChanged)" (click)="campaignStop()"
        mat-flat-button [color]="
          !(this.campaign.status == '0' || this.campaign.status == '1')
            ? 'default'
            : 'primary'
        " class="e-btn mt-2 mb-3" [disabled]="
          !(this.campaign.status == '0' || this.campaign.status == '1') || (isLoading && !isAllPaginatorChanged && !isSearchPaginatorChanged)
        ">
        STOP CAMPAIGN
      </button>
    </div>
    <div class="row">
      <div class="col-12 col-md-8">
        <div class="e-card">
          <div *ngIf="isLoading && !isAllPaginatorChanged && !isSearchPaginatorChanged"
            class="col-12 d-flex justify-content-center align-items-center">
            <mat-spinner></mat-spinner>
          </div>
          <div *ngIf="!isLoading || isAllPaginatorChanged || isSearchPaginatorChanged">
            <div class="row mb-2">
              <div class="col-6 col-md-6">
                <div class="e-inp-view">
                  <div class="e-inp-view__lbl">Campaign name</div>
                  <div class="e-inp-view__val">
                    {{ this.campaign?.campaign_name }}
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-6">
                <div class="e-inp-view">
                  <div class="e-inp-view__lbl">Mask</div>
                  <div class="e-inp-view__val">
                    {{ this.campaign?.source_address }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-6 col-md-6">
                <div class="e-inp-view">
                  <div class="e-inp-view__lbl">Created time</div>
                  <div class="e-inp-view__val">
                    {{
                    this.campaign?.created_time
                    | date: "yyyy-MM-dd HH:mm":"+0000"
                    }}
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-6">
                <div class="e-inp-view">
                  <div class="e-inp-view__lbl">Schedule time</div>
                  <div class="e-inp-view__val">
                    {{
                    this.campaign?.schedule_time
                    | date: "yyyy-MM-dd HH:mm":"+0000"
                    }}
                  </div>
                </div>
              </div>
              <!-- <div class="col-6 col-md-4">
              <div class="e-inp-view">
                <div class="e-inp-view__lbl">Expiry time</div>
                <div class="e-inp-view__val">
                  {{
                    this.campaign?.expiry_time
                      | date: "yyyy-MM-dd HH:mm a":"+0000"
                  }}
                </div>
              </div>
            </div> -->
            </div>
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="e-inp-view">
                  <div class="e-inp-view__lbl">Message</div>
                  <div class="e-inp-view__val"
                    [innerHTML]="formatTemplatedMessage(campaign.text)">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mt-4 mt-sm-0">
        <div class="e-card e-card-campaign-right mb-4">
          <div class="spinner-grow text-primary" role="status"
            *ngIf="isLoading && !isAllPaginatorChanged && !isSearchPaginatorChanged">
            <span class="sr-only">Loading...</span>
          </div>
          <div *ngIf="!isLoading || isAllPaginatorChanged || isSearchPaginatorChanged">
            <div class="e-card-campaign-right__sec-txt">Campaign status</div>
            <div class="
              e-card-campaign-right__primary-txt
              e-card-campaign-right__primary-txt--colored
              mt-3
            ">
              {{ fetchedCampaignStatus(this.campaign.status) }}
            </div>
          </div>
        </div>
        <div class="e-card e-card-campaign-right">
          <div class="spinner-grow text-primary" role="status"
            *ngIf="isLoading && !isAllPaginatorChanged && !isSearchPaginatorChanged">
            <span class="sr-only">Loading...</span>
          </div>
          <div *ngIf="!isLoading || isAllPaginatorChanged || isSearchPaginatorChanged">
            <div class="e-card-campaign-right__sec-txt">Number of receivers</div>
            <div class="e-card-campaign-right__primary-txt mt-3">
              {{ this.campaign?.campaign_messages }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="e-body-wrapper">
  <div class="container"
    *ngIf="campaign?.is_server_number_file_processing_running==1 || campaign?.is_waiting_for_server_number_file_uploads==1">
    <div class="alert alert-danger" role="alert">
      Numbers processing in progress!
    </div>
  </div>
  <div
    *ngIf="campaign?.is_server_number_file_processing_running!=1 && campaign?.is_waiting_for_server_number_file_uploads!=1">
    <div *ngIf="!isLoading || isAllPaginatorChanged || isSearchPaginatorChanged" class="navbar-light bg-light">
      <nav class="container">
        <div class="e-sec-header navbar navbar-expand-lg navbar-light bg-light">
          <ul class="navbar-nav mr-auto e-sec-header-nav">
            <li class="nav-item active">
              <button class="e-sec-header-nav__item" [ngClass]="{'e-sec-header-nav__item--active': isClickContactList }"
                mat-button (click)="headerClick(true)">
                All Contacts
              </button>
            </li>
            <li class="nav-item">
              <button class="e-sec-header-nav__item"
                [ngClass]="{'e-sec-header-nav__item--active': !isClickContactList }" mat-button
                (click)="headerClick(false)">
                Search
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <section class="pt-5 pb-5">
      <div class="container">
        <div class="row">
          <div class="col-12 e-card e-card--no-paddings">
            <span *ngIf="isClickContactList">
              <section>
                <div #TABLE>
                  <ng-container>
                    <div class="e-card--table">
                      <div *ngIf="isLoading || isAllPaginatorChanged"
                        class="col-12 d-flex justify-content-center align-items-center">
                        <mat-spinner></mat-spinner>
                      </div>
                      <table mat-table matSort [dataSource]="dataSourceOne"
                        #TableOneSort="matSort">
                        <!-- No. Column -->
                        <ng-container matColumnDef="msisdn">
                          <th class="mat-header" mat-header-cell *matHeaderCellDef style="width: 10%" mat-sort-header>
                            Mobile.
                          </th>
                          <td class="mat-data" mat-cell *matCellDef="let element">
                            {{ element.msisdn }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                          <th class="mat-header" mat-header-cell *matHeaderCellDef style="width: 15%" mat-sort-header>
                            Status
                          </th>
                          <td mat-cell *matCellDef="let element" class="mat-data e-txt-success">
                            {{ getDeliveredStatus(element.message_sent) }}
                          </td>
                        </ng-container>

                        <!-- Total Column -->
                        <ng-container matColumnDef="deliveredDATE">
                          <th class="mat-header" mat-header-cell *matHeaderCellDef style="width: 15%">
                            View
                          </th>
                          <td class="mat-data" mat-cell *matCellDef="let element">
                            <a [routerLink]="['/campaign/view', element.id]">
                              <mat-icon>visibility</mat-icon>
                            </a>
                          </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumnsOne"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsOne"></tr>
                      </table>
                    </div>
                    <mat-paginator *ngIf="!isLoading && !isAllPaginatorChanged" #TableOnePaginator="matPaginator" [length]="this.totalPostsOne"
                    [showFirstLastButtons]="false" [pageSizeOptions]="pageSizeOptionOne" [pageSize]="postsPerPageOne"
                    (page)="onChangedAllPage($event)"></mat-paginator>
                  </ng-container>
                </div>
              </section>

            </span>
            <span *ngIf="!isClickContactList">
              <div class="container-fluid e-filter-section e-filter-section--paddings-x2">
                <div class="row">
                  <div class="col-12 col-md-10">
                    <h4 class="pt-4 pb-2 e-page-title">Filter</h4>
                  </div>
                </div>
                <form [formGroup]="form" (submit)="applySearch(formDirective)" #formDirective="ngForm">
                  <div style="padding: 10px"></div>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <mat-form-field appearance="outline">
                        <mat-label>Only Show</mat-label>
                        <mat-select formControlName="messageSent" name="messageSentObjet">
                          <mat-option *ngFor="let type of messageSentTypes" [value]="type.value">
                            {{type.name}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.get('messageSent').hasError('required')">You must make a selection
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-12 col-md-4">
                      <mat-form-field appearance="outline">
                        <mat-label>Search</mat-label>
                        <input matInput name="searchParamObject" formControlName="searchParam"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          maxlength="11" />
                        <mat-error *ngIf="form.get('searchParam').hasError('required')">Search can't be empty
                        </mat-error>
                        <mat-error *ngIf="
                      form.get('searchParam').hasError('minlength') ||
                      form.get('searchParam').hasError('maxlength')
                    ">Searching mobile must contain 11 to 9 digits</mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-12 col-md-4">
                      <div class="row">
                        <div class="col-4 pt-2">
                          <button mat-flat-button color="primary" class="e-btn ml-2">
                            VIEW
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <hr />
              </div>
              <section>
                <div #TABLE>
                  <div *ngIf="isLoading" class="col-12 d-flex justify-content-center align-items-center"
                    style="min-height: 50vh;">
                    <mat-spinner></mat-spinner>
                  </div>
                  <ng-container>
                    <div class="e-card--table">
                      <div *ngIf="isLoading || isSearchPaginatorChanged"
                        class="col-12 d-flex justify-content-center align-items-center">
                        <mat-spinner></mat-spinner>
                      </div>
                      <table mat-table matSort
                        [dataSource]="dataSourceTwo" #TableTwoSort="matSort">
                        <!-- No. Column -->
                        <ng-container matColumnDef="msisdn">
                          <th class="mat-header" mat-header-cell *matHeaderCellDef style="width: 10%" mat-sort-header>
                            Mobile.
                          </th>
                          <td class="mat-data" mat-cell *matCellDef="let element">
                            {{ element.msisdn }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                          <th class="mat-header" mat-header-cell *matHeaderCellDef style="width: 15%" mat-sort-header>
                            Status
                          </th>
                          <td mat-cell *matCellDef="let element" class="mat-data e-txt-success">
                            {{ getDeliveredStatus(element.message_sent) }}
                          </td>
                        </ng-container>

                        <!-- Total Column -->
                        <ng-container matColumnDef="deliveredDATE">
                          <th class="mat-header" mat-header-cell *matHeaderCellDef style="width: 15%">
                            View
                          </th>
                          <td class="mat-data" mat-cell *matCellDef="let element">
                            <a [routerLink]="['/campaign/view', element.id]">
                              <mat-icon>visibility</mat-icon>
                            </a>
                          </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumnsTwo"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsTwo"></tr>
                      </table>
                    </div>
                    <mat-paginator *ngIf="!isRefresh" #TableTwoPaginator="matPaginator" [length]="this.totalPostsTwo"
                        [showFirstLastButtons]="false" [pageSizeOptions]="pageSizeOptionTwo" [pageSize]="postsPerPageTwo"
                        (page)="onChangedSearchPage($event)"></mat-paginator>
                  </ng-container>
                </div>
              </section>
            </span>
          </div>
        </div>
      </div>
      <div class="container pt-3 pb-3">
        <div class="col-lg-6">
          <button class="btn btn-primary" (click)="downloadExcel()"
            [disabled]="loadedDataCampaignView || dataSourceOne?.filteredData?.length == 0 || !dataSourceOne?.filteredData"><span><i
                class="fa fa-download pr-3"></i><b>Export as Excel</b></span>&nbsp;<i class="fa fa-spinner fa-spin"
              *ngIf="this.loadedDataCampaignView"></i></button>
        </div>
      </div>
    </section>
  </div>
</section>
