import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./auth/login/login.component";
import {
  SignupComponent,
  TermsConditionsDialog,
  AccountUssageDialog,
} from "./auth/signup/signup.component";
import { PinVerifyComponent } from "./auth/signup/pin-verify/pin-verify.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatRippleModule} from '@angular/material/core';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCardModule } from "@angular/material/card";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import {
  BlockCopyPasteDirective,
  CampaignCreateComponent,
  CampaignPaymentComponent,
} from "./campaign/campaign-create/campaign-create.component";
import {CampaignViewComponent, CampaignStopComponentPopUpDialog} from "./campaign/campaign-view/campaign-view.component";
import { CampaignListComponent } from "./campaign/campaign-list/campaign-list.component";
import { MatGridListModule } from "@angular/material/grid-list";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import {CustomMatPaginatorIntl} from './custom-mat-paginator-int';
import {MatPaginatorIntl} from '@angular/material/paginator';
import { MatSortModule } from "@angular/material/sort";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MatChipsModule } from "@angular/material/chips";
import { LayoutModule } from "@angular/cdk/layout";
import { MatMenuModule } from "@angular/material/menu";
import { NewMaskComponent } from "./account/new-mask/new-mask.component";
import {
  UserWalletComponent,
  UserReloadPopupComponent,
} from "./account/user-wallet/user-wallet.component";
import { UserTransactionHistoryComponent } from "./account/user-transaction-history/user-transaction-history.component";
import { AllCampaignsReportComponent } from "./campaign/reports/all-campaigns-report/all-campaigns-report.component";
import { AllCampaignsPerMobileComponent } from "./campaign/reports/all-campaigns-per-mobile/all-campaigns-per-mobile.component";
import { ForgotPasswordComponent } from "./account/forgot-password/forgot-password.component";
import { VerifyMobileComponent } from "./account/forgot-password/verify-mobile/verify-mobile.component";
import { PasswordVerifyPinComponent } from "./account/forgot-password/password-verify-pin/password-verify-pin.component";
import {
  AccountInfoComponent,
  UserAccountInfoReloadPopupDialogComponent,
} from "./account/account-info/account-info.component";
import { MatRadioModule } from "@angular/material/radio";
import { CampaignPayComponent } from "./auth/campaign-pay/campaign-pay.component";
import { PayCampaignOptionComponent } from "./auth/pay-campaign-option/pay-campaign-option.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "./auth/auth-interceptor";
import { AuthGuard } from "./auth/auth.guard";
import { ErrorInterceptor } from "./error-interceptor";
import { ErrorComponent } from "./error/error.component";
import { MatDialogModule } from "@angular/material/dialog";
import { TokenInterceptor } from "./token-interceptor";
import { GetPinComponent } from "./auth/signup/get-pin/get-pin.component";
import { CountdownModule } from "ngx-countdown";
import {
  CampaignEditComponent,
  CampaignEditPaymentComponent,
} from "./campaign/campaign-edit/campaign-edit.component";
import { DatePipe, CommonModule } from "@angular/common";
import { SuccessComponent } from "./success/success.component";
import { MatTabsModule } from "@angular/material/tabs";
import { MaterialFileInputModule } from "ngx-material-file-input";

//after ui rewamp
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatSliderModule } from "@angular/material/slider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatStepperModule } from "@angular/material/stepper";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { AccountEditComponent } from "./account/account-edit/account-edit.component";
import {
  UserDashboardComponent,
  UserDashBoardReloadPopupDialogComponent,
} from "./account/user-dashboard/user-dashboard.component";
import { LandingComponent } from "./landing/landing.component";
import {
  NotificationComponent,
  UserNotifcationPopupDialogComponent,
} from "./account/notification/notification.component";
import { ChangePasswordGetPinComponent } from "./account/change-password/change-password-get-pin/change-password-get-pin.component";
import { ChangePasswordVerifyPinComponent } from "./account/change-password/change-password-verify-pin/change-password-verify-pin.component";
import { ChangePasswordAddNewPasswordComponent } from "./account/change-password/change-password-add-new-password/change-password-add-new-password.component";
import { SuccessDialogBoxComponent } from "./pop-up-messages/success-dialog-box/success-dialog-box.component";
import { ErrorDialogBoxComponent } from "./pop-up-messages/error-dialog-box/error-dialog-box.component";
import { SecondaryHeaderComponent } from "./campaign/reports/secondary-header/secondary-header.component";
import { ListViewComponent } from "./contact-list/list-view/list-view.component";
import { CreateListComponent } from "./contact-list/create-list/create-list.component";
import {ViewListComponent,ContactListAddNumberComponent,ContactListRemoveNumberComponent} from "./contact-list/view-list/view-list.component";
import { ContactUsLandingComponent } from './contact-us-landing/contact-us-landing.component';
import { PricingLandingComponent } from './pricing-landing/pricing-landing.component';
import { LandingHeaderComponent } from './landing/landing-header/landing-header.component';
import { BulkCampaignComponent } from './campaign/campaign-list/bulk-campaign/bulk-campaign.component';
import { PersonalizeSmsComponent,CampaignPersonalizePaymentComponent } from './campaign/campaign-list/personalize-sms/personalize-sms.component';
import { CampaignSecondaryHeaderComponent } from './campaign/campaign-list/bulk-campaign/campaign-secondary-header/campaign-secondary-header.component';
import { CampaignsAllComponent } from './campaign/campaign-list/bulk-campaign/campaigns-all/campaigns-all.component';
import { CampaignsSearchComponent } from './campaign/campaign-list/bulk-campaign/campaigns-search/campaigns-search.component';
import { ContactListSecondaryHeaderComponent } from './contact-list/list-view/contact-list-secondary-header/contact-list-secondary-header.component';
import { ContactListSearchComponent } from './contact-list/list-view/contact-list-search/contact-list-search.component';
import { ContactListAllComponent } from './contact-list/list-view/contact-list-all/contact-list-all.component';
import { UserWalletSecondaryHeaderComponent } from './account/user-wallet/user-wallet-secondary-header/user-wallet-secondary-header.component';
import { UserWalletAllComponent } from './account/user-wallet/user-wallet-all/user-wallet-all.component';
import { UserWalletSearchComponent } from './account/user-wallet/user-wallet-search/user-wallet-search.component';
import { InboxComponent, ReadMessagePopupDialogComponent } from './inbox/inbox.component';
import { InboxSecondaryHeaderComponent } from './inbox/inbox-secondary-header/inbox-secondary-header.component';
import { InboxSearchComponent } from './inbox/inbox-search/inbox-search.component';
import { InboxAllComponent } from './inbox/inbox-all/inbox-all.component';
import { CreateTemplateListComponent } from './template-list/create-template-list/create-template-list.component';
import { TemplateListViewComponent } from './template-list/template-list-view/template-list-view.component';
import { ViewTemplateListComponent } from './template-list/view-template-list/view-template-list.component';
import { TemplateListAllComponent } from './template-list/template-list-view/template-list-all/template-list-all.component';
import { TemplateListSearchComponent } from './template-list/template-list-view/template-list-search/template-list-search.component';
import { TemplateListSecondaryHeaderComponent } from './template-list/template-list-view/template-list-secondary-header/template-list-secondary-header.component';
import { UserEnterpriseDashboardComponent } from './campaign/reports/user-enterprise-dashboard/user-enterprise-dashboard.component';
import { UserUsageReportComponent } from './campaign/reports/user-usage-report/user-usage-report.component';
import { ConfirmDialogBoxComponent } from "./pop-up-messages/confirm-dialog-box/confirm-dialog-box.component";
import { TargetedCampaignCreateComponent, CampaignTargetedPaymentComponent, } from './campaign/targeted-campaign-create/targeted-campaign-create.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ChipAutocompleteTagetedComponent } from './campaign/targeted-campaign-create/chip-autocomplete-tageted/chip-autocomplete-tageted.component';
import { TargetingComponent } from './campaign/targeted-campaign-create/targeting/targeting.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { DailyBulkSummaryReportComponent } from './campaign/reports/campaigns-summary-reports/daily/daily-bulk-summary-report/daily-bulk-summary-report.component';
import { MonthlyBulkSummaryReportComponent } from './campaign/reports/campaigns-summary-reports/monthly/monthly-bulk-summary-report/monthly-bulk-summary-report.component';
import { MaskUsageReportComponent } from './campaign/reports/campaigns-summary-reports/mask-usage-report/mask-usage-report.component';
import { TwoStepVerifyEnterPinComponent } from './auth/two-step-verify-enter-pin/two-step-verify-enter-pin.component';
import { HelpBannerComponent } from './help-banner/help-banner.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    PinVerifyComponent,
    HeaderComponent,
    FooterComponent,
    CampaignCreateComponent,
    BlockCopyPasteDirective,
    CampaignViewComponent,
    CampaignListComponent,
    NewMaskComponent,
    UserWalletComponent,
    UserTransactionHistoryComponent,
    AllCampaignsReportComponent,
    AllCampaignsPerMobileComponent,
    ForgotPasswordComponent,
    VerifyMobileComponent,
    PasswordVerifyPinComponent,
    AccountInfoComponent,
    CampaignPayComponent,
    PayCampaignOptionComponent,
    GetPinComponent,
    CampaignEditComponent,
    AccountEditComponent,
    CampaignPaymentComponent,
    UserReloadPopupComponent,
    UserAccountInfoReloadPopupDialogComponent,
    UserDashboardComponent,
    UserDashBoardReloadPopupDialogComponent,
    CampaignEditPaymentComponent,
    CampaignStopComponentPopUpDialog,
    LandingComponent,
    NotificationComponent,
    UserNotifcationPopupDialogComponent,
    ChangePasswordGetPinComponent,
    ChangePasswordVerifyPinComponent,
    ChangePasswordAddNewPasswordComponent,
    ConfirmDialogBoxComponent,
    SuccessDialogBoxComponent,
    ErrorDialogBoxComponent,
    SecondaryHeaderComponent,
    TermsConditionsDialog,
    AccountUssageDialog,
    ListViewComponent,
    CreateListComponent,
    ViewListComponent,
    ContactListAddNumberComponent,
    ContactListRemoveNumberComponent,
    ContactUsLandingComponent,
    PricingLandingComponent,
    LandingHeaderComponent,
    BulkCampaignComponent,
    PersonalizeSmsComponent,
    CampaignPersonalizePaymentComponent,
    CampaignSecondaryHeaderComponent,
    CampaignsAllComponent,
    CampaignsSearchComponent,
    ContactListSecondaryHeaderComponent,
    ContactListSearchComponent,
    ContactListAllComponent,
    UserWalletSecondaryHeaderComponent,
    UserWalletAllComponent,
    UserWalletSearchComponent,
    InboxComponent,
    ReadMessagePopupDialogComponent,
    InboxSecondaryHeaderComponent,
    InboxSearchComponent,
    InboxAllComponent,
    CreateTemplateListComponent,
    TemplateListViewComponent,
    ViewTemplateListComponent,
    TemplateListAllComponent,
    TemplateListSearchComponent,
    TemplateListSecondaryHeaderComponent,
    UserEnterpriseDashboardComponent,
    UserUsageReportComponent,
    TargetedCampaignCreateComponent,
    CampaignTargetedPaymentComponent,
    ChipAutocompleteTagetedComponent,
    TargetingComponent,
    TermsAndConditionsComponent,
    DailyBulkSummaryReportComponent,
    MonthlyBulkSummaryReportComponent,
    MaskUsageReportComponent,
    TwoStepVerifyEnterPinComponent,
    HelpBannerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatCardModule,
    MatInputModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatListModule,
    MatGridListModule,
    FlexLayoutModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMaterialTimepickerModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    NgbModule,
    MatChipsModule,
    LayoutModule,
    MatMenuModule,
    MatRadioModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatDialogModule,
    CountdownModule,
    MatTabsModule,
    MaterialFileInputModule,
    MatIconModule,
    ClipboardModule,
    MatRippleModule,
    NgxSliderModule,

    //after ui rewamp
    MatAutocompleteModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatSnackBarModule,
    CommonModule,
  ],
  providers: [
    DatePipe,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl }
  ],
  bootstrap: [AppComponent],
  entryComponents: [ErrorComponent, SuccessComponent],
})
export class AppModule {}
