<div class="container-fluid e-modal">
  <button
    mat-mini-fab
    color="primary"
    class="e-btn-modal-close"
    (click)="closeDialog()"
  >
    <mat-icon class="e-btn-modal-close__icon">close</mat-icon>
  </button>
  <div class="row mt-3">
    <div class="col-12 e-modal-row-1">
      <span class="e-status-syb e-status-syb--success">
        <span class="material-icons e-status-syb__icon">done</span>
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center mt-5 mb-3">
      <h4 class="e-page-title">
        {{ data.message }}
      </h4>
    </div>
  </div>
</div>
<div class="e-modal-footer mb-1" mat-dialog-actions>
  <button
    mat-flat-button
    color="primary"
    class="e-btn e-btn--small"
    (click)="closeDialog()"
  >
    OK
  </button>
</div>
