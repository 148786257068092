import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.authService.getToken();
    const refreshToken = this.authService.getRefreshToken();
    const regToken = this.authService.getRegisterToken();
    const verifyMobileToken = this.authService.getVerifyMobileToken();
    const changePasswordToken = this.authService.getChangePasswordToken();
    const addNewPasswordtoken = this.authService.getAddNewPasswordWhileLoggedToken();
    const addVerifyNewPasswordtoken = this.authService.getVeirfyAddNewPasswordWhileLoggedToken();
    const twoStepAuthToken = this.authService.getTwoStepAuthToken();
    const autoRefreshToken = req.clone({
      setHeaders: {
        RefreshToken: "Bearer " + refreshToken,
        Authorization: "Bearer " + authToken,
        RegisterToken: "Bearer " + regToken,
        VerifyMobileToken: "Bearer " + verifyMobileToken,
        ChangePasswordToken: "Bearer " + changePasswordToken,
        AddNewPasswordtoken: "Bearer " + addNewPasswordtoken,
        AddVerifyNewPasswordtoken: "Bearer " + addVerifyNewPasswordtoken,
        UserAuthV3: "Bearer " + twoStepAuthToken,
      },
    });
    return next.handle(autoRefreshToken);
  }
}
