import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import { Route } from "@angular/compiler/src/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { AuthService } from "../../auth.service";

@Component({
  selector: "app-get-pin",
  templateUrl: "./get-pin.component.html",
  styleUrls: ["./get-pin.component.css"],
})
export class GetPinComponent implements OnInit {
  mobile;
  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.mobile = this.route.snapshot.params["mobile"];
    this.route.params.subscribe((params: Params) => {
      this.mobile = this.route.snapshot.params["mobile"];
    });
    const now = new Date();
    if (
      !localStorage.getItem("regToken") ||
      !localStorage.getItem("regExpirationDate")
    ) {
      this.router.navigate(["/"]);
    }
    const expireTime = localStorage.getItem("regExpirationDate");
    const expiresIn = new Date(expireTime).getTime() - now.getTime();
    if (expiresIn < 0) {
      this.router.navigate(["/"]);
    }
  }
  onGetPin(form: NgForm) {
    if (form.invalid) {
      return;
    }
    this.authService.getPin().subscribe(
      (response) => {
        //console.log(response);
        this.router.navigate(["/signup/pin-verify"]);
      },
      (error) => {
        //console.log(error);
        this.authService.clearRegAuthData();
        this.router.navigate(["/login"]);
      }
    );
  }
}
