<section class="e-body-wrapper py-5">
  <div class="container e-body-wrapper">
    <div class="row mt-5">
      <div class="offset-md-1 col-md-10 e-card">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <form
          [formGroup]="form"
          (submit)="onUpdate(formDirective)"
          #formDirective="ngForm"
          *ngIf="!isLoading"
          autocomplete="off"
        >
          <h4 class="mb-4 e-page-title text-center">Edit account</h4>
          <div class="row">
            <div class="col-12 col-md-6">
              <mat-form-field appearance="outline" class="e-input">
                <mat-label>First name</mat-label>
                <input
                  matInput
                  required
                  type="text"
                  formControlName="fname"
                  maxlength="50"
                />
                <mat-error *ngIf="form.get('fname').hasError('required')"
                  >First name can't be null</mat-error
                >
                <mat-error *ngIf="form.get('fname').hasError('minlength')"
                  >First name should contain atleast 3 characters</mat-error
                >
                <mat-error *ngIf="form.get('fname').hasError('pattern')"
                  >Illegal characters in the First Name</mat-error
                >
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <mat-form-field appearance="outline" class="e-input">
                <mat-label>Last name</mat-label>
                <input
                  matInput
                  required
                  formControlName="lname"
                  maxlength="50"
                />
                <mat-error *ngIf="form.get('lname').hasError('required')"
                  >Last name can't be null</mat-error
                >
                <mat-error *ngIf="form.get('lname').hasError('minlength')"
                  >Last name should contain atleast 3 characters</mat-error
                >
                <mat-error *ngIf="form.get('lname').hasError('pattern')"
                  >Illegal characters in the Last Name</mat-error
                >
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <mat-form-field appearance="outline" class="e-input">
                <mat-label>Address</mat-label>
                <textarea
                  matInput
                  name="address"
                  formControlName="address"
                  maxlength="100"
                  rows="1"
                ></textarea>
                <mat-error *ngIf="form.get('address').hasError('required')"
                  >Adress can't be null</mat-error
                >
                <mat-error *ngIf="form.get('address').hasError('minlength')"
                  >Address should contain atleast 10 characters</mat-error
                >
                <mat-error *ngIf="form.get('address').hasError('pattern')"
                  >Illegal characters detected in the address field</mat-error
                >
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <mat-form-field appearance="outline" class="e-input">
                <mat-label>Email</mat-label>
                <input
                  matInput
                  type="email"
                  name="email"
                  formControlName="email"
                  maxlength="100"
                />
                <mat-error *ngIf="form.get('email').hasError('required')"
                  >Email can't be null</mat-error
                >
                <mat-error *ngIf="form.get('email').hasError('email')"
                  >Not a valid email</mat-error
                >
              </mat-form-field>
            </div>
          </div>

          <div style="padding: 10px;"></div>
          <div class="row">
            <div class="col-12">
              <mat-form-field appearance="outline" class="e-input">
                <mat-label>Remarks</mat-label>
                <input
                  matInput
                  name="remark"
                  formControlName="remark"
                  minL
                  maxlength="100"
                />
                <mat-error *ngIf="form.get('remark').hasError('required')"
                  >Remarks can't be null</mat-error
                >
                <mat-error *ngIf="form.get('remark').hasError('minlength')"
                  >Remarks should contain atleast 2 characters</mat-error
                >
                <mat-error *ngIf="form.get('remark').hasError('pattern')"
                  >Illegal characters detected in the remarks field</mat-error
                >
              </mat-form-field>
            </div>
          </div>
          <div class="row e-btn-group e-btn-group--center mt-2">
            <button
              mat-stroked-button
              class="e-btn-stroke e-btn-stroke--colored-txt mr-2"
              routerLink="/account/view-account"
            >
              DISCARD
            </button>
            <button
              mat-flat-button
              color="primary"
              class="e-btn ml-2"
              type="submit"
            >
              SAVE CHANGES
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
