import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NumberMimeType } from "../campaign-create/mime-type.validator";
import { HttpClient } from "@angular/common/http";
import { CampaignService } from "../campaign.service";
import { Router, ActivatedRoute } from "@angular/router";
import * as momentT from "moment-timezone";
import * as moment from "moment";
import { SuccessComponent } from "src/app/success/success.component";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { DialogData } from "../campaign-create/campaign-create.component";
import { AuthService } from "src/app/auth/auth.service";
@Component({
  selector: "app-campaign-edit",
  templateUrl: "./campaign-edit.component.html",
  styleUrls: ["./campaign-edit.component.css"],
})
export class CampaignEditComponent implements OnInit {
  masks = [];
  form: FormGroup;
  isLoading = true;
  messageRemLength = 160;
  numberOfMessages = 1;
  msg_default_size = 1;
  msg_multiplier = 1;
  campaign: {
    campaign_cost: Number;
    campaign_messages: Number;
    campaign_name: String;
    campaign_ready_status: Number;
    campaign_type: Number;
    created_time: String;
    creator_id: Number;
    expiry_time: String;
    id: Number;
    message_id: String;
    schedule_time: String;
    source_address: String;
    status: Number;
    text: String;
    transaction_id: String;
  };
  minDate;
  maxDate;
  date;
  constructor(
    private http: HttpClient,
    private campaignService: CampaignService,
    private router: Router,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) {
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();
    const currentDay = date.getDate();
    this.minDate = new Date(currentYear - 0, currentMonth, currentDay);
    this.maxDate = new Date(currentYear + 0, currentMonth, currentDay + 7);
  }

  ngOnInit(): void {
    //form validations
    this.form = new FormGroup({
      sourceAddress: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9 _~'&-.@]*$"),
        ],
      }),
      campaignName: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern("^[a-zA-Z0-9-.,_'\"`|:() /]*$"),
        ],
      }),

      scheduleDate: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(8),
        ],
      }),
      scheduleTime: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(8),
          Validators.pattern("^[AMamPMpm 0-9:]*$"),
        ],
      }),
      scheduleNow: new FormControl(null, {
        validators: [],
      }),
      message: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(3),
          // Validators.pattern("^[a-zA-Z0-9-.,_'\"`|:() /]*$"),
        ],
      }),
    });

    this.campaignService
      .getSingleCampaign(this.route.snapshot.params["id"])
      .subscribe(
        (response: any) => {
          if (
            response.campaign &&
            response.status == "success" &&
            (response.campaign.status == "9" ||
              response.campaign.status == "11")
          ) {
            this.campaign = {
              campaign_cost: response.campaign.campaign_cost,
              campaign_messages: response.campaign.campaign_messages,
              campaign_name: response.campaign.campaign_name,
              campaign_ready_status: response.campaign.campaign_ready_status,
              campaign_type: response.campaign.campaign_type,
              created_time: response.campaign.created_time,
              creator_id: response.campaign.creator_id,
              expiry_time: response.campaign.expiry_time,
              id: response.campaign.id,
              message_id: response.campaign.message_id,
              schedule_time: response.campaign.schedule_time,
              source_address: response.campaign.source_address,
              status: response.campaign.status,
              text: response.campaign.text.replace(/ /g, '\u00a0'),
              transaction_id: response.campaign.transaction_id,
            };

            //console.log(this.campaign);

            //setting values from db
            this.form.patchValue({
              sourceAddress: this.campaign.source_address,
            });
            this.form.patchValue({
              campaignName: this.campaign.campaign_name,
            });
            this.form.patchValue({
              message: this.campaign.text,
            });
            const newDate = momentT(this.campaign.schedule_time.toString(), [
              "YYYY-MM-DD HH:mm:ss",
            ])
              .tz("Asia/kolkata")
              .format("YYYY-MM-DD");
            const newTime = momentT(this.campaign.schedule_time.toString(), [
              "YYYY-MM-DD HH:mm:ss",
            ])
              .tz("Asia/kolkata")
              .format("HH:mm A");

            this.form.patchValue({
              scheduleDate: newDate,
            });
            this.form.patchValue({
              scheduleTime: newTime,
            });
            this.isLoading = false;
          } else {
            this.router.navigate(["/campaign/list"]);
          }
        },
        (error) => {
          console.log(error);
          this.router.navigate(["/campaign/list"]);
        }
      );
  }
  formatTemplatedMessage(msg) {
    var htmlMsg = msg
      .replaceAll(
        "#eSMS_VAR_2",
        '<img src="assets/images/col2_tag.png" id="img_col2" width="60">'
      )
      .replaceAll(
        "#eSMS_VAR_3",
        '<img src="assets/images/col3_tag.png" id="img_col3" width="60">'
      )
      .replaceAll(
        "#eSMS_VAR_4",
        '<img src="assets/images/col4_tag.png" id="img_col4" width="60">'
      )
      .replaceAll(
        "#eSMS_VAR_5",
        '<img src="assets/images/col5_tag.png" id="img_col5" width="60">'
      )
      .replaceAll(
        "#eSMS_VAR_6",
        '<img src="assets/images/col6_tag.png" id="img_col6" width="60">'
      )
      .replaceAll("\n", "<br>");

    return htmlMsg;
  }

  scheduleNowChange() {
    const newTime = momentT().tz("Asia/kolkata").format("HH:mm A");
    const newDate = momentT().tz("Asia/kolkata").format("YYYY-MM-DD");
    if (this.form.value.scheduleNow == true) {
      //schedule time
      this.form.patchValue({ scheduleTime: newTime });
      this.form.get("scheduleTime").updateValueAndValidity();
      //schedule date
      this.form.patchValue({ scheduleDate: newDate });
      this.form.get("scheduleDate").updateValueAndValidity();
    } else {
      this.form.patchValue({ scheduleTime: null });
      this.form.get("scheduleTime").updateValueAndValidity();
      this.form.patchValue({ scheduleDate: null });
      this.form.get("scheduleDate").updateValueAndValidity();
    }
  }

  updateCampaign() {
    //console.log(this.form);
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;

    this.campaignService
      .updateCampaign(
        this.campaign.id.toString(),
        this.form.value.scheduleDate,
        this.form.value.scheduleTime,
        this.campaign?.source_address.toString(),
        this.form.value.scheduleNow ? "1" : "0"
      )
      .subscribe(
        (response: any) => {
          if (response.status == "success") {
            const dialogRef = this.matDialog.open(
              CampaignEditPaymentComponent,
              {
                width: "630px",
                height: "630px",
                data: {
                  campaignId: this.campaign.id,
                  campaignCost: Number(this.campaign.campaign_cost),
                  walletBalance: Number(response.userData.walletBalance),
                  chargeableCost: this.campaign.campaign_cost,
                  campaignStatus: this.campaign.status,
                  mobile: response.userData.mobile,
                },
              }
            );
          }
          this.isLoading = false;
          //console.log(response);
        },
        (error) => {
          this.isLoading = false;
          console.log(error);
        }
      );
  }

  messageCounterUpdate() {
    if (this.form.value.message != null) {
      if (hasUnicode(this.form.value.message)) {
        this.msg_default_size = 70;
        this.setMessageLength();
      } else {
        this.msg_default_size = 160;
        this.setMessageLength();
      }
    }
  }
  //function to send message counter in the UI
  setMessageLength() {
    var msg_temp_length = this.form.value.message.length;
    var quotient = Math.floor(msg_temp_length / this.msg_default_size);
    var remainder =
      (msg_temp_length - this.msg_default_size * quotient) %
      this.msg_default_size;
    if (msg_temp_length % this.msg_default_size == 0) {
      this.messageRemLength = this.msg_default_size - remainder;
      this.numberOfMessages = quotient;
    } else {
      this.messageRemLength = this.msg_default_size - remainder;
      this.numberOfMessages = quotient + 1;
    }
    //setting number of messages to 1 when ever message box is empty
    if (this.form.value.message.length == 0) {
      this.numberOfMessages = 1;
    }
  }
}
function hasUnicode(str) {
  for (var i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 127) return true;
  }
  return false;
}
@Component({
  selector: "campiagn-edit-payment-popup-dialog",
  templateUrl: "./payment-modal.component.html",
  styleUrls: ["./payment-modal.component.css"],
})
export class CampaignEditPaymentComponent implements OnInit {
  selectedPaymentOption = null;
  paymentType;

  ngOnInit() {
    this.paymentType = this.authService.getPaymentType();
    if (this.data.campaignId == null) {
      this.closeDialog();
    }
  }
  constructor(
    public dialogRef: MatDialogRef<CampaignEditPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private campaignService: CampaignService,
    private router: Router,
    private matDialog: MatDialog,
    private authService: AuthService
  ) {
    dialogRef.disableClose = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  closeDialog() {
    this.router.navigate(["/campaign/view", this.data.campaignId]);
    this.dialogRef.close();
  }
  walletOption() {
    this.selectedPaymentOption = 0;
  }
  ezCashOption() {
    this.selectedPaymentOption = 1;
  }
  packageOption() {
    this.selectedPaymentOption = 4;
  }
  addToBillOption() {
    this.selectedPaymentOption = 2;
  }
  initiatePayment() {
    //console.log(this.selectedPaymentOption);
    if (
      this.selectedPaymentOption == null ||
      (this.selectedPaymentOption != 0 &&
        this.selectedPaymentOption != 1 &&
        this.selectedPaymentOption != 2 &&
        this.selectedPaymentOption != 4)
    ) {
      return;
    }

    //0 stands for campaign payment
    let type = 0;
    //campign value is handled in backend
    //setting defualt value to 0
    let default_val = 0;
    this.campaignService
      .initiatePayment(
        +this.data.campaignId,
        +this.selectedPaymentOption,
        +type,
        +default_val
      )
      .subscribe(
        (response: any) => {
          //console.log(response);
          this.closeDialog();
          this.router.navigate(["/campaign/view", this.data.campaignId]);
          //how capture success response
          this.matDialog.open(SuccessComponent, {
            width: "532px",
            data: { message: response.comment },
          });
        },
        (error) => {
          console.log(error);
        }
      );
  }
}
