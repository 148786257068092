import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { CampaignService } from 'src/app/campaign/campaign.service';
import * as moment from "moment";
import * as momentT from "moment-timezone";
import { SuccessComponent } from 'src/app/success/success.component';

@Component({
  selector: 'app-create-template-list',
  templateUrl: './create-template-list.component.html',
  styleUrls: ['./create-template-list.component.css']
})
export class CreateTemplateListComponent implements OnInit {

  form: FormGroup;
  isLoading = false;
  messageRemLength = 160;
  numberOfMessages = 1;
  msg_default_size = 1;
  constructor(
    private campaignService: CampaignService,
    private router: Router,
    private matDialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    //form validations
    this.form = new FormGroup({
      templateListName: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern("^[a-zA-Z0-9-.,_'\"`|:() /]*$"),
        ],
      }),
      message: new FormControl("", {
        validators: [
          Validators.required,
          Validators.minLength(3),
        ],
      }),
    });
  }

  createCampaign() {
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;
    this.campaignService
      .createTemplateList(
        this.form.value.templateListName,
        this.form.value.message,
      )
      .subscribe(
        (response: any) => {
          this.isLoading = false;

          if (response.status == "success") {
            //console.log(response);
            //navigate
            //how capture success response
            this.matDialog.open(SuccessComponent, {
              width: "542px",
              data: {
                message:
                  "New Template List Created"
              },
            });
            this.router.navigate(["/template/list"]);
          } else {
            console.log("invalid");
          }
        },
        (error) => {
          this.isLoading = false;
          console.log(error);
        }
      );
    //console.log(this.form);
  }

  messageCounterUpdate() {
    if (this.form.value.message != null) {
      if (hasUnicode(this.form.value.message)) {
        this.msg_default_size = 70;
        this.setMessageLength();
      } else {
        this.msg_default_size = 160;
        this.setMessageLength();
      }
    } else {
      this.msg_default_size = 160;
      this.setMessageLength();
    }
  }
  //function to send message counter in the UI
  setMessageLength() {
    var msg_temp_length = this.form.value.message.length;

    var quotient = Math.floor(msg_temp_length / this.msg_default_size);
    var remainder =
      (msg_temp_length - this.msg_default_size * quotient) %
      this.msg_default_size;
    if (msg_temp_length % this.msg_default_size == 0) {
      if (
        msg_temp_length == this.msg_default_size * quotient &&
        msg_temp_length != 0
      ) {
        this.messageRemLength = 0;
        this.numberOfMessages = quotient;
      } else {
        this.messageRemLength = this.msg_default_size - remainder;
        this.numberOfMessages = quotient;
      }
    } else {
      this.messageRemLength = this.msg_default_size - remainder;
      this.numberOfMessages = quotient + 1;
    }
    //setting number of messages to 1 when ever message box is empty
    if (this.form.value.message.length == 0) {
      this.numberOfMessages = 1;
    }
  }

}

function hasUnicode(str) {
  for (var i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 127) return true;
  }
  return false;
}
