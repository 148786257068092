<app-template-list-secondary-header></app-template-list-secondary-header>
<section class="pt-5 e-body-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-12 e-card e-card--no-paddings">
        <app-template-list-all></app-template-list-all>
      </div>
    </div>
  </div>
</section>
