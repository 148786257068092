import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-campaign-pay",
  templateUrl: "./campaign-pay.component.html",
  styleUrls: ["./campaign-pay.component.css"],
})
export class CampaignPayComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
