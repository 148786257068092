import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import {
  NgForm,
  FormGroup,
  FormControl,
  Validators,
  FormGroupDirective,
} from "@angular/forms";
import { CampaignService } from "src/app/campaign/campaign.service";
import { MatDialog } from "@angular/material/dialog";
import { SuccessComponent } from "src/app/success/success.component";
import { AuthService } from "src/app/auth/auth.service";
import * as momentT from "moment-timezone";
import { UserReloadPopupComponent } from "../user-wallet.component";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-user-wallet-all",
  templateUrl: "./user-wallet-all.component.html",
  styleUrls: ["./user-wallet-all.component.css"],
})
export class UserWalletAllComponent implements OnInit {
  isLoading = false;
  paymentType;

  totalPosts = 0;
  postsPerPage = 5;
  pageSizeOption = [5, 10, 25, 100];
  currentPage = 1;

  dataSourceAll;

  isLoading_bal = true;
  account;
  displayedColumns: string[] = [
    "id",
    "transaction_init_timestamp",
    "payment_method",
    "transaction_type",
    "charged_total",
    "transaction_status",
  ];
  dataSource;
  @ViewChild("TableOnePaginator", { static: false })
  tableOnePaginator: MatPaginator;
  @ViewChild("TableOneSort") tableOneSort: MatSort;

  constructor(
    private campaignService: CampaignService,
    private matDialog: MatDialog,
    private authService: AuthService,
    private datePipe: DatePipe
  ) {}
  ngOnInit() {
    this.paymentType = this.authService.getPaymentType();
    this.fetchData(this.currentPage, this.postsPerPage);
    // this.campaignService.getPaymentHistory().subscribe(
    //   (response: any) => {
    //     //console.log(response);
    //     this.dataSource = new MatTableDataSource(response.transction);
    //     this.isLoading = false;

    //     // let fromDate = new Date(this.form.value.fromDate);
    //     // let toDate = new Date(this.form.value.toDate);
    //     // let formtattedFromDate = momentT(fromDate).tz("Asia/kolkata");
    //     // let formtattedToDate = momentT(toDate).tz("Asia/kolkata");
    //     //console.log(fromDate);

    //     this.dataSource.filterPredicate = (data, filter) => {
    //       if (
    //         this.form.value.toDate &&
    //         this.form.value.fromDate &&
    //         this.form.value.keyword
    //       ) {
    //         return (
    //           momentT(data.transaction_init_timestamp).tz("Asia/kolkata") >=
    //             this.form.value.fromDate &&
    //           momentT(data.transaction_init_timestamp).tz("Asia/kolkata") <=
    //             momentT(momentT(this.form.value.toDate).add(1, "days")) &&
    //           (data.id
    //             .toString()
    //             .includes(this.form.value.keyword.trim().toLowerCase()) ||
    //             data.transaction_init_timestamp
    //               .toString()
    //               .includes(this.form.value.keyword.trim().toLowerCase()) ||
    //             this.paymentMethod(+data.payment_method)
    //               .toLowerCase()
    //               .includes(this.form.value.keyword.trim().toLowerCase()) ||
    //             this.TransactionType(+data.transaction_type)
    //               .toLowerCase()
    //               .includes(this.form.value.keyword.trim().toLowerCase()) ||
    //             data.charged_total
    //               .toString()
    //               .includes(this.form.value.keyword.trim().toLowerCase()) ||
    //             this.TransactionStatus(+data.transaction_status)
    //               .toLowerCase()
    //               .includes(this.form.value.keyword.trim().toLowerCase()))
    //         );
    //       } else if (this.form.value.toDate && this.form.value.fromDate) {
    //         return (
    //           momentT(data.transaction_init_timestamp).tz("Asia/kolkata") >=
    //             this.form.value.fromDate &&
    //           momentT(data.transaction_init_timestamp).tz("Asia/kolkata") <=
    //             momentT(momentT(this.form.value.toDate).add(1, "days"))
    //         );
    //       }
    //       return true;
    //     };
    //   },
    //   (error) => {
    //     //console.log(error);
    //   }
    // );

    //get account balance
    this.authService.getAccountDetails().subscribe(
      (response: any) => {
        this.account = response.userData;
        //console.log(this.account);
        this.isLoading_bal = false;
      },
      (error) => {
        this.isLoading_bal = false;
        //console.log(error);
      }
    );
  }

  topUP(form: NgForm) {
    if (form.invalid) {
      return;
    }
    //user id is handled in backend
    let default_user_id = 0;
    //0 stands for campaign payment 1 for topup
    let type = 1;
    //campign value is handled in backend
    //topup value
    let default_val = form.value.topup;
    this.campaignService
      .initiateTopUp(default_user_id, form.value.paymentType, type, default_val)
      .subscribe(
        (response: any) => {
          //console.log(response);
          //how capture success response
          this.matDialog.open(SuccessComponent, {
            data: { message: response.comment },
          });
        },
        (error) => {
          //console.log(error);
        }
      );
  }
  openReload() {
    this.isLoading = true;
    if (this.account != null) {
      const dialogRefOpen = this.matDialog.open(UserReloadPopupComponent, {
        width: "630px",
        height: "700px",
        data: {
          mobile: this.account.mobile,
        },
      });
      dialogRefOpen.afterClosed().subscribe(
        (result) => {
          if (result.data == true) {
            //reset table and balance
            this.campaignService.getPaymentHistory().subscribe(
              (response: any) => {
                //console.log(response);
                this.fetchData(this.currentPage, this.postsPerPage);
                this.isLoading = false;
              },
              (error) => {
                //console.log(error);
                this.isLoading = false;
              }
            );
            this.isLoading_bal = true;
            //get account balance
            this.authService.getAccountDetails().subscribe(
              (response: any) => {
                this.account = response.userData;
                //console.log(this.account);
                this.isLoading_bal = false;
              },
              (error) => {
                this.isLoading_bal = false;
                //console.log(error);
              }
            );
          } else {
            this.isLoading = false;
          }
        },
        () => {
          this.isLoading = false;
        }
      );
    }
  }
  paymentMethod(status) {
    let status_string = null;
    switch (status) {
      case 0:
        status_string = "wallet";
        break;
      case 1:
        status_string = "EZCash";
        break;
      case 2:
        status_string = "Bill";
        break;
      case 3:
        status_string = "Refund";
        break;
      case 4:
        status_string = "Admin Action";
        break;
      default:
        status_string = "null";
        break;
    }
    return status_string;
  }
  TransactionType(status) {
    let status_string = null;
    switch (status) {
      case 0:
        status_string = "Campaign";
        break;
      case 1:
        status_string = "Reload";
        break;
      case 2:
        status_string = "Refund";
        break;
      default:
        status_string = null;
        break;
    }
    return status_string;
  }
  TransactionStatus(status) {
    let status_string = null;
    switch (status) {
      case 0:
        status_string = "Pending";
        break;
      case 1:
        status_string = "Success";
        break;
      case 2:
        status_string = "Failed";
        break;
      case 3:
        status_string = "Cancel";
        break;
      case 4:
        status_string = "Error";
        break;

      default:
        status_string = null;
        break;
    }
    return status_string;
  }

  fetchData(currentPage, postsPerPage) {
    this.isLoading = true;
    this.dataSource = new MatTableDataSource(null);
    //get campaign list

    this.campaignService
      .walletAllData(currentPage, postsPerPage.toString())
      .subscribe(
        (response: any) => {
          ////////console.log(response);
          // this.totalPosts = response.count;
          ////////console.log(this.totalPosts);
          this.dataSourceAll = response?.transction||[];
          this.dataSource = new MatTableDataSource(this.dataSourceAll);
          this.dataSource.sort = this.tableOneSort;
          // this.cdr.detectChanges();
          if (this.dataSourceAll.length < this.postsPerPage) {
            this.totalPosts = this.currentPage * this.postsPerPage;
          } else {
            this.totalPosts = this.currentPage * this.postsPerPage + 1;
          }
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
        }
      );
  }

  onChangedPage(pageData: PageEvent) {
    //////console.log(pageData);
    this.currentPage = pageData.pageIndex + 1;
    this.postsPerPage = pageData.pageSize;
    this.fetchData(this.currentPage, this.postsPerPage);
  }
}
