import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/auth/auth.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormGroupDirective,
} from "@angular/forms";
import { passValidator } from "./confirm-password.validator";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { SuccessComponent } from "src/app/success/success.component";
import { SuccessDialogBoxComponent } from "src/app/pop-up-messages/success-dialog-box/success-dialog-box.component";
@Component({
  selector: "app-account-edit",
  templateUrl: "./account-edit.component.html",
  styleUrls: ["./account-edit.component.css"],
})
export class AccountEditComponent implements OnInit {
  account;
  isLoading = true;
  form;
  show;
  confirmShow;
  dialogRef;
  constructor(
    private authService: AuthService,
    private router: Router,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      fname: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern("^[a-zA-Z0-9-.,_'\"`|:() /]*$"),
        ],
      }),
      lname: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern("^[a-zA-Z0-9-.,_'\"`|:() /]*$"),
        ],
      }),
      address: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(10),
          Validators.pattern("^[a-zA-Z0-9-.,_'\"`|:()\n\r /]*$"),
        ],
      }),
      email: new FormControl(null, {
        validators: [Validators.required, Validators.email],
      }),

      remark: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(2),
          Validators.pattern("^[a-zA-Z0-9-.,_'\"`|:()\n\r /]*$"),
        ],
      }),
    });
    this.authService.getAccountDetails().subscribe(
      (response: any) => {
        this.account = response.userData;
        //console.log(this.account);
        //setting values from db
        this.form.patchValue({ fname: this.account.fname });
        this.form.patchValue({ lname: this.account.lname });
        this.form.patchValue({ address: this.account.address });
        this.form.patchValue({ email: this.account.email });
        this.isLoading = false;
      },
      (error) => {
        //console.log(error);
      }
    );
  }
  onUpdate(formDirective: FormGroupDirective) {
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;

    this.authService
      .updateAccountDetails(
        this.form.get("fname").value,
        this.form.get("lname").value,
        this.form.get("address").value,
        this.form.get("email").value,
        this.form.get("remark").value
      )
      .subscribe(
        (response: any) => {
          //console.log(response);
          this.isLoading = true;
          this.router.navigate(["/account/view-account"]);
          //how capture success response
          this.dialogRef = this.matDialog.open(SuccessDialogBoxComponent, {
            width: "542px",
            data: { message: response.comment },
          });
        },
        (error) => {
          //console.log(error);
          this.isLoading = true;
          this.router.navigate(["/account/view-account"]);
        }
      );
  }
  fetchedUserStatus(status) {
    let status_string = null;
    switch (status) {
      case 0:
        status_string = "Inactive";
        break;
      case 1:
        status_string = "Active";
        break;
      case 2:
        status_string = "Suspended";
        break;
      case 3:
        status_string = "Rejected";
        break;

      default:
        status_string = "null";
        break;
    }
    return status_string;
  }
  passwordShow() {
    this.show = !this.show;
  }
  confirmPasswordShow() {
    this.confirmShow = !this.confirmShow;
  }
  resetPassword() {
    this.form.get("cpassword").reset();
  }
}
