import { Component, Inject } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogService } from "../mat-dialog-service";
import { MatButtonModule } from "@angular/material/button";

@Component({
  templateUrl: "./success.component.html",
  styleUrls: ["./success.component.css"],
})
export class SuccessComponent {
  constructor(
    public dialogRef: MatDialogRef<SuccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { message: string }
  ) {}
  closeDialog() {
    this.dialogRef.close();
  }
}
