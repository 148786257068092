<div *ngIf="isLoading" class="col-12 d-flex justify-content-center align-items-center" style="min-height: 50vh;">
  <mat-spinner></mat-spinner>
</div>
<section>
  <div class="e-card--table">
    <table mat-table matSort [dataSource]="dataSourceOne" #TableOneSort="matSort">
      <!-- Campaign Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name.</th>
        <td mat-cell *matCellDef="let element">
          {{ element.name }}
        </td>
      </ng-container>
      <!-- ID Column -->
      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Created.
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.created | date: "yyyy-MM-dd HH:mm":"+0000" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="creator_id">
        <th mat-header-cell *matHeaderCellDef style="width: 1%;">View</th>
        <td mat-cell *matCellDef="let element">
          <a [routerLink]="['/contact/view-list', element.id]" matTooltip="View" [matTooltipPosition]="'below'"
            matTooltipHideDelay="100">
            <mat-icon>visibility</mat-icon>
          </a>
          <a matTooltip="Delete" [matTooltipPosition]="'below'" matTooltipHideDelay="100"
            (click)="removeContactList(element.id)">
            <mat-icon>delete</mat-icon>
          </a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsOne"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsOne"></tr>
    </table>
  </div>
  <mat-paginator #TableOnePaginator="matPaginator" [length]="this.totalPosts" [showFirstLastButtons]="false"
    [pageSizeOptions]="pageSizeOption" [pageSize]="postsPerPage" (page)="onChangedPage($event)"></mat-paginator>
</section>
