<app-landing-header></app-landing-header>
<section class="e-main-banner">
  <div class="container">
    <div class="row">
      <div class="col-md-7 e-main-banner__txt pt-5 pl-4">
        <h1 class="e-main-txt mb-3">Contact Us</h1>
        <h2 class="e-sub-header mb-3 my-heading">
          E-mail – esms@dialog.lk
        </h2>
        <h2 class="e-sub-header mb-3 my-heading">Hotline - 0117 100 200</h2>
      </div>
      <div class="col-md-5 e-main-banner__img-wrapper">
        <img
          src="../../../assets/images/banner.png"
          class="e-home-img e-main-banner__img"
        />
      </div>
    </div>
  </div>
</section>
