<app-secondary-header></app-secondary-header>
<div class="container-fluid e-filter-section">
  <div class="row">
    <div class="offset-md-1 col-12 col-md-10">
      <h4 class="pt-4 pb-2 e-page-title">Filter messages</h4>
    </div>
  </div>
  <form [formGroup]="form" (submit)="applySearch(formDirective)" #formDirective="ngForm">
    <div class="row">
      <div class="offset-md-1 col-12 col-md-3">
        <mat-form-field appearance="outline" class="e-input">
          <mat-label>Your mobile number</mat-label>
          <input matInput name="mobile" #input formControlName="mobile" maxlength="9" />
          <mat-hint align="end">{{ input.value?.length || 0 }}/9</mat-hint>
          <mat-hint>eg: 771234567</mat-hint>
          <mat-error *ngIf="form.get('mobile').hasError('required')">Mobile can't be null</mat-error>
          <mat-error *ngIf="
              form.get('mobile').hasError('minlength') ||
              form.get('mobile').hasError('maxlength')
            ">Mobile must contain 9 digits</mat-error>
          <mat-error *ngIf="form.get('mobile').hasError('pattern')">Mobile can only have digits</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-3">
        <mat-form-field appearance="outline">
          <mat-label>From date</mat-label>
          <input matInput [matDatepicker]="picker2" readonly formControlName="fromDate"
            [matDatepickerFilter]="dateFilterFrom" name="fromDateObject" />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-4">
        <div class="row">
          <div class="col-12 col-md-8">
            <mat-form-field appearance="outline">
              <mat-label>To date</mat-label>
              <input matInput [matDatepicker]="picker1" readonly name="toDateObject" formControlName="toDate"
                [matDatepickerFilter]="dateFilterTo" />
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-4 pt-2 pb-2">
            <button mat-flat-button color="primary" class="e-btn ml-2" [disabled]="isLoading">
              VIEW
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<section class="e-body-wrapper py-5">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="e-card e-card--no-paddings">
          <h4 class="pt-4 mb-3 text-center e-page-title">
            Total messages sent to (+94) {{ this.form?.value.mobile?this.form?.value.mobile:"_ _ _ _ _ _ _ _ _" }}
          </h4>
          <div #TABLE>
            <ng-container>
              <div class="e-card--table">
                <table mat-table matSort [dataSource]="dataSourceOne" #TableOneSort="matSort">
                  <!-- No. Column -->
                  <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%;" mat-sort-header>
                      ID.
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.id }}
                    </td>
                  </ng-container>

                  <!-- Date Column -->
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%;" mat-sort-header>
                      Campaign Name.
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.name }}
                    </td>
                  </ng-container>

                  <!-- Total Column -->
                  <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef style="width: 15%;" mat-sort-header>
                      Date
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.date | date: "yyyy-MM-dd HH:mm":"+0000" }}
                    </td>
                  </ng-container>

                  <!-- Status Column -->
                  <!-- other status classes: e-txt-pending e-txt-failed -->
                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef style="width: 15%;" mat-sort-header>
                      Status
                    </th>
                    <td mat-cell *matCellDef="let element" class="e-txt-success">
                      {{ getStatus(element.status) }}
                    </td>
                  </ng-container>

                  <!-- Total Column -->
                  <ng-container matColumnDef="deliveredDATE">
                    <th mat-header-cell *matHeaderCellDef style="width: 15%;">
                      View
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <a [routerLink]="['/campaign/view', element.id]">
                        <mat-icon>visibility</mat-icon>
                      </a>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsOne"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsOne"></tr>
                </table>
                <div *ngIf="isLoading" class="col-12 d-flex justify-content-center align-items-center"
                  style="min-height: 30vh;">
                  <mat-spinner></mat-spinner>
                </div>
              </div>
              <mat-paginator *ngIf="!isRefresh" #TableOnePaginator="matPaginator" [length]="this.totalPosts"
                [showFirstLastButtons]="false" [pageSizeOptions]="pageSizeOption" [pageSize]="postsPerPage"
                (page)="onChangedPage($event)"></mat-paginator>
              <div class="col-12">
                <button class="btn btn-primary"
                  [disabled]="isExportingExcel || dataSourceOne?.filteredData?.length == 0 || !dataSourceOne?.filteredData"
                  (click)="ExportTOExcel()">
                  <span><i class="fa fa-download pr-3"></i><b>Export as
                      Excel</b></span>&nbsp;<i class="fa fa-spinner fa-spin" *ngIf="this.isExportingExcel"></i>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
