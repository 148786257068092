import { AbstractControl } from "@angular/forms";
import { Observable, Observer, of } from "rxjs";

export function passValidator(control: AbstractControl) {
  if (control && (control.value !== null || control.value !== undefined)) {
    const cnfpassValue = control.value;

    const passControl = control.root.get("password"); // magic is this
    if (passControl) {
      const passValue = passControl.value;
      if (passValue !== cnfpassValue || passValue === "") {
        return {
          isError: true,
        };
      }
    }
  }

  return null;
}
