<section class="e-body-wrapper py-5">
  <section class="container">
    <div class="row">
      <div class="offset-md-1 col-md-10">
        <div class="e-card">
          <mat-spinner *ngIf="isLoading"></mat-spinner>
          <form
            [formGroup]="form"
            (submit)="onRegister(formDirective)"
            #formDirective="ngForm"
            *ngIf="!isLoading"
            autocomplete="off"
          >
            <h4 class="mb-4 e-page-title text-center">Signup</h4>
            <div class="row mb-3">
              <div class="col-12 col-md-6 mb-3 mb-md-0">
                <mat-form-field appearance="outline" class="e-input">
                  <mat-label>Company Name</mat-label>
                  <input
                    matInput
                    type="text"
                    formControlName="fname"
                    maxlength="50"
                  />
                  <mat-error *ngIf="form.get('fname').hasError('required')"
                    >Company name can't be null</mat-error
                  >
                  <mat-error *ngIf="form.get('fname').hasError('minlength')"
                    >Company name should contain atleast 3 characters</mat-error
                  >
                  <mat-error *ngIf="form.get('fname').hasError('pattern')"
                    >Illegal characters in the Company Name</mat-error
                  >
                </mat-form-field>
              </div>
              <div class="col-12 col-md-6">
                <mat-form-field appearance="outline" class="e-input">
                  <mat-label>Contact Person Name </mat-label>
                  <input matInput formControlName="lname" maxlength="50" />
                  <mat-error *ngIf="form.get('lname').hasError('required')"
                    >Contact Person Name can't be null</mat-error
                  >
                  <mat-error *ngIf="form.get('lname').hasError('minlength')"
                    >Contact Person Name should contain atleast 3
                    characters</mat-error
                  >
                  <mat-error *ngIf="form.get('lname').hasError('pattern')"
                    >Illegal characters in the Contact Person Name</mat-error
                  >
                </mat-form-field>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12 col-md-6 mb-3 mb-md-0">
                <mat-form-field appearance="outline" class="e-input">
                  <mat-label>Address</mat-label>

                  <textarea
                    matInput
                    name="address"
                    formControlName="address"
                    maxlength="100"
                    rows="1"
                  ></textarea>
                  <mat-error *ngIf="form.get('address').hasError('required')"
                    >Adress can't be null</mat-error
                  >
                  <mat-error *ngIf="form.get('address').hasError('minlength')"
                    >Address should contain atleast 10 characters</mat-error
                  >
                  <mat-error *ngIf="form.get('address').hasError('pattern')"
                    >Illegal characters detected in the address field</mat-error
                  >
                </mat-form-field>
              </div>
              <div class="col-12 col-md-6">
                <mat-form-field appearance="outline" class="e-input">
                  <mat-label>Email address</mat-label>
                  <input
                    matInput
                    type="email"
                    name="email"
                    formControlName="email"
                    maxlength="100"
                  />
                  <mat-error *ngIf="form.get('email').hasError('required')"
                    >Email can't be null</mat-error
                  >
                  <mat-error *ngIf="form.get('email').hasError('email')"
                    >Not a valid email</mat-error
                  >
                </mat-form-field>
              </div>
            </div>
            <div
              class="row mb-3"
              style="
                background-color: #e6faff;
                border-radius: 10px;
                justify-content: center;
                align-items: center;
              "
            >
              <div
                class="col-12 col-md-4 mb-3 mb-md-0"
                style="
                  color: #333333;
                  font-family: 'Open Sans';
                  font-size: 16px;
                  font-weight: 600;
                  letter-spacing: 0;
                  line-height: 22px;
                  margin: 10px;
                "
              >
                <div class="row">
                  This account is used for &nbsp;
                  <a
                    ><span class="material-icons" (click)="viewAcccountUssage()"
                      >info</span
                    ></a
                  >
                </div>
              </div>
              <div
                class="col-12 col-md-4 mb-3 mb-md-0"
                style="display: inline; text-align: center;"
              >
                <mat-radio-group
                  aria-label="Select an option"
                  formControlName="accountUsedFor"
                  class="e-radio"
                >
                  <mat-radio-button
                    value="1"
                    style="
                      color: #333333;
                      font-family: 'Open Sans';
                      font-size: 16px;
                      font-weight: 600;
                      letter-spacing: 0;
                      line-height: 22px;
                    "
                    >Transactions</mat-radio-button
                  >
                  &nbsp;
                  <mat-radio-button
                    style="
                      color: #333333;
                      font-family: 'Open Sans';
                      font-size: 16px;
                      font-weight: 600;
                      letter-spacing: 0;
                      line-height: 22px;
                    "
                    value="2"
                    >Promotions</mat-radio-button
                  >
                </mat-radio-group>
              </div>
              <div class="col-12 col-md-4 mb-3 mb-md-0"></div>
            </div>
            <div class="row mb-3">
              <div class="col-12 col-md-6 mb-3 mb-md-0">
                <mat-form-field appearance="outline" class="e-input">
                  <mat-label>NIC or BRC number</mat-label>
                  <input
                    matInput
                    matInput
                    type="text"
                    name="nic_or_brc"
                    formControlName="nic"
                    minlength="4"
                  />
                  <mat-error *ngIf="form.get('nic').hasError('required')"
                    >NIC or BRC can't be null</mat-error
                  >
                  <mat-error *ngIf="form.get('nic').hasError('pattern')"
                    >NIC or BRC Can only have letters and numbers</mat-error
                  >
                  <mat-error *ngIf="form.get('nic').hasError('minlength')"
                    >NIC or BRC should contain atleast 4 characters</mat-error
                  >
                </mat-form-field>
              </div>
              <div class="col-12 col-md-6">
                <mat-form-field appearance="outline" class="e-f-inp">
                  <mat-label>NIC or BRC soft copy</mat-label>
                  <input
                    matInput
                    type="text"
                    name=""
                    readonly
                    formControlName="imageName"
                  />
                  <mat-hint
                    *ngIf="
                      (!form.get('image').valid && this.file_select) ||
                      (!form.get('imageName').valid &&
                        form.get('imageName').touched)
                    "
                    style="color: red;"
                    >Not a pdf, png, jpg or jpeg and maximum file size is
                    2MB</mat-hint
                  >
                  <mat-hint
                    *ngIf="
                      !(!form.get('image').valid && this.file_select) &&
                      !(
                        !form.get('imageName').valid &&
                        form.get('imageName').touched
                      )
                    "
                    >Only .pdf, .png, .jpeg and .jpg files are allowed</mat-hint
                  >
                  <button
                    mat-stroked-button
                    type="button"
                    (click)="filePicker.click()"
                    mat-button
                    class="e-f-inp__select"
                  >
                    UPLOAD
                  </button>

                  <input
                    style="display: none;"
                    type="file"
                    #filePicker
                    (change)="onImagePicked($event)"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <mat-form-field appearance="outline" class="e-input">
                  <mat-label>Mask</mat-label>
                  <input
                    matInput
                    type="text"
                    name="mask"
                    formControlName="mask"
                    maxlength="11"
                    #userMask
                  />
                  <mat-hint align="end"
                    >{{ userMask.value?.length || 0 }}/11</mat-hint
                  >
                  <mat-error *ngIf="form.get('mask').hasError('required')"
                    >Mask can't be null</mat-error
                  >
                  <mat-error *ngIf="form.get('mask').hasError('minlength')"
                    >Mask should contain atleast 3 characters</mat-error
                  >
                  <mat-error *ngIf="form.get('mask').hasError('pattern')"
                    >Mask can only have leters,numbers and
                    spaces</mat-error
                  >
                  <mat-error *ngIf="form.get('mask').hasError('maxlength')"
                    >Mask can only have 11 characters</mat-error
                  >
                </mat-form-field>
              </div>
              <div class="col-12 col-md-4">
                <mat-form-field appearance="outline" class="e-input">
                  <mat-label>Your mobile</mat-label>
                  <input
                    matInput
                    type="text"
                    name="mobile"
                    #input
                    formControlName="mobile"
                    maxlength="11"
                  />
                  <!-- <mat-hint align="end">{{ input.value?.length || 0 }}/9</mat-hint> -->
                  <!-- <mat-hint>eg: 771234567</mat-hint> -->
                  <mat-error *ngIf="form.get('mobile').hasError('required')"
                    >Mobile can't be null</mat-error
                  >
                  <mat-error
                    *ngIf="
                      form.get('mobile').hasError('minlength') ||
                      form.get('mobile').hasError('maxlength')
                    "
                    >Mobile must contain 9 digits</mat-error
                  >
                  <mat-error *ngIf="form.get('mobile').hasError('pattern')"
                    >Invalid mobile format</mat-error
                  >
                </mat-form-field>
              </div>
              <div class="col-12 col-md-4">
                <mat-form-field appearance="outline" class="e-input">
                  <mat-label>Username</mat-label>
                  <input
                    matInput
                    type="text"
                    name="username"
                    #userNameInput
                    maxlength="20"
                    formControlName="username"
                  />

                  <mat-hint align="end"
                    >{{ userNameInput.value?.length || 0 }}/20</mat-hint
                  >
                  <mat-error *ngIf="form.get('username').hasError('required')"
                    >Username can't be null</mat-error
                  >
                  <mat-error *ngIf="form.get('username').hasError('minlength')"
                    >Username must contain atleast 3 characters</mat-error
                  >
                  <mat-error *ngIf="form.get('username').hasError('maxlength')"
                    >Username can can only have 20 characters</mat-error
                  >
                  <mat-error *ngIf="form.get('username').hasError('pattern')"
                    >Illegal characters in the username</mat-error
                  >
                </mat-form-field>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12 col-md-6 mb-3 mb-md-0">
                <mat-form-field appearance="outline" class="e-input">
                  <mat-label>Password</mat-label>
                  <input
                    [type]="show ? 'text' : 'password'"
                    matInput
                    name="password"
                    matInput
                    formControlName="password"
                    (click)="resetPassword()"
                    maxlength="50"
                  />
                  <mat-icon matSuffix (click)="passwordShow()"
                    >visibility</mat-icon
                  >
                  <mat-error *ngIf="form.get('password').hasError('pattern')"
                    >8 characters password with a lower case letter,upper case
                    letter,number and special character</mat-error
                  >
                  <mat-error *ngIf="form.get('password').hasError('required')"
                    >Password can't be null</mat-error
                  >
                </mat-form-field>
              </div>
              <div class="col-12 col-md-6">
                <mat-form-field appearance="outline" class="e-input">
                  <mat-label>Confirm password</mat-label>
                  <input
                    [type]="confirmShow ? 'text' : 'password'"
                    matInput
                    #confirm_password
                    matInput
                    formControlName="cpassword"
                    maxlength="50"
                  />
                  <mat-icon matSuffix (click)="confirmPasswordShow()"
                    >visibility</mat-icon
                  >
                  <mat-error *ngIf="form.get('cpassword').invalid"
                    >Password and confirm password fields does not
                    match</mat-error
                  >
                </mat-form-field>
              </div>
            </div>
            <div class="row e-cb-wrapper e-cb-wrapper--hor-center mb-4">
              <mat-checkbox class="e-cb-wrapper__cb" formControlName="condition"
                >I agree to
                <a
                  routerLink="/signup"
                  class="e-link e-link--underline"
                  (click)="viewTermsAndCondition()"
                  >Terms and Conditions</a
                >
              </mat-checkbox>
            </div>

            <mat-error
              class="row e-cb-wrapper e-cb-wrapper--hor-center mb-4"
              *ngIf="
                (form.get('condition').hasError('required') &&
                  this.file_select) ||
                (form.get('condition').hasError('minlength') &&
                  this.file_select)
              "
              >You need to accept Terms & Conditions</mat-error
            >
            <div class="row e-btn-group e-btn-group--center mt-2">
              <button
                mat-stroked-button
                class="e-btn-stroke e-btn-stroke--colored-txt mr-2"
                type="button"
                routerLink="/login"
              >
                CANCEL
              </button>
              <button mat-flat-button color="primary" class="e-btn ml-2">
                REGISTER
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</section>
