import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import * as moment from "moment";
import { Message } from "@angular/compiler/src/i18n/i18n_ast";
import { AppSettings } from "../config";
@Injectable({
  providedIn: "root",
})
export class CampaignService {
  hostIpWithPort = AppSettings.hostIpWithPort;

  constructor(private http: HttpClient, private router: Router) {}

  getAllMasks() {
    return this.http.get(this.hostIpWithPort + "/api/v1/mask");
  }
  createCampaign(
    sourceAddress: string,
    campaignName: string,
    msisdns: string,
    fileSelect: File,
    isOptOutRequired: string,
    scheduleDate: string,
    scheduleTime: string,
    // expiryDate: string,
    // expiryTime: string,
    message: string,
    scheduleNow: string
  ) {
    const ExpiryDateFix = new Date(scheduleDate);
    const ExpiryDateFixPlus7Days = moment(ExpiryDateFix)
      .add(7, "days")
      .format("YYYY-MM-DD HH:mm:ss");

    const postData = new FormData();
    postData.append("sourceAddress", sourceAddress);
    postData.append("campaignName", campaignName);
    postData.append("msisdns", msisdns);
    postData.append("fileSelect", fileSelect, campaignName);
    postData.append("is_opt_out_required", isOptOutRequired);
    postData.append("scheduleDate", scheduleDate);
    postData.append("scheduleTime", scheduleTime);
    postData.append("expiryDate", ExpiryDateFixPlus7Days);
    //passing shedule time as the expiry time as it is not used
    postData.append("expiryTime", scheduleTime);
    postData.append("message", message);
    postData.append("schedule_now", scheduleNow);

    return this.http.post(
      this.hostIpWithPort +
        "/api/v1/common-campaigns/create-campaign/via-file-upload",
      postData
    );
    //http://54.236.26.240:3000/api
  }

  createTargetedCampaign(
    sourceAddress: string,
    campaignName: string,
    msisdns: string,
    fileSelect: File,
    isOptOutRequired: string,
    scheduleDate: string,
    scheduleTime: string,
    // expiryDate: string,
    // expiryTime: string,
    message: string,
    scheduleNow: string,
    group: string,
    field: string,
    targetedField: any
  ) {
    const ExpiryDateFix = new Date(scheduleDate);
    const ExpiryDateFixPlus7Days = moment(ExpiryDateFix)
      .add(7, "days")
      .format("YYYY-MM-DD HH:mm:ss");

    const postData = new FormData();
    postData.append("sourceAddress", sourceAddress);
    postData.append("campaignName", campaignName);
    postData.append("msisdns", msisdns);
    postData.append("fileSelect", fileSelect, campaignName);
    postData.append("is_opt_out_required", isOptOutRequired);
    postData.append("scheduleDate", scheduleDate);
    postData.append("scheduleTime", scheduleTime);
    postData.append("expiryDate", ExpiryDateFixPlus7Days);
    //passing shedule time as the expiry time as it is not used
    postData.append("expiryTime", scheduleTime);
    postData.append("message", message);
    postData.append("schedule_now", scheduleNow);
    postData.append("group", group);
    postData.append("field", field);
    postData.append("targetedField", JSON.stringify(targetedField));

    return this.http.post(
      this.hostIpWithPort +
        "/api/v1/targeting-campaigns/create-campaign/via-file-upload",
      postData
    );
  }
  createTemplatedMsgCampaign(
    sourceAddress: string,
    campaignName: string,
    isOptOutRequired: string,
    noOfColumns: string,
    fileSelect: File,
    scheduleDate: string,
    scheduleTime: string,
    // expiryDate: string,
    // expiryTime: string,
    message: string,
    scheduleNow: string
  ) {
    const ExpiryDateFix = new Date(scheduleDate);
    const ExpiryDateFixPlus7Days = moment(ExpiryDateFix)
      .add(7, "days")
      .format("YYYY-MM-DD HH:mm:ss");

    const postData = new FormData();
    postData.append("sourceAddress", sourceAddress);
    postData.append("campaignName", campaignName);
    postData.append("is_opt_out_required", isOptOutRequired);
    postData.append("number_of_columns", noOfColumns);
    postData.append("fileSelect", fileSelect, campaignName);
    postData.append("scheduleDate", scheduleDate);
    postData.append("scheduleTime", scheduleTime);
    postData.append("expiryDate", ExpiryDateFixPlus7Days);
    //passing shedule time as the expiry time as it is not used
    postData.append("expiryTime", scheduleTime);
    postData.append("message", message);
    postData.append("schedule_now", scheduleNow);

    return this.http.post(
      this.hostIpWithPort +
        "/api/v1/campaignProvision/templated-message-optimized",
      postData
    );
    //http://54.236.26.240:3000/api
  }
  createCampaignAndroid(
    sourceAddress: string,
    campaignName: string,
    msisdns: string,
    fileSelect: File,
    scheduleDate: string,
    scheduleTime: string,
    expiryDate: string,
    expiryTime: string,
    message: string,
    scheduleNow: string
  ) {
    let sampleNumberList = [
      { mobile: "714551682" },
      { mobile: "724551682" },
      { mobile: "764551682" },
      { mobile: "774551682" },
      { mobile: "764551682" },
      { mobile: "784551682" },
    ];
    const campaign = {
      sourceAddress: sourceAddress,
      campaignName: campaignName,
      campaignType: 0,
      msisdns: sampleNumberList,
      scheduleDate: scheduleDate,
      scheduleTime: scheduleTime,
      expiryDate: expiryDate,
      expiryTime: expiryTime,
      message: message,
      schedule_now: scheduleNow,
    };

    return this.http.post(
      this.hostIpWithPort + "/api/v1/mobile/campaignProvision",
      campaign
    );
  }
  initiatePayment(
    campaignId: Number,
    paymentType: Number,
    type: Number,
    value: Number
  ) {
    const campaign = {
      campaignId: campaignId,
      paymentType: paymentType,
      type: type,
      value: value,
    };
    // console.log(campaign);
    return this.http.post(this.hostIpWithPort + "/api/v1/charge", campaign);
  }
  initiateTopUp(
    userId: Number,
    paymentType: Number,
    type: Number,
    value: Number
  ) {
    const userTopUp = {
      userId: userId,
      paymentType: paymentType,
      type: type,
      value: value,
    };

    return this.http.post(this.hostIpWithPort + "/api/v1/charge", userTopUp);
  }
  getAllCampaigns(
    currentPage: string,
    postsPerPage: string,
    campaignType: string
  ) {
    let params = {
      currentPage: currentPage,
      postsPerPage: postsPerPage,
      campaignType: campaignType,
    };
    return this.http.get(
      this.hostIpWithPort + "/api/v1/campaignProvision/campaignList",
      {
        params: params,
      }
    );
  }

  campaignGlobalSearch(
    currentPage: string,
    postsPerPage: string,
    campaignType: string,
    searchParam: string
  ) {
    let params = {
      currentPage: currentPage,
      postsPerPage: postsPerPage,
      campaignType: campaignType,
    };
    let body = { searchParam: searchParam };
    return this.http.post(
      this.hostIpWithPort + "/api/v1/campaignProvision/campaignList-search",
      body,
      {
        params: params,
      }
    );
  }

  getSingleCampaign(id: number) {
    return this.http.get(
      this.hostIpWithPort + "/api/v1/campaignProvision/campaign/" + id
    );
  }

  getCampaignViewContactList(
    currentPage: string,
    postsPerPage: string,
    message_sent: string,
    id: number
  ) {
    let params = {
      currentPage: currentPage,
      postsPerPage: postsPerPage,
      message_sent: message_sent,
    };
    return this.http.get(
      this.hostIpWithPort + "/api/v1/campaignProvision/campaign/" + id,
      {
        params: params,
      }
    );
  }

  getCampaignViewSearchContactList(
    currentPage: string,
    postsPerPage: string,
    message_sent: string,
    searchParam: string,
    id: number
  ) {
    let params = {
      currentPage: currentPage,
      postsPerPage: postsPerPage,
      message_sent: message_sent,
    };
    let body = { searchParam: searchParam };
    return this.http.post(
      this.hostIpWithPort + "/api/v1/campaignProvision/campaign-search/" + id,
      body,
      {
        params: params,
      }
    );
  }
  updateCampaign(
    campaignId: string,
    scheduleDate: string,
    scheduleTime: string,
    source_address: string,
    scheduleNow: string
  ) {
    const ExpiryDateFix = new Date(scheduleDate);
    const ExpiryDateFixPlus7Days = moment(ExpiryDateFix)
      .add(7, "days")
      .format("YYYY-MM-DD HH:mm:ss");
    let postData = {
      id: campaignId,
      scheduleDate: scheduleDate,
      scheduleTime: scheduleTime,
      expiryDate: ExpiryDateFixPlus7Days,
      expiryTime: scheduleTime,
      sourceAddress: source_address,
      schedule_now: scheduleNow,
    };
    return this.http.patch(
      this.hostIpWithPort + "/api/v1/campaignProvision",
      postData
    );
    //http://54.236.26.240:3000/api
  }
  campaignStatusChange(campaignId: Number, action: Number) {
    let postData = {
      campaignId: campaignId,
      action: action,
    };
    return this.http.post(
      this.hostIpWithPort + "/api/v1/campaignProvision/changeCampaignStatus",
      postData
    );
  }
  //request mask
  requestMask(mask: string, maskMessage: string) {
    let postData = {
      mask: mask,
      purposeOfMask: maskMessage,
    };
    return this.http.post(
      this.hostIpWithPort + "/api/v1/mask/requestAdditionalMask",
      postData
    );
  }
  getPaymentHistory() {
    return this.http.get(this.hostIpWithPort + "/api/v1/charge/history");
  }

  walletAllData(currentPage: string, postsPerPage: string) {
    let params = {
      currentPage: currentPage,
      postsPerPage: postsPerPage,
    };
    return this.http.get(this.hostIpWithPort + "/api/v1/charge/history", {
      params: params,
    });
  }

  walletSearchPayments(
    currentPage: string,
    postsPerPage: string,
    fromDate: string,
    toDate: string
  ) {
    let params = {
      currentPage: currentPage,
      postsPerPage: postsPerPage,
    };
    let body = { fromDate: fromDate, toDate: toDate };
    return this.http.post(
      this.hostIpWithPort + "/api/v1/charge/history-search",
      body,
      {
        params: params,
      }
    );
  }

  getShortCodeList() {
    return this.http.post(
      this.hostIpWithPort +
        "/api/v1/inbox-messaging/short-code-or-long-num/get-list",
      null
    );
  }

  getMessageList(
    currentPage: string,
    postsPerPage: string,
    shortCode: string,
    fromDate: string,
    toDate: string
  ) {
    let params = {
      currentPage: currentPage,
      postsPerPage: postsPerPage,
    };
    let body = {
      short_code_or_long_number: shortCode,
      fromDate: fromDate,
      toDate: toDate,
    };
    return this.http.post(
      this.hostIpWithPort +
        "/api/v1/inbox-messaging/short-code-or-long-num/get-message-list",
      body,
      {
        params: params,
      }
    );
  }

  searchMessageList(
    currentPage: string,
    postsPerPage: string,
    searchParam: string,
    shortCode: string,
    fromDate: string,
    toDate: string
  ) {
    let params = {
      currentPage: currentPage,
      postsPerPage: postsPerPage,
    };
    let body = {
      search: searchParam,
      short_code_or_long_number: shortCode,
      fromDate: fromDate,
      toDate: toDate,
    };
    return this.http.post(
      this.hostIpWithPort +
        "/api/v1/inbox-messaging/short-code-or-long-num/get-message-list/search",
      body,
      {
        params: params,
      }
    );
  }

  markReadUnreadMessage(shortCode: string, msgID: string, status: string) {
    let body = {
      short_code_or_long_number: shortCode,
      message_id: msgID,
      status: status,
    };
    return this.http.post(
      this.hostIpWithPort +
        "/api/v1/inbox-messaging/short-code-or-long-num/get-message-list/read-unread",
      body
    );
  }

  getContactList(currentPage: string, postsPerPage: string) {
    let params = {
      currentPage: currentPage,
      postsPerPage: postsPerPage,
    };
    return this.http.get(
      this.hostIpWithPort + "/api/v1/saved-contacts/get-list",
      {
        params: params,
      }
    );
  }
  contactListGlobalSearch(
    currentPage: string,
    postsPerPage: string,
    searchParam: string
  ) {
    let params = {
      currentPage: currentPage,
      postsPerPage: postsPerPage,
    };
    let body = { searchParam: searchParam };
    return this.http.post(
      this.hostIpWithPort + "/api/v1/saved-contacts/get-list-search",
      body,
      {
        params: params,
      }
    );
  }
  createContactList(
    contactListName: string,
    msisdns: string,
    fileSelect: File
  ) {
    const postData = new FormData();

    postData.append("contactListName", contactListName);
    postData.append("msisdns", msisdns);
    postData.append("fileSelect", fileSelect, contactListName);
    return this.http.post(
      this.hostIpWithPort + "/api/v1/saved-contacts/create",
      postData
    );
    //http://54.236.26.240:3000/api
  }
  viewContactList(contactListID: string) {
    const postData = {
      contactListID: contactListID,
    };
    return this.http.post(
      this.hostIpWithPort + "/api/v1/saved-contacts/view-list",
      postData
    );
  }

  deleteContactList(contactListID: string) {
    const postData = {
      contactListID: contactListID,
    };
    return this.http.post(
      this.hostIpWithPort +
        "/api/v1/saved-contacts/delete-complete-contact-list",
      postData
    );
  }

  deleteNumbersContactList(contactListID: string, numberList: any) {
    const postData = {
      contactListID: contactListID,
      number_list: numberList,
    };
    return this.http.post(
      this.hostIpWithPort +
        "/api/v1/saved-contacts/delete-numbers-in-contact-list",
      postData
    );
  }

  getTemplateList(currentPage: string, postsPerPage: string) {
    let body = {};
    let params = {
      currentPage: currentPage,
      postsPerPage: postsPerPage,
    };
    return this.http.post(
      this.hostIpWithPort + "/api/v1/message-template/view-all-templates",
      body,
      {
        params: params,
      }
    );
  }

  getViewSingleTemplate(templateID: number) {
    let body = { template_id: templateID };
    return this.http.post(
      this.hostIpWithPort + "/api/v1/message-template/view-template",
      body
    );
  }

  createTemplateList(templateListName: string, message: string) {
    let body = { template_name: templateListName, message: message };
    return this.http.post(
      this.hostIpWithPort + "/api/v1/message-template/create-template",
      body
    );
  }

  editTemplateList(templateListName: string, message: string, id: string) {
    let body = {
      template_name: templateListName,
      message: message,
      template_id: id,
    };
    return this.http.post(
      this.hostIpWithPort + "/api/v1/message-template/edit-template",
      body
    );
  }

  getContactNumberList(
    currentPage: string,
    postsPerPage: string,
    contactListID: number
  ) {
    let params = {
      currentPage: currentPage,
      postsPerPage: postsPerPage,
    };
    let body = { contactListID: contactListID };
    return this.http.post(
      this.hostIpWithPort + "/api/v1/saved-contacts/view-list",
      body,
      {
        params: params,
      }
    );
  }

  contactViewListGlobalSearch(
    currentPage: string,
    postsPerPage: string,
    searchParam: string,
    contactListID: number
  ) {
    let params = {
      currentPage: currentPage,
      postsPerPage: postsPerPage,
    };
    let body = { contactListID: contactListID, searchParam: searchParam };
    return this.http.post(
      this.hostIpWithPort + "/api/v1/saved-contacts/view-list-search",
      body,
      {
        params: params,
      }
    );
  }
  addNewMobile(contactListID: string, msisdn: string) {
    const postData = {
      contactListID: contactListID,
      msisdn: msisdn,
    };
    return this.http.post(
      this.hostIpWithPort + "/api/v1/saved-contacts/add-number",
      postData
    );
  }
  removeMobile(contactListID: string, msisdn: string) {
    const postData = {
      contactListID: contactListID,
      msisdn: msisdn,
    };
    return this.http.post(
      this.hostIpWithPort + "/api/v1/saved-contacts/delete-number",
      postData
    );
  }
  createCampaignContactList(
    sourceAddress: string,
    campaignName: string,
    msisdns: string,
    isOptOutRequired: string,
    scheduleDate: string,
    scheduleTime: string,
    message: string,
    contactListID: string,
    scheduleNow: string
  ) {
    const ExpiryDateFix = new Date(scheduleDate);
    const ExpiryDateFixPlus7Days = moment(ExpiryDateFix)
      .add(7, "days")
      .format("YYYY-MM-DD HH:mm:ss");

    const data = {
      sourceAddress: sourceAddress,
      campaignName: campaignName,
      msisdns: msisdns,
      is_opt_out_required: isOptOutRequired,
      scheduleDate: scheduleDate,
      scheduleTime: scheduleTime,
      expiryDate: ExpiryDateFixPlus7Days,
      expiryTime: scheduleTime,
      message: message,
      contactListID: contactListID,
      schedule_now: scheduleNow,
    };

    return this.http.post(
      this.hostIpWithPort +
        "/api/v1/common-campaigns/create-campaign/via-contact-list",
      data
    );
    //http://54.236.26.240:3000/api
  }
  createTargetedCampaignContactList(
    sourceAddress: string,
    campaignName: string,
    msisdns: string,
    isOptOutRequired: string,
    scheduleDate: string,
    scheduleTime: string,
    message: string,
    contactListID: string,
    scheduleNow: string,
    group: string,
    field: string,
    targetedField: any
  ) {
    const ExpiryDateFix = new Date(scheduleDate);
    const ExpiryDateFixPlus7Days = moment(ExpiryDateFix)
      .add(7, "days")
      .format("YYYY-MM-DD HH:mm:ss");

    const data = {
      sourceAddress: sourceAddress,
      campaignName: campaignName,
      msisdns: msisdns,
      is_opt_out_required: isOptOutRequired,
      scheduleDate: scheduleDate,
      scheduleTime: scheduleTime,
      expiryDate: ExpiryDateFixPlus7Days,
      expiryTime: scheduleTime,
      message: message,
      contactListID: contactListID,
      schedule_now: scheduleNow,
      group: group,
      field: field,
      targetedField: targetedField,
    };

    return this.http.post(
      this.hostIpWithPort +
        "/api/v1/targeting-campaigns/create-campaign/via-contact-list",
      data
    );
  }
  createCampaignOnlyForPhoneNumbers(
    sourceAddress: string,
    campaignName: string,
    isOptOutRequired: string,
    scheduleDate: string,
    scheduleTime: string,
    message: string,
    sampleNumberList: any,
    scheduleNow: string
  ) {
    const campaign = {
      sourceAddress: sourceAddress,
      campaign_name: campaignName,
      is_opt_out_required: isOptOutRequired,
      msisdns: "",
      sampleNumberList: sampleNumberList,
      scheduleDate: scheduleDate,
      scheduleTime: scheduleTime,
      message: message,
      schedule_now: scheduleNow,
    };

    return this.http.post(
      this.hostIpWithPort + "/api/v1/common-campaigns/create-campaign/via-json",
      campaign
    );
  }

  downloadFile(): any {
    return this.http.get(
      this.hostIpWithPort +
        "/api/sample-files/sample_for_templated_message.csv",
      { responseType: "blob" }
    );
  }
}
