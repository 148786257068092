import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { Route } from "@angular/compiler/src/core";
import { CampaignService } from "../../campaign/campaign.service";
import { SuccessComponent } from "src/app/success/success.component";
import { MatDialog } from "@angular/material/dialog";
@Component({
  selector: "app-pay-campaign-option",
  templateUrl: "./pay-campaign-option.component.html",
  styleUrls: ["./pay-campaign-option.component.css"],
})
export class PayCampaignOptionComponent implements OnInit {
  campaign: {
    campaignId: Number;
    campaignCost: Number;
    chargeableCost: Number;
    walletBalance: Number;
    campaignStatus: Number;
  };
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private campaignService: CampaignService,
    private router: Router,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.campaign = {
      campaignId: this.route.snapshot.params["campaignId"],
      campaignCost: this.route.snapshot.params["campaignCost"],
      chargeableCost: this.route.snapshot.params["chargeableCost"],
      walletBalance: this.route.snapshot.params["walletBalance"],
      campaignStatus: this.route.snapshot.params["campaignStatus"],
    };
    this.route.params.subscribe((params: Params) => {
      this.campaign.campaignId = params["campaignId"];
      this.campaign.campaignCost = params["campaignCost"];
      this.campaign.chargeableCost = params["chargeableCost"];
      this.campaign.walletBalance = params["walletBalance"];
      this.campaign.campaignStatus = params["campaignStatus"];
    });
  }
  proceed(form: NgForm) {
    if (form.invalid) {
      return;
    }
    //0 stands for campaign payment
    let type = 0;
    //campign value is handled in backend
    //setting defualt value to 0
    let default_val = 0;
    this.campaignService
      .initiatePayment(
        this.campaign.campaignId,
        form.value.paymentType,
        type,
        default_val
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.router.navigate(["/campaign/list"]);
          //how capture success response
          this.matDialog.open(SuccessComponent, {
            data: { message: response.comment },
          });
        },
        (error) => {
          console.log(error);
        }
      );
  }
}
