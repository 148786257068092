<section class="e-body-wrapper pb-5">
  <div class="container">
    <div class="row pt-4 pb-2 text-center">
      <h3 class="col-12 text-center e-page-title">
        {{ this.getTimeWelcome() }} {{ this.account?.userData.fname }} !
      </h3>
    </div>
    <div class="row">
      <div class="col-12 col-md-8">
        <div class="e-card e-card--no-paddings mb-4 pb-4">
          <h4 class="pt-4 mb-2 text-center e-page-title">Ongoing campaigns</h4>
          <mat-spinner *ngIf="isLoading"></mat-spinner>
          <ng-container *ngIf="!isLoading">
            <div class="e-card--table">
              <table mat-table matSort [dataSource]="dataSourceTwo" #TableTwoSort="matSort">
                <!-- Position Column -->
                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>No.</th>
                  <td mat-cell *matCellDef="let element">{{ element.id }}</td>
                </ng-container>
                <!-- Campaign Column -->
                <ng-container matColumnDef="campaign_name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Name.
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.campaign_name }}
                  </td>
                </ng-container>
                <!-- ID Column -->
                <ng-container matColumnDef="source_address">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Sender.
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.source_address }}
                  </td>
                </ng-container>
                <!-- ID Column -->
                <ng-container matColumnDef="schedule_time">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Schedule.
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{
                    element.schedule_time | date: "yyyy-MM-dd HH:mm":"+0000"
                    }}
                  </td>
                </ng-container>

                <!-- Message ID Column -->

                <ng-container matColumnDef="message_id">
                  <th mat-header-cell *matHeaderCellDef>View</th>
                  <td mat-cell *matCellDef="let element">
                    <a [routerLink]="['/campaign/view', element.id]"><mat-icon>visibility</mat-icon></a>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsTwo"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsTwo"></tr>
              </table>

              <!-- <mat-paginator
              #TableTwoPaginator="matPaginator"
              [pageSize]="5"
              [pageSizeOptions]="[5, 10, 25, 100]"
            ></mat-paginator> -->
            </div>
          </ng-container>
        </div>
        <div class="e-card e-card--no-paddings mb-4 pb-4">
          <h4 class="pt-4 mb-2 text-center e-page-title">
            Scheduled campaigns
          </h4>
          <mat-spinner *ngIf="isLoading"></mat-spinner>
          <ng-container *ngIf="!isLoading">
            <div class="e-card--table">
              <table mat-table matSort [dataSource]="dataSourceThree" #TableThreeSort="matSort">
                <!-- Position Column -->
                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>No.</th>
                  <td mat-cell *matCellDef="let element">{{ element.id }}</td>
                </ng-container>
                <!-- Campaign Column -->
                <ng-container matColumnDef="campaign_name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Name.
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.campaign_name }}
                  </td>
                </ng-container>
                <!-- ID Column -->
                <ng-container matColumnDef="source_address">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Sender.
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.source_address }}
                  </td>
                </ng-container>
                <!-- ID Column -->
                <ng-container matColumnDef="schedule_time">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Schedule.
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{
                    element.schedule_time | date: "yyyy-MM-dd HH:mm":"+0000"
                    }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="message_id">
                  <th mat-header-cell *matHeaderCellDef>View</th>
                  <td mat-cell *matCellDef="let element">
                    <a [routerLink]="['/campaign/view', element.id]"><mat-icon>visibility</mat-icon></a>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsTwo"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsTwo"></tr>
              </table>

              <!-- <mat-paginator
              #TableThreePaginator="matPaginator"
              [pageSize]="5"
              [pageSizeOptions]="[5, 10, 25, 100]"
            ></mat-paginator> -->
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-12 col-md-4 mt-4 mt-sm-0">
        <div class="e-card e-card-campaign-right mb-4">
          <div class="e-card-campaign-right__sec-txt">
            Your account balance is
          </div>
          <div class="spinner-grow text-primary" role="status" *ngIf="isLoadingBal">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="e-card-campaign-right__primary-txt mt-3 mb-3" *ngIf="!isLoadingBal">
            <span class="e-card-campaign-right__currency">Rs. &nbsp;</span>
            {{ this.account?.userData.walletBalance | currency:" ":"symbol" }}
          </div>
          <button *ngIf="paymentType == '0'" mat-flat-button color="primary" class="e-btn mt-2 mb-1"
            (click)="openReload()">
            RELOAD
          </button>
        </div>
        <div *ngIf="additionalAddons?.isAbleSendUrlMessages" class="e-card e-card-campaign-right mb-4 e-card-msg-key">
          <div class="e-card-campaign-right__sec-txt">
            URL Message Key
          </div>
          <div class="spinner-grow text-primary" role="status" *ngIf="urlMsgLoading">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="row mt-4" *ngIf="!urlMsgLoading">
            <div class="col-12 url-key-view" *ngIf="urlMsgKey">
              <small>{{urlMsgKey}}</small>
            </div>
            <div class="col" *ngIf="urlMsgKey">
              <div class="copy-clipboard" (click)="openSnackBar()" [cdkCopyToClipboard]="urlMsgKey" matRipple
                matTooltip="Copy key" [matTooltipPosition]="'below'"><i class="material-icons">content_copy</i></div>
            </div>
            <button mat-flat-button color="primary" class="e-key-btn" (click)="generateMsgKey()"
              *ngIf="!urlMsgKey">Generate</button>
            <button mat-flat-button color="primary" class="e-key-btn" (click)="generateMsgKey()"
              *ngIf="urlMsgKey">Re-Generate</button>
            <button mat-flat-button color="warn" class="e-key-btn" (click)="removeMsgKey()"
              *ngIf="urlMsgKey">Remove</button>
          </div>
        </div>
        <!-- <div class="e-card e-card--no-x-paddings e-card-campaign-right mb-4">
          <div class="e-card-campaign-right__sec-txt">Total Messages Sent</div>
          <div
            class="spinner-grow text-primary"
            role="status"
            *ngIf="isLoading"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <mat-tab-group class="mt-3 e-tab-widget" *ngIf="!isLoading">
            <mat-tab label="Last 7 days">
              <div class="e-card-campaign-right__primary-txt mt-3 mb-3">
                {{ messageCount(this.account?.userData.msgSeven) }}
              </div>
            </mat-tab>
            <mat-tab label="Last month">
              <div class="e-card-campaign-right__primary-txt mt-3 mb-3">
                {{ messageCount(this.account?.userData.msgMonth) }}
              </div>
            </mat-tab>
            <mat-tab label="Six Months">
              <div class="e-card-campaign-right__primary-txt mt-3 mb-3">
                {{ messageCount(this.account?.userData.msgSixMonth) }}
              </div>
            </mat-tab>
            <mat-tab label="Lifetime">
              <div class="e-card-campaign-right__primary-txt mt-3 mb-3">
                {{ messageCount(this.account?.userData.msgSixMonth) }}
              </div>
            </mat-tab>
          </mat-tab-group>
        </div> -->
      </div>
      <div class="row pt-4 pb-2" *ngIf="isAfterNow('2024-02-01')">
        <div class="col-12" style="color: coral; text-align: center;">
          <small style="text-align: center;">Dear Valued Customer,</small>
        </div>
        <div class="col-12" style="color: coral; text-align: justify;">
          <small style="text-align: justify;">This is to notify you that a vital update in line with the Finance Act No.
            33 of 2023 from the Government of Sri Lanka, starting from December 13, 2023, a nominal levy of 0.25 cents
            will be imposed on each promotional eSMS. This levy supports government initiatives and regulations,
            ensuring the continual functioning of electronic communication services.
            Your understanding and cooperation are valued as we enact this adjustment to meet legal requirements. The
            levy will be automatically applied to relevant messages, with details reflected in your billing
            statements.</small>
        </div>
        <div class="col-12" style="color: coral; text-align: center;"><small style="text-align: justify;">Thank
            You!</small></div>
      </div>
    </div>
  </div>
</section>
