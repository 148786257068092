<section class="container-fluid e-body-wrapper pt-5">
  <div class="row">
    <div class="col-md-6 offset-md-3 col-lg-4 offset-lg-4">
      <div class="e-card text-center">
        <form
          [formGroup]="form"
          (submit)="onAddNewPassword(formDirective)"
          #formDirective="ngForm"
          autocomplete="off"
        >
          <div class="e-card__header mb-4 row">
            <div class="col-2">
              <button
                mat-icon-button
                aria-label="Back icon"
                routerLink="/login"
              >
                <span class="material-icons">
                  arrow_back
                </span>
              </button>
            </div>
            <h4 class="col-8 e-page-title">Please enter new password</h4>
            <h3 class="col-12 e-page-title">
              <countdown [config]="{ leftTime: counterTime }"></countdown>
            </h3>
          </div>
          <mat-form-field appearance="outline" class="e-input">
            <mat-label>Password *</mat-label>
            <input
              matInput
              [type]="show ? 'text' : 'password'"
              name="password"
              formControlName="password"
              (click)="resetPassword()"
              maxlength="50"
            />

            <mat-icon matSuffix (click)="passwordShow()">visibility</mat-icon>
            <mat-error *ngIf="form.get('password').hasError('pattern')"
              >8 characters password with a lower case letter,upper case
              letter,number and special character</mat-error
            >
            <mat-error *ngIf="form.get('password').hasError('required')"
              >Password can't be null</mat-error
            >
          </mat-form-field>
          <div style="padding: 10px;"></div>
          <mat-form-field appearance="outline" class="e-input">
            <mat-label>Confirm password *</mat-label>
            <input
              matInput
              [type]="confirmShow ? 'text' : 'password'"
              name="cpassword"
              #confirm_password
              formControlName="cpassword"
              maxlength="50"
            />
            <mat-icon matSuffix (click)="confirmPasswordShow()"
              >visibility</mat-icon
            >
            <mat-error *ngIf="form.get('cpassword').invalid"
              >Password and confirm password fields does not match</mat-error
            >
          </mat-form-field>
          <button
            mat-flat-button
            color="primary"
            class="e-btn mt-2"
            type="submit"
          >
            CHANGE
          </button>
        </form>
      </div>
    </div>
  </div>
</section>
