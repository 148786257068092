<footer class="e-footer pt-3 pt-md-5">
  <div class="container">
    <div class="row pb-4 e-footer__c-info">
      <!-- <div class="col-md-4 text-center d-flex justify-content-end">
              <div>
                  <fa name="facebook" class="e-footer__c-info-icon"></fa>
                  <fa name="twitter" class="e-footer__c-info-icon"></fa>
                  <fa name="instagram" class="e-footer__c-info-icon"></fa>
                  <fa name="linkedin" class="e-footer__c-info-icon"></fa>
                  <fa name="youtube" class="e-footer__c-info-icon"></fa>
              </div>
          </div> -->
      <div class="offset-md-3 col-md-3 text-center e-caption-txt">
        E-mail: esms@dialog.lk
      </div>
      <div
        class="col-md-3 text-center e-caption-txt d-flex justify-content-start"
      >
        Hotline: 0117 100 200
      </div>
    </div>
    <div class="row pb-2">
      <div class="col-12 text-center e-small-txt">
        &#169;Dialog Axiata PLC. All rights reserved
      </div>
    </div>
    <div class="row">
      <div class="offset-sm-4 col-sm-4">
        <div class="text-center e-footer__row-3">
          <span class="e-small-txt e-small-txt--highlighted2"
            >Privacy policy</span
          >
          <span class="e-footer__vl"></span>
          <a class="e-small-txt e-small-txt--highlighted2" href="terms-and-conditions"
            >Terms & Conditions</a
          >
        </div>
      </div>
    </div>
  </div>
</footer>
