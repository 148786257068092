import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { not } from "@angular/compiler/src/output/output_ast";
import {
  FormGroupDirective,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import * as momentT from "moment-timezone";
import { AuthService } from "src/app/auth/auth.service";
import * as XLSX from "xlsx";
import { CampaignService } from "../../campaign.service";
@Component({
  selector: "app-all-campaigns-report",
  templateUrl: "./all-campaigns-report.component.html",
  styleUrls: ["./all-campaigns-report.component.css"],
})
export class AllCampaignsReportComponent implements OnInit {
  displayedColumnsOne: string[] = [
    "id",
    "date",
    "name",
    "status",
    "deliveredDATE",
  ];

  form;
  isLoading = false;
  isRefresh = false;
  isExportingExcel = false;
  fromDate;
  toDate;

  totalPosts = 0;
  postsPerPage = 5;
  pageSizeOption = [5, 10, 25, 100];
  currentPage = 1;

  dataSourceAll;
  dataSourceOne;

  @ViewChild("TableOnePaginator", { static: false })
  tableOnePaginator: MatPaginator;
  @ViewChild("TableOneSort") tableOneSort: MatSort;
  @ViewChild(MatSort) set TableOneSort(tableOneSort: MatSort) {
    if (this.dataSourceOne) {
      this.dataSourceOne.sort = tableOneSort;
    }
  }

  ngOnInit() {
    this.form = new FormGroup({
      fromDate: new FormControl(null, {
        validators: [Validators.required],
      }),
      toDate: new FormControl(null, {
        validators: [Validators.required],
      }),

      mobile: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(9),
          Validators.pattern("^[0-9]*$"),
        ],
      }),
    });
  }

  public dateFilterFrom = (d: Date): boolean => {
    const value = this.form.value;
    if (value.toDate) {
      return true && d <= this.convertToDate(value.toDate);
    } else {
      return true;
    }
  };

  public dateFilterTo = (d: Date): boolean => {
    const value = this.form.value;
    if (value.fromDate) {
      return d >= this.convertToDate(value.fromDate) && true;
    } else {
      return true;
    }
  };

  protected convertToDate(d: Date | string): Date {
    return typeof d === "string" ? new Date(d) : d;
  }

  constructor(
    private authService: AuthService
  ) {}
  applySearch(formDirective: FormGroupDirective) {
    //console.log(this.form);
    if (this.form.invalid) {
      return;
    }

    this.isRefresh = true;

    this.postsPerPage = 5;
    this.pageSizeOption = [5, 10, 25, 100];
    this.currentPage = 1;

    this.fromDate = momentT(this.form.value.fromDate)
      .tz("Asia/kolkata")
      .format("YYYY-MM-DD");
    this.toDate = momentT(this.form.value.toDate)
      .tz("Asia/kolkata")
      .format("YYYY-MM-DD");
    this.fetchDataOne(
      this.currentPage,
      this.postsPerPage,
      this.fromDate,
      this.toDate,
      this.form.value.mobile
    );
  }

  fetchDataOne(currentPage, postsPerPage, fromDate, toDate, searchMobile) {
    this.isLoading = true;
    this.dataSourceOne = new MatTableDataSource(null);
    //get campaign list

    this.authService
      .getReportMobile(
        currentPage,
        postsPerPage.toString(),
        fromDate,
        toDate,
        searchMobile
      )
      .subscribe(
        (response: any) => {
          ////////console.log(response);
          // this.totalPosts = response.count;
          ////////console.log(this.totalPosts);
          this.dataSourceAll = response?.userData||[];
          this.dataSourceOne = new MatTableDataSource(this.dataSourceAll);
          this.dataSourceOne.sort = this.tableOneSort;
          if (this.dataSourceAll.length < this.postsPerPage) {
            this.totalPosts = this.currentPage * this.postsPerPage;
          } else {
            this.totalPosts = this.currentPage * this.postsPerPage + 1;
          }
          this.isLoading = false;
          this.isRefresh = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.isRefresh = false;
        }
      );
  }

  onChangedPage(pageData: PageEvent) {
    this.currentPage = pageData.pageIndex + 1;
    this.postsPerPage = pageData.pageSize;
    this.fetchDataOne(
      this.currentPage,
      this.postsPerPage,
      this.fromDate,
      this.toDate,
      this.form.value.mobile
    );
  }

  ExportTOExcel() {
    this.isExportingExcel = true;
    this.authService
      .getReportMobile(
        "1",
        "all",
        this.fromDate,
        this.toDate,
        this.form.value.mobile
      )
      .subscribe(
        (response: any) => {
          //console.log(response);
          var data = response?.userData?.map((a) => ({
            id: a.id,
            date: momentT(a.date).tz('Etc/UTC').format('YYYY-MM-DD HH:mm:ss'),
            campaign_name: a.name,
            status: this.getStatus(a.status),
          }))||[];
          var dataSourceOne = new MatTableDataSource(data);

          const Heading = [["ID", "Date", "Campaign Name", "Status"]];

          /* add the header */
          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
          XLSX.utils.sheet_add_aoa(ws, Heading);

          //Starting in the second row to avoid overriding and skipping headers
          XLSX.utils.sheet_add_json(ws, dataSourceOne.filteredData, {
            origin: "A2",
            skipHeader: true,
          });

          /* generate workbook and add the worksheet */
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

          /* save to file */
          XLSX.writeFile(wb, "report-mobile" + new Date().getTime() + ".xlsx");
          this.isExportingExcel = false;
        },
        (error) => {
          console.log(error);
          this.isExportingExcel = false;
        }
      );
  }

  getStatus(status) {
    let stringStatus;
    switch (status) {
      case 0:
        stringStatus = "Not Sent";
        break;
      case 1:
        stringStatus = "Sent";
        break;
      case 2:
        stringStatus = "Sending Failed";
        break;
      case 3:
        stringStatus = "Delivered";
        break;
      case 4:
        stringStatus = "Delivery Failed";
        break;
      default:
        stringStatus = null;
        break;
    }
    return stringStatus;
  }
}

export interface PeriodicElement {
  date: string;
  name: string;
  id: number;
  status: number;
  deliveredDATE: string;
}
