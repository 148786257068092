import { AbstractControl } from "@angular/forms";
import { Observable, Observer, of } from "rxjs";

export const mimeType = (
  control: AbstractControl
): Promise<{ [key: string]: any }> | Observable<{ [key: string]: any }> => {
  if (typeof control.value === "string") {
    return of(null);
  }
  const file = control.value as File;
  const fileReader = new FileReader();
  const frObs = Observable.create(
    (observer: Observer<{ [key: string]: any }>) => {
      fileReader.addEventListener("loadend", () => {
        let isValid = false;
        const maxFileSize = 2000000;
        if (
          file.size <= maxFileSize &&
          hasExtension(file.name, [".jpg", ".jpeg", ".png", ".pdf"])
        ) {
          isValid = true;
        }
        if (isValid) {
          observer.next(null);
        } else {
          observer.next({ invalidMimeType: true });
          control.root.get("imageName").setValue(null);
        }
        observer.complete();
      });
      fileReader.readAsArrayBuffer(file);
    }
  );
  return frObs;
};
function hasExtension(fileName, exts) {
  return new RegExp(
    "(" + exts.join("|").replace(/\./g, "\\.") + ")$",
    "i"
  ).test(fileName);
}
