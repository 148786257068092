<div class="container-fluid e-modal">
  <button
      mat-mini-fab
      color="primary"
      class="e-btn-modal-close"
      type="button"
    >
      <mat-icon class="e-btn-modal-close__icon" (click)="closeDialog()"
        >close</mat-icon
      >
    </button>
  <div class="row mt-3">
    <div class="col-12 e-modal-row-1">
      <span class="material-icons e-status-syb__stop-icon">info</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center mt-5 mb-3">
      <h1 mat-dialog-title>{{data.title}}</h1>
      <div mat-dialog-content>
        {{data.message}}
      </div>
      <div style="float:right;margin:20px;">
      </div>
    </div>
  </div>
  <div class="e-modal-footer mb-1" mat-dialog-actions>
    <button mat-stroked-button class="e-btn-stroke e-btn--small" [mat-dialog-close]="false">
      Cancel
    </button>
    <button mat-flat-button color="primary" class="e-btn e-btn--small" [mat-dialog-close]="true">
      Confirm
    </button>
  </div>
